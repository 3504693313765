import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
@Injectable({ providedIn: 'root' })
export class RuleEngineService {
  constructor(private http: HttpClient) {}
  getListConstant(filters) {
    return this.http.get(`${environment.apiUrl}rule-engine/list-constant`, { params: filters });
  }
  getListRule(filters) {
    return this.http.get(`${environment.apiUrl}rule-engine/list-rule`, { params: filters });
  }
  getLeadCount(obj) {
    return this.http.post(`${environment.apiUrl}rule-engine/lead-count`, obj);
  }
  createRule(obj) {
    return this.http.post(`${environment.apiUrl}rule-engine/create`, obj);
  }
  duplicateRule(obj) {
    return this.http.post(`${environment.apiUrl}rule-engine/duplicate`, obj);
  }
  statusChangeRule(obj) {
    return this.http.post(`${environment.apiUrl}rule-engine/status-change-rule`, obj);
  }
  analyticsRule(obj) {
    return this.http.post(`${environment.apiUrl}rule-engine/analytics`, obj);
  }
  viewHistory(obj) {
    return this.http.post(`${environment.apiUrl}rule-engine/history`, obj);
  }
  createRuleCounsellor(obj) {
    return this.http.post(`${environment.apiUrl}rule-engine/create-counsellor`, obj);
  }
  getListRuleCounsellor(filters) {
    return this.http.get(`${environment.apiUrl}rule-engine/list-rule-counsellor`, {
      params: filters,
    });
  }
  statusChangeRuleCounsellor(obj) {
    return this.http.post(`${environment.apiUrl}rule-engine/status-change-rule-counsellor`, obj);
  }
  deleteRule(obj) {
    return this.http.post(`${environment.apiUrl}rule-engine/delete`, obj);
  }
  deleteRuleCounsellor(obj) {
    return this.http.post(`${environment.apiUrl}rule-engine/delete-counsellor`, obj);
  }
  allocationCounsellor(obj) {
    return this.http.post(`${environment.apiUrl}rule-engine/allocation-counsellor`, obj);
  }
  updateRuleCounsellor(obj) {
    return this.http.post(`${environment.apiUrl}rule-engine/update-counsellor`, obj);
  }
  allocationCounsellorCount(obj) {
    return this.http.post(`${environment.apiUrl}rule-engine/allocation-counsellor-count`, obj);
  }
  allocationRule(obj) {
    return this.http.post(`${environment.apiUrl}rule-engine/allocation-rule`, obj);
  }
  allocationRuleCount(obj) {
    return this.http.post(`${environment.apiUrl}rule-engine/allocation-rule-count`, obj);
  }
  createRuleWorkflow(obj) {
    return this.http.post(`${environment.apiUrl}rule-engine/workflow/create`, obj);
  }
  getLeadCountWorkflow(obj) {
    return this.http.post(`${environment.apiUrl}rule-engine/workflow/lead-count`, obj);
  }
  getListRuleWorkflow(filters) {
    return this.http.get(`${environment.apiUrl}rule-engine/workflow/list-rule`, {
      params: 
        filters,
  });
  }
  statusChangeRuleWorkflow(obj) {
    return this.http.post(`${environment.apiUrl}rule-engine/workflow/status-change-rule`, obj);
  }
  deleteRuleWorkflow(obj) {
    return this.http.post(`${environment.apiUrl}rule-engine/workflow/delete`, obj);
  }
}
