<!-- <div id="divLoading" class="divLoading" *ngIf="loading">
    <p><img src="assets/images/loader1.gif" /></p>
  </div> -->
<div id="page-wrapper" class="gray-bg dashbard-1">
  <div class="content-main">
    <div class="blank">
      <div class="access-denied flex-center justify-space-between">
        <div class="wrapper-div">
          <h2 class="fs18 mb-15">Access Denied !</h2>
          <p class="fs18 fsm16">
            We are sorry, but you do not have permission to access this page. Please contact the
            administrator. <br />
            Click to
            <a class="text-blue" href="/">Home</a>
          </p>
        </div>
        <img src="assets/images/access-denied.svg" alt="Access Denied" />
      </div>
    </div>
  </div>
</div>
