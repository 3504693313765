export const permissionArray = [
    {
      "id": 1,
      "permission_name": "ACCESS_ALL_DOWNLOADS"
    },
    {
      "id": 2,
      "permission_name": "ACCESS_BLUETOOTH_SHARE"
    },
    {
      "id": 3,
      "permission_name": "ACCESS_CACHE_FILESYSTEM"
    },
    {
      "id": 4,
      "permission_name": "ACCESS_CHECKIN_PROPERTIES"
    },
    {
      "id": 5,
      "permission_name": "ACCESS_CONTENT_PROVIDERS_EXTERNALLY"
    },
    {
      "id": 6,
      "permission_name": "ACCESS_DOWNLOAD_MANAGER"
    },
    {
      "id": 7,
      "permission_name": "ACCESS_DOWNLOAD_MANAGER_ADVANCED"
    },
    {
      "id": 8,
      "permission_name": "ACCESS_DRM_CERTIFICATES"
    },
    {
      "id": 9,
      "permission_name": "ACCESS_EPHEMERAL_APPS"
    },
    {
      "id": 10,
      "permission_name": "ACCESS_FM_RADIO"
    },
    {
      "id": 11,
      "permission_name": "ACCESS_INPUT_FLINGER"
    },
    {
      "id": 12,
      "permission_name": "ACCESS_KEYGUARD_SECURE_STORAGE"
    },
    {
      "id": 13,
      "permission_name": "ACCESS_LOCATION_EXTRA_COMMANDS"
    },
    {
      "id": 14,
      "permission_name": "ACCESS_MOCK_LOCATION"
    },
    {
      "id": 15,
      "permission_name": "ACCESS_MTP"
    },
    {
      "id": 16,
      "permission_name": "ACCESS_NETWORK_CONDITIONS"
    },
    {
      "id": 17,
      "permission_name": "ACCESS_NETWORK_STATE"
    },
    {
      "id": 18,
      "permission_name": "ACCESS_NOTIFICATIONS"
    },
    {
      "id": 19,
      "permission_name": "ACCESS_NOTIFICATION_POLICY"
    },
    {
      "id": 20,
      "permission_name": "ACCESS_PDB_STATE"
    },
    {
      "id": 21,
      "permission_name": "ACCESS_SURFACE_FLINGER"
    },
    {
      "id": 22,
      "permission_name": "ACCESS_VOICE_INTERACTION_SERVICE"
    },
    {
      "id": 23,
      "permission_name": "ACCESS_VR_MANAGER"
    },
    {
      "id": 24,
      "permission_name": "ACCESS_WIFI_STATE"
    },
    {
      "id": 25,
      "permission_name": "ACCESS_WIMAX_STATE"
    },
    {
      "id": 26,
      "permission_name": "ACCOUNT_MANAGER"
    },
    {
      "id": 27,
      "permission_name": "ALLOW_ANY_CODEC_FOR_PLAYBACK"
    },
    {
      "id": 28,
      "permission_name": "ASEC_ACCESS"
    },
    {
      "id": 29,
      "permission_name": "ASEC_CREATE"
    },
    {
      "id": 30,
      "permission_name": "ASEC_DESTROY"
    },
    {
      "id": 31,
      "permission_name": "ASEC_MOUNT_UNMOUNT"
    },
    {
      "id": 32,
      "permission_name": "ASEC_RENAME"
    },
    {
      "id": 33,
      "permission_name": "AUTHENTICATE_ACCOUNTS"
    },
    {
      "id": 34,
      "permission_name": "BACKUP"
    },
    {
      "id": 35,
      "permission_name": "BATTERY_STATS"
    },
    {
      "id": 36,
      "permission_name": "BIND_ACCESSIBILITY_SERVICE"
    },
    {
      "id": 37,
      "permission_name": "BIND_APPWIDGET"
    },
    {
      "id": 38,
      "permission_name": "BIND_CARRIER_MESSAGING_SERVICE"
    },
    {
      "id": 39,
      "permission_name": "BIND_CARRIER_SERVICES"
    },
    {
      "id": 40,
      "permission_name": "BIND_CHOOSER_TARGET_SERVICE"
    },
    {
      "id": 41,
      "permission_name": "BIND_CONDITION_PROVIDER_SERVICE"
    },
    {
      "id": 42,
      "permission_name": "BIND_CONNECTION_SERVICE"
    },
    {
      "id": 43,
      "permission_name": "BIND_DEVICE_ADMIN"
    },
    {
      "id": 44,
      "permission_name": "BIND_DIRECTORY_SEARCH"
    },
    {
      "id": 45,
      "permission_name": "BIND_DREAM_SERVICE"
    },
    {
      "id": 46,
      "permission_name": "BIND_INCALL_SERVICE"
    },
    {
      "id": 47,
      "permission_name": "BIND_INPUT_METHOD"
    },
    {
      "id": 48,
      "permission_name": "BIND_INTENT_FILTER_VERIFIER"
    },
    {
      "id": 49,
      "permission_name": "BIND_JOB_SERVICE"
    },
    {
      "id": 50,
      "permission_name": "BIND_KEYGUARD_APPWIDGET"
    },
    {
      "id": 51,
      "permission_name": "BIND_MIDI_DEVICE_SERVICE"
    },
    {
      "id": 52,
      "permission_name": "BIND_NFC_SERVICE"
    },
    {
      "id": 53,
      "permission_name": "BIND_NOTIFICATION_LISTENER_SERVICE"
    },
    {
      "id": 54,
      "permission_name": "BIND_NOTIFICATION_RANKER_SERVICE"
    },
    {
      "id": 55,
      "permission_name": "BIND_PACKAGE_VERIFIER"
    },
    {
      "id": 56,
      "permission_name": "BIND_PRINT_RECOMMENDATION_SERVICE"
    },
    {
      "id": 57,
      "permission_name": "BIND_PRINT_SERVICE"
    },
    {
      "id": 58,
      "permission_name": "BIND_PRINT_SPOOLER_SERVICE"
    },
    {
      "id": 59,
      "permission_name": "BIND_QUICK_SETTINGS_TILE"
    },
    {
      "id": 60,
      "permission_name": "BIND_REMOTEVIEWS"
    },
    {
      "id": 61,
      "permission_name": "BIND_REMOTE_DISPLAY"
    },
    {
      "id": 62,
      "permission_name": "BIND_ROUTE_PROVIDER"
    },
    {
      "id": 63,
      "permission_name": "BIND_RUNTIME_PERMISSION_PRESENTER_SERVICE"
    },
    {
      "id": 64,
      "permission_name": "BIND_SCREENING_SERVICE"
    },
    {
      "id": 65,
      "permission_name": "BIND_TELECOM_CONNECTION_SERVICE"
    },
    {
      "id": 66,
      "permission_name": "BIND_TEXT_SERVICE"
    },
    {
      "id": 67,
      "permission_name": "BIND_TRUST_AGENT"
    },
    {
      "id": 68,
      "permission_name": "BIND_TV_INPUT"
    },
    {
      "id": 69,
      "permission_name": "BIND_TV_REMOTE_SERVICE"
    },
    {
      "id": 70,
      "permission_name": "BIND_VOICE_INTERACTION"
    },
    {
      "id": 71,
      "permission_name": "BIND_VPN_SERVICE"
    },
    {
      "id": 72,
      "permission_name": "BIND_VR_LISTENER_SERVICE"
    },
    {
      "id": 73,
      "permission_name": "BIND_WALLPAPER"
    },
    {
      "id": 74,
      "permission_name": "BLUETOOTH"
    },
    {
      "id": 75,
      "permission_name": "BLUETOOTH_ADMIN"
    },
    {
      "id": 76,
      "permission_name": "BLUETOOTH_MAP"
    },
    {
      "id": 77,
      "permission_name": "BLUETOOTH_PRIVILEGED"
    },
    {
      "id": 78,
      "permission_name": "BLUETOOTH_STACK"
    },
    {
      "id": 79,
      "permission_name": "BRICK"
    },
    {
      "id": 80,
      "permission_name": "BROADCAST_CALLLOG_INFO"
    },
    {
      "id": 81,
      "permission_name": "BROADCAST_NETWORK_PRIVILEGED"
    },
    {
      "id": 82,
      "permission_name": "BROADCAST_PACKAGE_REMOVED"
    },
    {
      "id": 83,
      "permission_name": "BROADCAST_PHONE_ACCOUNT_REGISTRATION"
    },
    {
      "id": 84,
      "permission_name": "BROADCAST_SMS"
    },
    {
      "id": 85,
      "permission_name": "BROADCAST_STICKY"
    },
    {
      "id": 86,
      "permission_name": "BROADCAST_WAP_PUSH"
    },
    {
      "id": 87,
      "permission_name": "CACHE_CONTENT"
    },
    {
      "id": 88,
      "permission_name": "CALL_PRIVILEGED"
    },
    {
      "id": 89,
      "permission_name": "CAMERA_DISABLE_TRANSMIT_LED"
    },
    {
      "id": 90,
      "permission_name": "CAMERA_SEND_SYSTEM_EVENTS"
    },
    {
      "id": 91,
      "permission_name": "CAPTURE_AUDIO_HOTWORD"
    },
    {
      "id": 92,
      "permission_name": "CAPTURE_AUDIO_OUTPUT"
    },
    {
      "id": 93,
      "permission_name": "CAPTURE_SECURE_VIDEO_OUTPUT"
    },
    {
      "id": 94,
      "permission_name": "CAPTURE_TV_INPUT"
    },
    {
      "id": 95,
      "permission_name": "CAPTURE_VIDEO_OUTPUT"
    },
    {
      "id": 96,
      "permission_name": "CARRIER_FILTER_SMS"
    },
    {
      "id": 97,
      "permission_name": "CHANGE_APP_IDLE_STATE"
    },
    {
      "id": 98,
      "permission_name": "CHANGE_BACKGROUND_DATA_SETTING"
    },
    {
      "id": 99,
      "permission_name": "CHANGE_COMPONENT_ENABLED_STATE"
    },
    {
      "id": 100,
      "permission_name": "CHANGE_CONFIGURATION"
    },
    {
      "id": 101,
      "permission_name": "CHANGE_DEVICE_IDLE_TEMP_WHITELIST"
    },
    {
      "id": 102,
      "permission_name": "CHANGE_NETWORK_STATE"
    },
    {
      "id": 103,
      "permission_name": "CHANGE_WIFI_MULTICAST_STATE"
    },
    {
      "id": 104,
      "permission_name": "CHANGE_WIFI_STATE"
    },
    {
      "id": 105,
      "permission_name": "CHANGE_WIMAX_STATE"
    },
    {
      "id": 106,
      "permission_name": "CLEAR_APP_CACHE"
    },
    {
      "id": 107,
      "permission_name": "CLEAR_APP_GRANTED_URI_PERMISSIONS"
    },
    {
      "id": 108,
      "permission_name": "CLEAR_APP_USER_DATA"
    },
    {
      "id": 109,
      "permission_name": "CONFIGURE_DISPLAY_COLOR_TRANSFORM"
    },
    {
      "id": 110,
      "permission_name": "CONFIGURE_WIFI_DISPLAY"
    },
    {
      "id": 111,
      "permission_name": "CONFIRM_FULL_BACKUP"
    },
    {
      "id": 112,
      "permission_name": "CONNECTIVITY_INTERNAL"
    },
    {
      "id": 113,
      "permission_name": "CONTROL_INCALL_EXPERIENCE"
    },
    {
      "id": 114,
      "permission_name": "CONTROL_KEYGUARD"
    },
    {
      "id": 115,
      "permission_name": "CONTROL_LOCATION_UPDATES"
    },
    {
      "id": 116,
      "permission_name": "CONTROL_VPN"
    },
    {
      "id": 117,
      "permission_name": "CONTROL_WIFI_DISPLAY"
    },
    {
      "id": 118,
      "permission_name": "COPY_PROTECTED_DATA"
    },
    {
      "id": 119,
      "permission_name": "CREATE_USERS"
    },
    {
      "id": 120,
      "permission_name": "CRYPT_KEEPER"
    },
    {
      "id": 121,
      "permission_name": "DELETE_CACHE_FILES"
    },
    {
      "id": 122,
      "permission_name": "DELETE_PACKAGES"
    },
    {
      "id": 123,
      "permission_name": "DEVICE_POWER"
    },
    {
      "id": 124,
      "permission_name": "DIAGNOSTIC"
    },
    {
      "id": 125,
      "permission_name": "DISABLE_KEYGUARD"
    },
    {
      "id": 126,
      "permission_name": "DISPATCH_NFC_MESSAGE"
    },
    {
      "id": 127,
      "permission_name": "DISPATCH_PROVISIONING_MESSAGE"
    },
    {
      "id": 128,
      "permission_name": "DOWNLOAD_CACHE_NON_PURGEABLE"
    },
    {
      "id": 129,
      "permission_name": "DUMP"
    },
    {
      "id": 130,
      "permission_name": "DVB_DEVICE"
    },
    {
      "id": 131,
      "permission_name": "EXPAND_STATUS_BAR"
    },
    {
      "id": 132,
      "permission_name": "FACTORY_TEST"
    },
    {
      "id": 133,
      "permission_name": "FILTER_EVENTS"
    },
    {
      "id": 134,
      "permission_name": "FLASHLIGHT"
    },
    {
      "id": 135,
      "permission_name": "FORCE_BACK"
    },
    {
      "id": 136,
      "permission_name": "FORCE_STOP_PACKAGES"
    },
    {
      "id": 137,
      "permission_name": "FRAME_STATS"
    },
    {
      "id": 138,
      "permission_name": "FREEZE_SCREEN"
    },
    {
      "id": 139,
      "permission_name": "GET_ACCOUNTS_PRIVILEGED"
    },
    {
      "id": 140,
      "permission_name": "GET_APP_GRANTED_URI_PERMISSIONS"
    },
    {
      "id": 141,
      "permission_name": "GET_APP_OPS_STATS"
    },
    {
      "id": 142,
      "permission_name": "GET_DETAILED_TASKS"
    },
    {
      "id": 143,
      "permission_name": "GET_INTENT_SENDER_INTENT"
    },
    {
      "id": 144,
      "permission_name": "GET_PACKAGE_IMPORTANCE"
    },
    {
      "id": 145,
      "permission_name": "GET_PACKAGE_SIZE"
    },
    {
      "id": 146,
      "permission_name": "GET_PASSWORD"
    },
    {
      "id": 147,
      "permission_name": "GET_PROCESS_STATE_AND_OOM_SCORE"
    },
    {
      "id": 148,
      "permission_name": "GET_TASKS"
    },
    {
      "id": 149,
      "permission_name": "GET_TOP_ACTIVITY_INFO"
    },
    {
      "id": 150,
      "permission_name": "GLOBAL_SEARCH"
    },
    {
      "id": 151,
      "permission_name": "GLOBAL_SEARCH_CONTROL"
    },
    {
      "id": 152,
      "permission_name": "GRANT_RUNTIME_PERMISSIONS"
    },
    {
      "id": 153,
      "permission_name": "HARDWARE_TEST"
    },
    {
      "id": 154,
      "permission_name": "HDMI_CEC"
    },
    {
      "id": 155,
      "permission_name": "INJECT_EVENTS"
    },
    {
      "id": 156,
      "permission_name": "INSTALL_GRANT_RUNTIME_PERMISSIONS"
    },
    {
      "id": 157,
      "permission_name": "INSTALL_LOCATION_PROVIDER"
    },
    {
      "id": 158,
      "permission_name": "INSTALL_PACKAGES"
    },
    {
      "id": 159,
      "permission_name": "INTENT_FILTER_VERIFICATION_AGENT"
    },
    {
      "id": 160,
      "permission_name": "INTERACT_ACROSS_USERS"
    },
    {
      "id": 161,
      "permission_name": "INTERACT_ACROSS_USERS_FULL"
    },
    {
      "id": 162,
      "permission_name": "INTERNAL_SYSTEM_WINDOW"
    },
    {
      "id": 163,
      "permission_name": "INTERNET"
    },
    {
      "id": 164,
      "permission_name": "INVOKE_CARRIER_SETUP"
    },
    {
      "id": 165,
      "permission_name": "KILL_BACKGROUND_PROCESSES"
    },
    {
      "id": 166,
      "permission_name": "KILL_UID"
    },
    {
      "id": 167,
      "permission_name": "LAUNCH_TRUST_AGENT_SETTINGS"
    },
    {
      "id": 168,
      "permission_name": "LOCAL_MAC_ADDRESS"
    },
    {
      "id": 169,
      "permission_name": "LOCATION_HARDWARE"
    },
    {
      "id": 170,
      "permission_name": "LOOP_RADIO"
    },
    {
      "id": 171,
      "permission_name": "MANAGE_ACCOUNTS"
    },
    {
      "id": 172,
      "permission_name": "MANAGE_ACTIVITY_STACKS"
    },
    {
      "id": 173,
      "permission_name": "MANAGE_APP_OPS_RESTRICTIONS"
    },
    {
      "id": 174,
      "permission_name": "MANAGE_APP_TOKENS"
    },
    {
      "id": 175,
      "permission_name": "MANAGE_CA_CERTIFICATES"
    },
    {
      "id": 176,
      "permission_name": "MANAGE_DEVICE_ADMINS"
    },
    {
      "id": 177,
      "permission_name": "MANAGE_DOCUMENTS"
    },
    {
      "id": 178,
      "permission_name": "MANAGE_FINGERPRINT"
    },
    {
      "id": 179,
      "permission_name": "MANAGE_MEDIA_PROJECTION"
    },
    {
      "id": 180,
      "permission_name": "MANAGE_NETWORK_POLICY"
    },
    {
      "id": 181,
      "permission_name": "MANAGE_NOTIFICATIONS"
    },
    {
      "id": 182,
      "permission_name": "MANAGE_PROFILE_AND_DEVICE_OWNERS"
    },
    {
      "id": 183,
      "permission_name": "MANAGE_SOUND_TRIGGER"
    },
    {
      "id": 184,
      "permission_name": "MANAGE_USB"
    },
    {
      "id": 185,
      "permission_name": "MANAGE_USERS"
    },
    {
      "id": 186,
      "permission_name": "MANAGE_VOICE_KEYPHRASES"
    },
    {
      "id": 187,
      "permission_name": "MASTER_CLEAR"
    },
    {
      "id": 188,
      "permission_name": "MEDIA_CONTENT_CONTROL"
    },
    {
      "id": 189,
      "permission_name": "MODIFY_APPWIDGET_BIND_PERMISSIONS"
    },
    {
      "id": 190,
      "permission_name": "MODIFY_AUDIO_ROUTING"
    },
    {
      "id": 191,
      "permission_name": "MODIFY_AUDIO_SETTINGS"
    },
    {
      "id": 192,
      "permission_name": "MODIFY_CELL_BROADCASTS"
    },
    {
      "id": 193,
      "permission_name": "MODIFY_DAY_NIGHT_MODE"
    },
    {
      "id": 194,
      "permission_name": "MODIFY_NETWORK_ACCOUNTING"
    },
    {
      "id": 195,
      "permission_name": "MODIFY_PARENTAL_CONTROLS"
    },
    {
      "id": 196,
      "permission_name": "MODIFY_PHONE_STATE"
    },
    {
      "id": 197,
      "permission_name": "MOUNT_FORMAT_FILESYSTEMS"
    },
    {
      "id": 198,
      "permission_name": "MOUNT_UNMOUNT_FILESYSTEMS"
    },
    {
      "id": 199,
      "permission_name": "MOVE_PACKAGE"
    },
    {
      "id": 200,
      "permission_name": "NET_ADMIN"
    },
    {
      "id": 201,
      "permission_name": "NET_TUNNELING"
    },
    {
      "id": 202,
      "permission_name": "NFC"
    },
    {
      "id": 203,
      "permission_name": "NFC_HANDOVER_STATUS"
    },
    {
      "id": 204,
      "permission_name": "NOTIFY_PENDING_SYSTEM_UPDATE"
    },
    {
      "id": 205,
      "permission_name": "OBSERVE_GRANT_REVOKE_PERMISSIONS"
    },
    {
      "id": 206,
      "permission_name": "OEM_UNLOCK_STATE"
    },
    {
      "id": 207,
      "permission_name": "OVERRIDE_WIFI_CONFIG"
    },
    {
      "id": 208,
      "permission_name": "PACKAGE_USAGE_STATS"
    },
    {
      "id": 209,
      "permission_name": "PACKAGE_VERIFICATION_AGENT"
    },
    {
      "id": 210,
      "permission_name": "PACKET_KEEPALIVE_OFFLOAD"
    },
    {
      "id": 211,
      "permission_name": "PEERS_MAC_ADDRESS"
    },
    {
      "id": 212,
      "permission_name": "PERFORM_CDMA_PROVISIONING"
    },
    {
      "id": 213,
      "permission_name": "PERFORM_SIM_ACTIVATION"
    },
    {
      "id": 214,
      "permission_name": "PERSISTENT_ACTIVITY"
    },
    {
      "id": 215,
      "permission_name": "PROCESS_CALLLOG_INFO"
    },
    {
      "id": 216,
      "permission_name": "PROCESS_PHONE_ACCOUNT_REGISTRATION"
    },
    {
      "id": 217,
      "permission_name": "PROVIDE_TRUST_AGENT"
    },
    {
      "id": 218,
      "permission_name": "QUERY_DO_NOT_ASK_CREDENTIALS_ON_BOOT"
    },
    {
      "id": 219,
      "permission_name": "READ_BLOCKED_NUMBERS"
    },
    {
      "id": 220,
      "permission_name": "READ_DREAM_STATE"
    },
    {
      "id": 221,
      "permission_name": "READ_FRAME_BUFFER"
    },
    {
      "id": 222,
      "permission_name": "READ_INPUT_STATE"
    },
    {
      "id": 223,
      "permission_name": "READ_INSTALL_SESSIONS"
    },
    {
      "id": 224,
      "permission_name": "READ_LOGS"
    },
    {
      "id": 225,
      "permission_name": "READ_NETWORK_USAGE_HISTORY"
    },
    {
      "id": 226,
      "permission_name": "READ_OEM_UNLOCK_STATE"
    },
    {
      "id": 227,
      "permission_name": "READ_PRECISE_PHONE_STATE"
    },
    {
      "id": 228,
      "permission_name": "READ_PRIVILEGED_PHONE_STATE"
    },
    {
      "id": 229,
      "permission_name": "READ_PROFILE"
    },
    {
      "id": 230,
      "permission_name": "READ_SEARCH_INDEXABLES"
    },
    {
      "id": 231,
      "permission_name": "READ_SOCIAL_STREAM"
    },
    {
      "id": 232,
      "permission_name": "READ_SYNC_SETTINGS"
    },
    {
      "id": 233,
      "permission_name": "READ_SYNC_STATS"
    },
    {
      "id": 234,
      "permission_name": "READ_USER_DICTIONARY"
    },
    {
      "id": 235,
      "permission_name": "READ_WIFI_CREDENTIAL"
    },
    {
      "id": 236,
      "permission_name": "REAL_GET_TASKS"
    },
    {
      "id": 237,
      "permission_name": "REBOOT"
    },
    {
      "id": 238,
      "permission_name": "RECEIVE_BLUETOOTH_MAP"
    },
    {
      "id": 239,
      "permission_name": "RECEIVE_BOOT_COMPLETED"
    },
    {
      "id": 240,
      "permission_name": "RECEIVE_DATA_ACTIVITY_CHANGE"
    },
    {
      "id": 241,
      "permission_name": "RECEIVE_EMERGENCY_BROADCAST"
    },
    {
      "id": 242,
      "permission_name": "RECEIVE_MEDIA_RESOURCE_USAGE"
    },
    {
      "id": 243,
      "permission_name": "RECEIVE_STK_COMMANDS"
    },
    {
      "id": 244,
      "permission_name": "RECEIVE_WIFI_CREDENTIAL_CHANGE"
    },
    {
      "id": 245,
      "permission_name": "RECOVERY"
    },
    {
      "id": 246,
      "permission_name": "REGISTER_CALL_PROVIDER"
    },
    {
      "id": 247,
      "permission_name": "REGISTER_CONNECTION_MANAGER"
    },
    {
      "id": 248,
      "permission_name": "REGISTER_SIM_SUBSCRIPTION"
    },
    {
      "id": 249,
      "permission_name": "REGISTER_WINDOW_MANAGER_LISTENERS"
    },
    {
      "id": 250,
      "permission_name": "REMOTE_AUDIO_PLAYBACK"
    },
    {
      "id": 251,
      "permission_name": "REMOVE_DRM_CERTIFICATES"
    },
    {
      "id": 252,
      "permission_name": "REMOVE_TASKS"
    },
    {
      "id": 253,
      "permission_name": "REORDER_TASKS"
    },
    {
      "id": 254,
      "permission_name": "REQUEST_IGNORE_BATTERY_OPTIMIZATIONS"
    },
    {
      "id": 255,
      "permission_name": "REQUEST_INSTALL_PACKAGES"
    },
    {
      "id": 256,
      "permission_name": "RESET_FINGERPRINT_LOCKOUT"
    },
    {
      "id": 257,
      "permission_name": "RESET_SHORTCUT_MANAGER_THROTTLING"
    },
    {
      "id": 258,
      "permission_name": "RESTART_PACKAGES"
    },
    {
      "id": 259,
      "permission_name": "RETRIEVE_WINDOW_CONTENT"
    },
    {
      "id": 260,
      "permission_name": "RETRIEVE_WINDOW_TOKEN"
    },
    {
      "id": 261,
      "permission_name": "REVOKE_RUNTIME_PERMISSIONS"
    },
    {
      "id": 262,
      "permission_name": "SCORE_NETWORKS"
    },
    {
      "id": 263,
      "permission_name": "SEND_CALL_LOG_CHANGE"
    },
    {
      "id": 264,
      "permission_name": "SEND_DOWNLOAD_COMPLETED_INTENTS"
    },
    {
      "id": 265,
      "permission_name": "SEND_RESPOND_VIA_MESSAGE"
    },
    {
      "id": 266,
      "permission_name": "SEND_SMS_NO_CONFIRMATION"
    },
    {
      "id": 267,
      "permission_name": "SERIAL_PORT"
    },
    {
      "id": 268,
      "permission_name": "SET_ACTIVITY_WATCHER"
    },
    {
      "id": 269,
      "permission_name": "SET_ALWAYS_FINISH"
    },
    {
      "id": 270,
      "permission_name": "SET_ANIMATION_SCALE"
    },
    {
      "id": 271,
      "permission_name": "SET_DEBUG_APP"
    },
    {
      "id": 272,
      "permission_name": "SET_INPUT_CALIBRATION"
    },
    {
      "id": 273,
      "permission_name": "SET_KEYBOARD_LAYOUT"
    },
    {
      "id": 274,
      "permission_name": "SET_ORIENTATION"
    },
    {
      "id": 275,
      "permission_name": "SET_POINTER_SPEED"
    },
    {
      "id": 276,
      "permission_name": "SET_PREFERRED_APPLICATIONS"
    },
    {
      "id": 277,
      "permission_name": "SET_PROCESS_LIMIT"
    },
    {
      "id": 278,
      "permission_name": "SET_SCREEN_COMPATIBILITY"
    },
    {
      "id": 279,
      "permission_name": "SET_TIME"
    },
    {
      "id": 280,
      "permission_name": "SET_TIME_ZONE"
    },
    {
      "id": 281,
      "permission_name": "SET_WALLPAPER"
    },
    {
      "id": 282,
      "permission_name": "SET_WALLPAPER_COMPONENT"
    },
    {
      "id": 283,
      "permission_name": "SET_WALLPAPER_HINTS"
    },
    {
      "id": 284,
      "permission_name": "SHUTDOWN"
    },
    {
      "id": 285,
      "permission_name": "SIGNAL_PERSISTENT_PROCESSES"
    },
    {
      "id": 286,
      "permission_name": "START_ANY_ACTIVITY"
    },
    {
      "id": 287,
      "permission_name": "START_PRINT_SERVICE_CONFIG_ACTIVITY"
    },
    {
      "id": 288,
      "permission_name": "START_TASKS_FROM_RECENTS"
    },
    {
      "id": 289,
      "permission_name": "STATUS_BAR"
    },
    {
      "id": 290,
      "permission_name": "STATUS_BAR_SERVICE"
    },
    {
      "id": 291,
      "permission_name": "STOP_APP_SWITCHES"
    },
    {
      "id": 292,
      "permission_name": "STORAGE_INTERNAL"
    },
    {
      "id": 293,
      "permission_name": "SUBSCRIBED_FEEDS_READ"
    },
    {
      "id": 294,
      "permission_name": "SUBSCRIBED_FEEDS_WRITE"
    },
    {
      "id": 295,
      "permission_name": "SUBSTITUTE_NOTIFICATION_APP_NAME"
    },
    {
      "id": 296,
      "permission_name": "SYSTEM_ALERT_WINDOW"
    },
    {
      "id": 297,
      "permission_name": "TABLET_MODE"
    },
    {
      "id": 298,
      "permission_name": "TEMPORARY_ENABLE_ACCESSIBILITY"
    },
    {
      "id": 299,
      "permission_name": "TETHER_PRIVILEGED"
    },
    {
      "id": 300,
      "permission_name": "TRANSMIT_IR"
    },
    {
      "id": 301,
      "permission_name": "TRUST_LISTENER"
    },
    {
      "id": 302,
      "permission_name": "TV_INPUT_HARDWARE"
    },
    {
      "id": 303,
      "permission_name": "TV_VIRTUAL_REMOTE_CONTROLLER"
    },
    {
      "id": 304,
      "permission_name": "UPDATE_APP_OPS_STATS"
    },
    {
      "id": 305,
      "permission_name": "UPDATE_CONFIG"
    },
    {
      "id": 306,
      "permission_name": "UPDATE_DEVICE_STATS"
    },
    {
      "id": 307,
      "permission_name": "UPDATE_LOCK"
    },
    {
      "id": 308,
      "permission_name": "UPDATE_LOCK_TASK_PACKAGES"
    },
    {
      "id": 309,
      "permission_name": "USER_ACTIVITY"
    },
    {
      "id": 310,
      "permission_name": "USE_CREDENTIALS"
    },
    {
      "id": 311,
      "permission_name": "VIBRATE"
    },
    {
      "id": 312,
      "permission_name": "WAKE_LOCK"
    },
    {
      "id": 313,
      "permission_name": "WRITE_APN_SETTINGS"
    },
    {
      "id": 314,
      "permission_name": "WRITE_BLOCKED_NUMBERS"
    },
    {
      "id": 315,
      "permission_name": "WRITE_DREAM_STATE"
    },
    {
      "id": 316,
      "permission_name": "WRITE_GSERVICES"
    },
    {
      "id": 317,
      "permission_name": "WRITE_MEDIA_STORAGE"
    },
    {
      "id": 318,
      "permission_name": "WRITE_PROFILE"
    },
    {
      "id": 319,
      "permission_name": "WRITE_SECURE_SETTINGS"
    },
    {
      "id": 320,
      "permission_name": "WRITE_SETTINGS"
    },
    {
      "id": 321,
      "permission_name": "WRITE_SMS"
    },
    {
      "id": 322,
      "permission_name": "WRITE_SOCIAL_STREAM"
    },
    {
      "id": 323,
      "permission_name": "WRITE_SYNC_SETTINGS"
    },
    {
      "id": 324,
      "permission_name": "WRITE_USER_DICTIONARY"
    }
  ]

