import { Component } from '@angular/core';
import { TenantService } from '../../../_services/tenant.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { FormBuilderService } from 'src/app/_services/form-builder.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-import-report',
  templateUrl: './import-report.component.html',
  styleUrls: ['./import-report.component.css'],
})
export class ImportReportComponent {
  Reports: any[] = [];
  filters = {
    formType: '',
    importedBy: '' as any,
    importStatus: '',
    dataRange: null as any,
  };
  maxDate = moment();
  invalidDates: moment.Moment[] = [
    moment().add(2, 'days'),
    moment().add(3, 'days'),
    moment().add(5, 'days'),
  ];
  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some(d => d.isSame(m, 'day'));
  };
  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
  };

  dummyData = {
    formType: [],
    importedBy: [{ name: 'Imported By', id: '' }] as any,
    importStatus: ['Completed', 'Failed'],
  };
  filteredDummyData = { ...this.dummyData };

  selectedTenant: any = null;
  selectedApplicationIds: any[] = [];
  communicationApplicationArr: any[] = [];
  checkedCheckboxCount: number = 0;
  selectAll: boolean = false;
  serviceForm!: FormGroup;
  serviceFormFields: any;
  filterStatus: string = '';
  currentPage: number = 1;
  filteredReports: any[] = [];
  pageSize: number = 10;

  selectedStatus: string = '';
  selectedService: string = '';
  loading: boolean = false;

  constructor(
    private tenantService: TenantService,
    private formBuilder: FormBuilder,
    private toasterService: ToastrService,
    private formBuilderService: FormBuilderService
  ) {}

  ngOnInit(): void {
    this.fetchImportReportForms();
    this.fetchImportedByList();
    this.fetchReportDetails();
  }

  fetchReportDetails() {
    this.loading = true;
    const { formType, importStatus, dataRange } = this.filters;

    const importedBy =
      this.filters.importedBy && this.filters.importedBy._id ? this.filters.importedBy._id : '';

    const dateFrom = dataRange && dataRange.start ? this.convertToIST(dataRange.start._d) : '';

    const dateTo = dataRange && dataRange.end ? this.convertToIST(dataRange.end._d) : '';

    console.log(this.filters);

    this.formBuilderService
      .getImportReport(
        this.currentPage,
        this.pageSize,
        importedBy,
        dateFrom,
        dateTo,
        importStatus,
        formType
      )
      .subscribe(
        (res: any) => {
          this.Reports = res.data;
          this.loading = false;
          this.toasterService.success('Report fetched successfully');
        },
        error => {
          this.loading = false;
          this.toasterService.error('Error in Fetching Form Data');
        }
      );
  }

  fetchImportedByList() {
    this.formBuilderService.getImportedByList().subscribe(
      (res: any) => {
        this.dummyData.importedBy = [...this.dummyData.importedBy, ...res.users];
        this.filteredDummyData.importedBy = this.dummyData.importedBy;
        console.log(this.dummyData.importedBy);
      },
      (err: any) => {
        this.toasterService.error('Error while fetching Imported By list');
      }
    );
  }

  fetchImportReportForms() {
    this.formBuilderService.getImportReportForms().subscribe(
      (res: any) => {
        this.dummyData.formType = res && res.forms ? res.forms : [];
        this.filteredDummyData.formType = this.dummyData.formType;
        console.log(this.dummyData.formType);
      },
      (err: any) => {
        this.toasterService.error('Error fetching form types');
      }
    );
  }

  handleDropDownClick(e: any) {
    if (e.target.tagName === 'INPUT') {
      e.stopPropagation();
    }
  }

  handleStatusSearch(e: any) {
    const value = e.target.value.trim().toLowerCase();
    this.filteredDummyData.importStatus = this.dummyData.importStatus.filter(item =>
      item.toLowerCase().includes(value)
    );
  }

  handleImportedBySearch(e: any) {
    const value = e.target.value.trim().toLowerCase();
    this.filteredDummyData.importedBy = this.dummyData.importedBy.filter(item =>
      item.name.toLowerCase().includes(value)
    );
  }

  handleFormTypeSearch(e: any) {
    const value = e.target.value.trim().toLowerCase();
    this.filteredDummyData.formType = this.dummyData.formType.filter(item =>
      item.toLowerCase().includes(value)
    );
  }

  handleSelectStatus(value: string) {
    this.filters.importStatus = value;
  }

  handleSelectImportedBy(value: string) {
    this.filters.importedBy = value;
  }

  handleSelectFormType(value: string) {
    this.filters.formType = value;
  }

  resetFilters() {
    this.filters.formType = '';
    this.filters.dataRange = null;
    this.filters.importStatus = '';
    this.filters.importedBy = '';
    this.fetchReportDetails(); // Fetch reports again with reset filters
  }

  downloadCSV(failedApplications: any[]) {
    const csvData = this.convertToCSV(failedApplications);
    const blob = new Blob([csvData], { type: 'text/csv' });
    saveAs(blob, 'failed_applications.csv');
  }

  convertToCSV(objArray: any[]): string {
    const array = [Object.keys(objArray[0])].concat(objArray);
    return array
      .map(row => {
        return Object.values(row)
          .map(value => {
            return typeof value === 'string' ? `"${value.replace(/"/g, '""')}"` : value;
          })
          .join(',');
      })
      .join('\r\n');
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.fetchReportDetails();
    }
  }

  nextPage() {
    this.currentPage++;
    this.fetchReportDetails();
  }

  convertToIST(utcDate) {
    const utcTime = new Date(utcDate);
    const istOffset = 5.5 * 60 * 60 * 1000; // 5.5 hours in milliseconds
    const istDate = new Date(utcTime.getTime() + istOffset);
    return istDate.toISOString();
  }
}
