<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->

<div id="page-wrapper">
  <div class="blank">
    <div class="importReport-header">
      <h4>Manage Accounts</h4>

      <button
        type="button"
        data-toggle="modal"
        data-target="#importReport-filter-Modal"
        class="filter-button">
        <svg class="dash-icons">
          <use href="assets/images/icon.svg#filter" width="16px" height="16px"></use>
        </svg>
        <p>Filter</p>
      </button>
      <div id="importReport-filter-Modal" class="modal static-modal fade">
        <div class="modal-filter">
          <div class="modal-filter-header">
            <h4 class="fw700">Filter</h4>
            <svg class="dash-icons" data-dismiss="modal" data-target="#importReport-filter-Modal">
              <use href="assets/images/icon.svg#cross" width="10px"></use>
            </svg>
          </div>
          <div class="modal-filter-body">
            <div class="input-container">
              <div class="select-search">
                <button data-toggle="dropdown">
                  <p *ngIf="!filters.formType" class="dropdown-label">Select an Option</p>
                  <p *ngIf="filters.formType">{{ filters.formType }}</p>
                  <svg class="dash-icons">
                    <use href="assets/images/icon.svg#arrow-dn" width="10px"></use>
                  </svg>
                </button>
                <div class="dropdown-menu" (click)="handleDropDownClick($event)">
                  <div class="dropdown-container">
                    <div class="dropdown-input">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search"
                        (keyup)="handleFormTypeSearch($event)" />
                    </div>
                    <ul *ngIf="filteredDummyData.formType?.length >= 0">
                      <li
                        *ngFor="let item of filteredDummyData.formType"
                        (click)="handleSelectFormType(item)">
                        {{ item }}
                      </li>
                    </ul>
                    <p *ngIf="filteredDummyData.formType?.length === 0">Not found</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-container">
              <div class="select-search">
                <button data-toggle="dropdown">
                  <p *ngIf="!filters.importedBy" class="dropdown-label">Imported By</p>
                  <p *ngIf="filters.importedBy">{{ filters.importedBy?.name }}</p>
                  <svg class="dash-icons">
                    <use href="assets/images/icon.svg#arrow-dn" width="10px"></use>
                  </svg>
                </button>
                <div class="dropdown-menu" (click)="handleDropDownClick($event)">
                  <div class="dropdown-container">
                    <div class="dropdown-input">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search"
                        (keyup)="handleImportedBySearch($event)" />
                    </div>
                    <ul *ngIf="filteredDummyData.importedBy?.length >= 0">
                      <li
                        *ngFor="let item of filteredDummyData.importedBy"
                        (click)="handleSelectImportedBy(item)">
                        {{ item?.name }}
                      </li>
                    </ul>
                    <p *ngIf="filteredDummyData.importedBy?.length === 0">Not found</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-container">
              <div class="select-search">
                <button data-toggle="dropdown">
                  <p *ngIf="!filters.importStatus" class="dropdown-label">Import Status</p>
                  <p *ngIf="filters.importStatus">{{ filters.importStatus }}</p>
                  <svg class="dash-icons">
                    <use href="assets/images/icon.svg#arrow-dn" width="10px"></use>
                  </svg>
                </button>
                <div class="dropdown-menu" (click)="handleDropDownClick($event)">
                  <div class="dropdown-container">
                    <div class="dropdown-input">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search"
                        (keyup)="handleStatusSearch($event)" />
                    </div>
                    <ul *ngIf="filteredDummyData.importStatus?.length >= 0">
                      <li
                        *ngFor="let item of filteredDummyData.importStatus"
                        (click)="handleSelectStatus(item)">
                        {{ item }}
                      </li>
                    </ul>
                    <p *ngIf="filteredDummyData.importStatus?.length === 0">Not found</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-container">
              <div class="col-sm-4 col-xs-12 datepicker normal-form">
                <input
                  type="text"
                  class="form-control dateTimePicker"
                  ngxDaterangepickerMd
                  [(ngModel)]="filters.dataRange"
                  [alwaysShowCalendars]="true"
                  [showCustomRangeLabel]="true"
                  startKey="start"
                  [ranges]="ranges"
                  endKey="end"
                  open="center"
                  keepCalendarOpeningWithRange="true"
                  [maxDate]="maxDate"
                  [isInvalidDate]="isInvalidDate"
                  [showClearButton]="true"
                  placeholder="Select a Range" />
              </div>
            </div>
          </div>
          <div class="modal-filter-footer">
            <button class="flex-center reset-button" (click)="resetFilters()">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#rotate-ccw" width="15px"></use>
              </svg>
              <p>Reset</p>
            </button>
            <button class="apply-button" (click)="fetchReportDetails()">Apply</button>
          </div>
        </div>
      </div>
    </div>

    <div class="gray-bg dashbard-1">
      <div class="">
        <div class="lead-table">
          <table class="table table-condensed">
            <thead>
              <tr>
                <th>
                  <div class="th-bg">
                    <div class="select-radio-btn text-center">
                      <!-- <label class="container_radio">
                    <input type="checkbox" [(ngModel)]="selectAll" (change)="onSelectAllChange()" />
                    <span class="checkmark"></span>
                  </label> -->
                    </div>
                  </div>
                </th>
                <th>
                  <div class="th-bg">Import Date & Time</div>
                </th>
                <th>
                  <div class="th-bg">Forms</div>
                </th>
                <th>
                  <div class="th-bg">Uploaded With</div>
                </th>
                <th>
                  <div class="th-bg">Uploaded Type</div>
                </th>
                <th>
                  <div class="th-bg">Import Status</div>
                </th>
                <th>
                  <div class="th-bg">Import By</div>
                </th>
                <th>
                  <div class="th-bg">Application Processed</div>
                </th>
                <th>
                  <div class="th-bg">Successfully Imported Applications</div>
                </th>
                <th>
                  <div class="th-bg">Skipped Application</div>
                </th>
                <th>
                  <div class="th-bg">Failed Application</div>
                </th>
                <th>
                  <div class="th-bg">Updated Applications</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let report of Reports">
                <td>
                  <div class="select-radio-btn">
                    <!-- <label class="container_radio">
                  <input
                    type="checkbox"
                    [(ngModel)]="tenant.checked"
                    (change)="onCheckboxChange(tenant)" />
                  <span class="checkmark"></span>
                </label> -->
                  </div>
                </td>
                <td>{{ report?.created_at | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
                <td>{{ report?.form }}</td>
                <td>{{ report?.uploaded_with }}</td>
                <td>{{ report?.uploaded_type }}</td>
                <td>{{ report?.import_status }}</td>
                <td>{{ report?.import_by?.name }}</td>
                <td>{{ report?.application_processed }}</td>
                <td>{{ report?.successfully_imported_applications }}</td>
                <td>{{ report?.skipped_applications }}</td>
                <td>
                  <a (click)="downloadCSV(report?.failed_applications)" href="javascript:void(0)">
                    {{ report?.failed_applications?.length }}
                  </a>
                </td>
                <td>{{ report?.updated_applications }}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Pagination controls -->
        <div class="pagination-container">
          <button class="btn" (click)="prevPage()">Previous</button>
          <span>Page {{ currentPage }}</span>
          <button class="btn btn-next" (click)="nextPage()">Next</button>
        </div>
      </div>
    </div>
  </div>
</div>
