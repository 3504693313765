<div id="divLoading" class="divLoading" *ngIf="loading">
  <p><img src="assets/images/loader1.gif" /></p>
</div>
<nav class="navbar-default" role="navigation">
  <div class="navbar-header">
    <button
      type="button"
      class="btn toggle-btn"
      (click)="toggleBtn()"
      data-toggle="collapse"
      data-target=".navbar-collapse">
      <svg class="dash-icons">
        <use href="assets/images/icon.svg#mob-menu"></use>
      </svg>
    </button>
  </div>
  <div class="border-bottom">
    <div class="header-wraper flex-center flex-wrap justify-content-end">
      <app-tracking-active-user></app-tracking-active-user>
      <div class="filter-sort-holder">
        <div class="dropdown">
          <button
            class="btn dropdown-toggle flex-center dropdown-btn justify-space-between fs12"
            type="button"
            data-toggle="dropdown">
            {{button == 0 ? 'Online' : 'Offline'}}
            <svg
              class="pull-right hidden-xs"
              aria-label="down icon"
              style="width: 10px; height: 10px">
              <use href="assets/images/icon.svg#arrow-dn" />
            </svg>
          </button>
          <ul class="dropdown-menu dropdown-item">
            <li (click)="breakButton('Online')">
              <a>Online</a>
            </li>
            <li (click)="breakButton('Offline')">
              <a data-toggle="modal" data-target="#breakModal">Break</a>
            </li>
          </ul>
        </div>
      </div>
      <!-- notification -->
      <ul class="custom_dropdown flex-center">
        <li class="dropdown user-menu notification">
          <a
            href="#"
            class="dropdown-toggle flex-center position-relative"
            data-toggle="dropdown"
            aria-expanded="false">
            <svg class="dash-icons notify">
              <use href="assets/images/icon.svg#notification" />
            </svg>
            <span
              *ngIf="notificationsArrayUnreadCount > 0"
              title="{{ notificationsArrayUnreadCount }}"
              class="text-white fw700 count-no flex-center justify-content-center"
              >{{ notificationsArrayUnreadCount > 999? '999+' : notificationsArrayUnreadCount}}
            </span>
          </a>
          <div class="dropdown-menu dropdown-item dropdown-menu-right user-profile">
            <form>
              <div class="flex-center justify-space-between padding-10 pd-10 notify-header">
                <h2 class="fs16 fw700">Notifications</h2>
                <a
                  *ngIf="notificationsArray.length > 0"
                  (click)="markAsreadAllUserNotifications()"
                  class="fs12 fsm12 text-blue">
                  Mark all as read
                </a>
              </div>
              <ul class="scrollbar">
                <li
                  *ngFor="let item of notificationsArray; trackBy: trackByNotification"
                  [ngClass]="{'unread-notification': !item.read}">
                  <a
                    *ngIf="item.notifications.type === 'lead_refered' || item.notifications.type === 'leave' || item.notifications.type === 'lead_csv_download'"
                    (click)="ReadTracker(item)"
                    class="text-black notification flex-column"
                    [routerLink]="
                      item.notifications.type === 'lead_refered' ? ['/leads'] :
                      item.notifications.type === 'leave' ? ['/employee/leave'] :
                      item.notifications.type === 'lead_csv_download' ? ['/employee/authentication'] : null
                    "
                    [queryParams]="item.notifications.type === 'lead_refered' ? { type: 'assigned-leads', n_id: item._id } :
                                   item.notifications.type === 'lead_csv_download' ? { activateCsvExportTab: true } : null">
                    <div class="flex-center flex-center gap-10 justify-space-between">
                      <div class="flex-center gap-10">
                        <svg class="dash-icons">
                          <use
                            [attr.href]="
                              item.notifications.type === 'lead_refered' ? 'assets/images/icon.svg#owner-transfer' :
                              item.notifications.type === 'leave' ? 'assets/images/icon.svg#leave' :
                              item.notifications.type === 'lead_csv_download' ? 'assets/images/icon.svg#download' : null
                            "></use>
                        </svg>
                        {{ item.notifications.message }}
                      </div>
                      <button
                        *ngIf="!item.read"
                        (click)="ReadTracker(item)"
                        class="notification-unread-dot"
                        title="Mark as read">
                        <svg
                          data-testid="unread-indicator"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24px"
                          fill="none"
                          height="24px">
                          <circle
                            cx="50%"
                            cy="50%"
                            r="4"
                            fill="var(--ds-icon-brand, #5e3ec5)"></circle>
                        </svg>
                      </button>
                    </div>
                    <p class="fs10 text-right text-grey-dark mt-5">
                      {{ calculateTimeDifference(item.notifications.time) }}
                    </p>
                  </a>
                </li>
                <li *ngIf="!notificationsArray.length">No Notification Available</li>
                <li class="text-center see-all" *ngIf="showLoadMore" (click)="loadMore()">
                  <a class="text-blue flex-center justify-content-center">See All</a>
                </li>
              </ul>
            </form>
          </div>
        </li>
      </ul>
      <ul class="custom_dropdown flex-center">
        <li class="dropdown user-menu">
          <a
            href="#"
            class="dropdown-toggle display-flex align-items-center user-info"
            data-toggle="dropdown"
            aria-expanded="false">
            <figure class="flex-center">
              <div class="user-dp">
                <img src="assets/images/user.svg" class="user-image" alt="User Image" />
              </div>
              <figcaption class="flex-center justify-space-between">
                <div>
                  <span class="text-capitalize">{{currentUser.name}}</span>
                </div>
                <svg aria-label="down icon" style="width: 10px; height: 10px">
                  <use href="assets/images/icon.svg#arrow-dn" />
                </svg>
              </figcaption>
            </figure>
          </a>
          <ul class="dropdown-menu dropdown-item dropdown-menu-right user-profile">
            <form>
              <li>
                <div class="flex-center user_info">
                  <figure class="display-flex justify-content-center align-items-center">
                    <h1>{{getShortName(currentUser.name)}}</h1>
                  </figure>
                  <div>
                    <h4>{{currentUser.name}}</h4>
                    <p>{{currentUser.email}}</p>
                    <h6 class="margin-t5 mr-t5">Role: {{currentUser.role_name}}</h6>
                  </div>
                </div>
              </li>
              <li class="query-action">
                <h5>Have A Query? Contact</h5>
                <span class="flex-center margin-t5 mr-t5">
                  <svg class="dash-icons-sm margin-r5 mr-r5">
                    <use href="assets/images/icon.svg#email" />
                  </svg>
                  <a href="mailto:{{persistUser.support_email}}"
                    ><span> {{persistUser.support_email}}</span></a
                  >
                </span>
                <span class="flex-center margin-t5 mr-t5">
                  <svg class="dash-icons-sm margin-r5 mr-r5">
                    <use href="assets/images/icon.svg#phone" />
                  </svg>
                  <a href="tel:‎+91 {{persistUser.support_number}}"
                    >‎+91 {{persistUser.support_number}}</a
                  >
                </span>
              </li>
              <li class="user-logout form-btn flex-center gap-5">
                <a routerLink="/profile" class="btn btn-blue fs">Change Password</a>
                <a routerLink="/profile" class="btn btn-blue">My Account</a>
                <button (click)="logout()" class="btn btn-orange">Logout</button>
              </li>
            </form>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="modal fade static-modal" tabindex="-1" id="breakModal" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="fs16 fw700">Break Reason</h2>
        <a class="close-btn" data-dismiss="modal" aria-label="Close" id="closeBreakPopup">
          <svg class="dash-icons">
            <use href="assets/images/icon.svg#close-btn" />
          </svg>
        </a>
      </div>
      <div class="modal-body text-center">
        <div class="form-group">
          <select (change)="breakReason($event)" class="form-control">
            <option value="">Select</option>
            <option value="Manual Call">Manual Call</option>
            <option value="Customer Counselling">Customer Counselling</option>
            <option value="Lunch Break">Lunch Break</option>
            <option value="Tea / Coffee Break">Tea / Coffee Break</option>
          </select>
        </div>
        <div class="form-group form-btn">
          <button class="btn btn-blue" (click)="breakSubmit()">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>
