<!-- eslint-disable prettier/prettier -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div class="navbar-default sidebar" role="navigation">
  <div class="sidebar-nav navbar-collapse collapse" id="navbar-overlay">
    <div class="navbar-overlay" (click)="closeNavBar()"></div>
    <figure id="hide_mob">
      <a [routerLink]="['/tenant-profile/', tenantDetail.id]"
        ><img src="{{ hostname }}{{ tenantDetail.logo_image }}"
      /></a>
    </figure>
    <ul class="nav scrollbar" id="sidemenu" (click)="handleNavLinkClick($event)">
      <li style="display: none" id="first_menu">
        <figure>
          <a [routerLink]="['/tenant-profile/', tenantDetail.id]"
            ><img src="{{ hostname }}{{ tenantDetail.logo_image }}"
          /></a>
        </figure>
      </li>
      <li [ngClass]="{ active: activeClick == 'dashboard' }">
        <a
          routerLink="/dashboard"
          class=""
          routerLinkActive="active"
          (click)="activeclick('dashboard')">
          <svg class="dash-icons">
            <use href="assets/images/icon.svg#dashboard"></use>
          </svg>
          <span class="nav-label">Dashboard</span>
        </a>
      </li>
      <li
        [ngClass]="{ active: activeClick == 'publishers' }"
        *ngIf="user_role == 1 || filterByCodeAndLevel('Publisher',6)">
        <a routerLink="/publishers" routerLinkActive="active" (click)="activeclick('publishers')">
          <svg class="dash-icons">
            <use href="assets/images/icon.svg#publisher"></use>
          </svg>
          <span class="nav-label">Publisher</span>
        </a>
      </li>

      <!-- <li class="panel" *ngIf="filterByCodeAndLevel('FORM_BUILDER',6)">
        <button
          data-toggle="collapse"
          data-parent="#sidemenu"
          *ngIf="filterByCodeAndLevel('FORM_BUILDER',6)"
          data-target="#formBuilder"
          class="btn menu-btn display-flex justify-space-between">
          <div class="display-flex">
            <svg class="dash-icons">
              <use href="assets/images/icon.svg#form-builder"></use>
            </svg>
            Form Builder
          </div>
          <svg class="dash-icons caret-icon">
            <use href="assets/images/icon.svg#caret"></use>
          </svg>
        </button>
        <ul class="collapse submenu-list" id="formBuilder">
         
          <li *ngIf="user_role == 1" [ngClass]="{ active: activeClick === 'package_details' }">
            <a
              [routerLink]="['/form-builder/package-details']"
              routerLinkActive="active"
              (click)="activeclick('package_details')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#form-builder"></use>
              </svg>
              <span class="nav-label">Package Details</span>
            </a>
          </li>
          <li *ngIf="user_role == 1" [ngClass]="{ active: activeClick === 'create_form_step_1' }">
            <a
              [routerLink]="['/form-builder/forms/create/step-1']"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="activeclick('create_form_step_1')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#form-builder"></use>
              </svg>
              <span class="nav-label">Create Form</span>
            </a>
          </li>
          <li *ngIf="user_role == 1" [ngClass]="{ active: activeClick === '/form-builder' }">
            <a
              routerLink="/form-builder/forms"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="activeclick('/form-builder')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#form-builder"></use>
              </svg>
              <span class="nav-label">Manage Forms</span>
            </a>
          </li>
          <li
            *ngIf="user_role == 1"
            [ngClass]="{ active: activeClick === 'form-builder/manage-institute' }">
            <a
              [routerLink]="['/form-builder/manage-institute']"
              routerLinkActive="active"
              (click)="activeclick('form-builder/manage-institute')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#form-builder"></use>
              </svg>
              <span class="nav-label">Manage Institute</span>
            </a>
          </li>
          <li
          *ngIf="user_role == 1"
          [ngClass]="{ active: activeClick === 'form-builder/manage-master-data' }">
          <a
            [routerLink]="['/form-builder/manage-master-data']"
            routerLinkActive="active"
            (click)="activeclick('form-builder/manage-master-data')">
            <svg class="dash-icons">
              <use href="assets/images/icon.svg#form-builder"></use>
            </svg>
            <span class="nav-label">Manage Master Data</span>
          </a>
        </li>
        </ul>
      </li>
       -->
      <li
        class="panel"
        *ngIf="filterByCodeAndLevel('LEADS',6) || filterByCodeAndLevel('UPLOAD_LEAD_CSV',6)">
        <button
          *ngIf="filterByCodeAndLevel('LEADS',6)"
          data-toggle="collapse"
          data-parent="#sidemenu"
          data-target="#leadsToggle"
          class="btn menu-btn display-flex justify-space-between">
          <div class="display-flex">
            <svg class="dash-icons">
              <use href="assets/images/icon.svg#leads"></use>
            </svg>
            Leads
          </div>
          <svg class="dash-icons caret-icon">
            <use href="assets/images/icon.svg#caret"></use>
          </svg>
        </button>
        <ul class="collapse submenu-list" id="leadsToggle">
          <li>
            <a
              [ngClass]="{ active: isActiveClickLead == true }"
              [routerLink]="['/leads']"
              [queryParams]="{ type: 'all' }"
              (click)="activeClickLead()">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#all-lead"></use>
              </svg>
              <span class="nav-label">All Leads</span>
            </a>
          </li>
          <li *ngIf="user_role == 1">
            <a
              [routerLink]="['/lead-activity']"
              routerLinkActive="active"
              (click)="activeclick('lead-activity')">
              <svg class="dash-icons" id="lead-activity" viewBox="0 0 25 25">
                <path
                  d="M19.175.725q-.275.9-.95 1.563t-1.562.937-1.838.125q-1.3-.25-2.475.375t-1.7 1.825q-.325-.175-.563-.038t-.263.45.15.613l4.475 7.575q-.2.15-.325.375-.15-.475-.35-.65-.125-.125-.325-.15t-.35.1-.125.35q0 .15.1.475l.075.3q.125.525-.275 1.075-.35.45-.95.775-1.1.6-2.35.7 1.275-1 1.663-2.563t-.313-3.025-2.15-2.163-3.025-.325-2.563 1.65-.95 2.9 1.089 2.851 2.65 1.525q-1.075 1-1.775 2.225-.8 1.45-.875 2.9-.025.15.063.238t.238.088h9.3q.125 0 .225-.088t.075-.238q-.1-1.85-1.4-3.65 2.15-.925 3.95-2.4.225-.175.4-.35l.3.5q.175.325.463.45t.513 0 .25-.438-.175-.613l-.55-.95q.125-.3.125-.625.025-.55-.313-1t-.839-.599q-.15-.05-.325-.05l-.9-1.525q.575-1.175 1.725-1.763t2.425-.363q.95.175 1.838-.1t1.563-.938.95-1.563z" />
              </svg>
              <span class="nav-label">Lead Activity</span>
            </a>
          </li>
          <!-- <li *ngIf="user_role != 6" [ngClass]="{ active: activeClick == 'favourites' }">
            <a
              [routerLink]="['/leads']"
              [queryParams]="{ type: 'fav' }"
              routerLinkActive="active"
              (click)="activeclick('favourites')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#favourite"></use>
              </svg>
              <span class="nav-label">Favourites</span>
            </a>
          </li> -->
          <li
            *ngIf="user_role == 1 || filterByCodeAndLevel('UPLOAD_LEAD_CSV',6) "
            [ngClass]="{ active: activeClick == 'upload-csv-lead' }">
            <a
              routerLink="/upload-csv-lead"
              class=" "
              routerLinkActive="active"
              (click)="activeclick('upload-csv-lead')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#upload-csv"></use>
              </svg>
              <span class="nav-label">Upload Lead CSV</span>
            </a>
          </li>
        </ul>
      </li>

      <!-- form builder  -->
      <li class="panel">
        <button
          data-toggle="collapse"
          data-parent="#sidemenu"
          data-target="#formBuilder"
          class="btn menu-btn display-flex justify-space-between">
          <div class="display-flex">
            <svg class="dash-icons">
              <use href="assets/images/icon.svg#form-builder"></use>
            </svg>
            Form Builder
          </div>
          <svg class="dash-icons caret-icon">
            <use href="assets/images/icon.svg#caret"></use>
          </svg>
        </button>
        <ul class="collapse submenu-list" id="formBuilder">
          <li *ngIf="user_role == 1" [ngClass]="{ active: activeClick === 'package_details' }">
            <a
              [routerLink]="['/form-builder/package-details']"
              routerLinkActive="active"
              (click)="activeclick('package_details')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#form-builder"></use>
              </svg>
              <span class="nav-label">Package Details</span>
            </a>
          </li>
          <li *ngIf="user_role == 1" [ngClass]="{ active: activeClick === 'create_form_step_1' }">
            <a
              [routerLink]="['/form-builder/forms/create/step-1']"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="activeclick('create_form_step_1')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#form-builder"></use>
              </svg>
              <span class="nav-label">Create Form</span>
            </a>
          </li>
          <li *ngIf="user_role == 1" [ngClass]="{ active: activeClick === '/form-builder' }">
            <a
              routerLink="/form-builder/forms"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="activeclick('/form-builder')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#form-builder"></use>
              </svg>
              <span class="nav-label">Manage Forms</span>
            </a>
          </li>
          <li
            *ngIf="user_role == 1"
            [ngClass]="{ active: activeClick === 'form-builder/manage-institute' }">
            <a
              [routerLink]="['/form-builder/manage-institute']"
              routerLinkActive="active"
              (click)="activeclick('form-builder/manage-institute')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#form-builder"></use>
              </svg>
              <span class="nav-label">Manage Institute</span>
            </a>
          </li>
          <li
            *ngIf="user_role == 1"
            [ngClass]="{ active: activeClick === 'form-builder/manage-master-data' }">
            <a
              [routerLink]="['/form-builder/manage-master-data']"
              routerLinkActive="active"
              (click)="activeclick('form-builder/manage-master-data')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#form-builder"></use>
              </svg>
              <span class="nav-label">Manage Master Data</span>
            </a>
          </li>
        </ul>
      </li>
      <!-- followup -->
      <!-- <li class="panel">
        <button
          data-toggle="collapse"
          data-parent="#sidemenu"
          data-target="#followupToggle"
          class="btn menu-btn display-flex justify-space-between">
          <div class="display-flex">
            <svg class="dash-icons">
              <use href="assets/images/icon.svg#followup"></use>
            </svg>
            My Followups
          </div>
          <svg class="dash-icons caret-icon">
            <use href="assets/images/icon.svg#caret"></use>
          </svg>
        </button>
        <ul class="collapse submenu-list" id="followupToggle">
          <li>
            <a
              [routerLink]="['/myfollowup']"
              [queryParams]="{ stage: 'today' }"
              routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#today"></use>
              </svg>
              <span class="nav-label">Today</span>
            </a>
          </li>
          <li>
            <a
              [routerLink]="['/myfollowup']"
              [queryParams]="{ stage: 'done' }"
              routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#done"></use>
              </svg>
              <span class="nav-label">Done</span>
            </a>
          </li>
          <li>
            <a
              [routerLink]="['/myfollowup']"
              [queryParams]="{ stage: 'missed' }"
              routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#missed"></use>
              </svg>
              <span class="nav-label">Missed</span>
            </a>
          </li>
          <li>
            <a
              [routerLink]="['/myfollowup']"
              [queryParams]="{ stage: 'upcoming' }"
              routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#upcoming"></use>
              </svg>
              <span class="nav-label">Upcoming</span>
            </a>
          </li>
        </ul>
      </li> -->
      <!-- notes -->
      <li
        [ngClass]="{ active: activeClick == 'note-history' }"
        *ngIf="filterByCodeAndLevel('Notes',6)">
        <a
          routerLink="/note-history"
          routerLinkActive="active"
          (click)="activeclick('note-history')">
          <svg class="dash-icons">
            <use href="assets/images/icon.svg#add-note"></use>
          </svg>
          <span class="nav-label">Notes</span>
        </a>
      </li>
      <!-- employee -->
      <li class="panel">
        <button
          data-toggle="collapse"
          data-parent="#sidemenu"
          data-target="#employeeToggle"
          class="btn menu-btn display-flex justify-space-between">
          <div class="display-flex">
            <svg class="dash-icons">
              <use href="assets/images/icon.svg#employee"></use>
            </svg>
            Employee Workforce
          </div>
          <svg class="dash-icons caret-icon">
            <use href="assets/images/icon.svg#caret"></use>
          </svg>
        </button>
        <ul class="collapse submenu-list" id="employeeToggle">
          <li>
            <a
              [routerLink]="['/employee/attendance']"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#attendance"></use>
              </svg>
              <span class="nav-label">Attendance</span>
            </a>
          </li>
          <li>
            <a [routerLink]="['/employee/attendance-report']" routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#order-report"></use>
              </svg>
              <span class="nav-label">Attendance Leave Report</span>
            </a>
          </li>
          <li>
            <a [routerLink]="['employee/leave']" routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#leave"></use>
              </svg>
              <span class="nav-label">Leave</span>
            </a>
          </li>
          <li *ngIf="user_role == 1">
            <a [routerLink]="['employee/configuration']" routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#custom"></use>
              </svg>
              <span class="nav-label">Configuration</span>
            </a>
          </li>
          <li>
            <a [routerLink]="['employee/finances']" routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#finance"></use>
              </svg>
              <span class="nav-label">Finances</span>
            </a>
          </li>
          <li>
            <a [routerLink]="['employee/authentication']" routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#auth"></use>
              </svg>
              <span class="nav-label">Auth Time Spent</span>
            </a>
          </li>
        </ul>
      </li>
      <!-- reports -->
      <li class="panel">
        <button
          data-toggle="collapse"
          data-parent="#sidemenu"
          data-target="#reportsToggle"
          class="btn menu-btn display-flex justify-space-between">
          <div class="display-flex">
            <svg class="dash-icons">
              <use href="assets/images/icon.svg#reports"></use>
            </svg>
            Reports & Activity
          </div>
          <svg class="dash-icons caret-icon">
            <use href="assets/images/icon.svg#caret"></use>
          </svg>
        </button>
        <ul class="collapse submenu-list" id="reportsToggle">
          <li>
            <a routerLink="/quick-report" routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#p-report" />
              </svg>
              User Productivity Report
            </a>
          </li>
          <li [ngClass]="{ active: activeClick == 'today-activity' }">
            <a
              routerLink="/today-activity"
              routerLinkActive="active"
              (click)="activeclick('today-activity')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#appointments"></use>
              </svg>
              <span class="nav-label">My Today Activity</span>
            </a>
          </li>
          <li>
            <a routerLink="/hourly-counsoler-report" routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#clock" />
              </svg>
              User Hourly Lead Activity
            </a>
          </li>
          <li>
            <a routerLink="/hourly-all-counsoler-report" routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#clock" />
              </svg>
              User Hourly Call Activity
            </a>
          </li>
          <li>
            <a routerLink="/caller-dashboard-report" routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#dashboard" />
              </svg>
              User Home Dashboard
            </a>
          </li>
          <li>
            <a routerLink="/lead-status-report" routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#frequency" />
              </svg>
              Lead Frequency Report
            </a>
          </li>
          <li>
            <a routerLink="/source-wise-report" routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#source" />
              </svg>
              User Source Wise
            </a>
          </li>
          <li>
            <a routerLink="/user-clg-wise-report" routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#icon-study" />
              </svg>
              User {{EntityName}} Wise
            </a>
          </li>
          <li>
            <a routerLink="/utm-wise-report" routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#url" />
              </svg>
              User UTM Wise
            </a>
          </li>
          <li>
            <a routerLink="/clg-wise-report" routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#lead-report" />
              </svg>
              {{EntityName}}wise Lead Report
            </a>
          </li>
          <li [ngClass]="{ active: activeClick == 'lead-calls-advance-history' }">
            <a
              routerLink="/lead-calls-advance-history"
              routerLinkActive="active"
              (click)="activeclick('lead-calls-advance-history')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#call-history"></use>
              </svg>
              <span class="nav-label">Advance Call History User</span>
            </a>
          </li>
          <li [ngClass]="{ active: activeClick == 'lead-calls-history' }">
            <a
              routerLink="/lead-calls-history"
              routerLinkActive="active"
              (click)="activeclick('lead-calls-history')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#call-history"></use>
              </svg>
              <span class="nav-label">Call History User</span>
            </a>
          </li>
          <li [ngClass]="{ active: activeClick == 'order-reports' }">
            <a
              routerLink="/order-reports"
              routerLinkActive="active"
              (click)="activeclick('order-reports')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#order-report"></use>
              </svg>
              <span class="nav-label">CAF Application Form Report</span>
            </a>
          </li>
          <li>
            <a routerLink="/lead-report-by-call-action" routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#stage" />
              </svg>
              User Lead Stage Report
            </a>
          </li>
          <!-- Commenting out this section as it is already covered in the Counsellor Productivity Report -->
          <!--<li
          
            [ngClass]="{ active: activeClick == 'all-counselor-report' }">
            <a
              routerLink="/all-counselor-report"
              routerLinkActive="active"
              (click)="activeclick('all-counselor-report')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#order-report"></use>
              </svg>
              <span class="nav-label">All Counselor Report</span>
            </a>
          </li>-->
          <!-- <li
            *ngIf="user_role == 1 || user_role == 4 || user_role == 6"
            [ngClass]="{ active: activeClick == 'lead-reports' }">
            <a
              routerLink="/lead-reports"
              routerLinkActive="active"
              (click)="activeclick('lead-reports')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#reports"></use>
              </svg>
              <span class="nav-label">My Lead Report</span>
            </a>
          </li> -->
        </ul>
      </li>
      <!-- automation -->
      <li
        *ngIf="user_role == 1 || filterByCodeAndLevel('Automtion_Workflow',6) || filterByCodeAndLevel('Rule_Engine',6) || filterByCodeAndLevel('Counselor_Allocation_Logic',6) || filterByCodeAndLevel('WorkfLow_Allocation_Logic',6)"
        class="panel">
        <button
          data-toggle="collapse"
          data-parent="#sidemenu"
          data-target="#automationToggle"
          class="btn menu-btn display-flex justify-space-between">
          <div class="display-flex">
            <svg class="dash-icons">
              <use href="assets/images/icon.svg#automation"></use>
            </svg>
            Automation
          </div>
          <svg class="dash-icons caret-icon">
            <use href="assets/images/icon.svg#caret"></use>
          </svg>
        </button>
        <ul class="collapse submenu-list" id="automationToggle">
          <li
            *ngIf="user_role == 1 || filterByCodeAndLevel('Automtion_Workflow',6)"
            [ngClass]="{ active: activeClick == 'automation' }">
            <a
              routerLink="/automation"
              (click)="activeclick('automation')"
              routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#work-flow"></use>
              </svg>
              <span class="nav-label">Workflow</span>
            </a>
          </li>
          <li
            *ngIf="user_role == 1 || filterByCodeAndLevel('Automtion_Workflow',6)"
            [ngClass]="{ active: activeClick == 'lead-generation' }">
            <a
              routerLink="/lead-generation"
              (click)="activeclick('lead-generation')"
              routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#lead-generation"></use>
              </svg>
              <span class="nav-label">Lead Generation</span>
            </a>
          </li>
          <li *ngIf="user_role == 1 || filterByCodeAndLevel('Rule_Engine',6)">
            <a routerLink="/rule-engine" routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#rule-eng"></use>
              </svg>
              <span class="nav-label">Rule Engine</span>
            </a>
          </li>
          <li *ngIf="user_role == 1 || filterByCodeAndLevel('Counselor_Allocation_Logic',6)">
            <a routerLink="/counsellor-allocation-logic" routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#allocation"></use>
              </svg>
              <span class="nav-label">User Allocation Logic</span>
            </a>
          </li>
          <li *ngIf="user_role == 1 || filterByCodeAndLevel('WorkfLow_Allocation_Logic',6)">
            <a routerLink="/workflow-allocation-logic" routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#allocation"></use>
              </svg>
              <span class="nav-label">Workflow Allocation Logic</span>
            </a>
          </li>
        </ul>
      </li>
      <!-- marketing -->
      <li
        class="panel"
        *ngIf="user_role == 1 || filterByCodeAndLevel('Ads_Management',6) || filterByCodeAndLevel('Traffic_Analytics',6)  || filterByCodeAndLevel('URL_Shortener',6) || filterByCodeAndLevel('Reconcile',6) || filterByCodeAndLevel('Email_Communication',6) || filterByCodeAndLevel('SMS_Communication',6) || filterByCodeAndLevel('WhatsApp_Communication',6) || filterByCodeAndLevel('OBD_Communication',6) || filterByCodeAndLevel('Manage_Widgets',6) || filterByCodeAndLevel('Push_Notification',6)">
        <button
          data-toggle="collapse"
          data-parent="#sidemenu"
          data-target="#marketingToggle"
          class="btn menu-btn display-flex justify-space-between">
          <div class="display-flex">
            <svg class="dash-icons">
              <use href="assets/images/icon.svg#marketing"></use>
            </svg>
            Marketing
          </div>
          <svg class="dash-icons caret-icon">
            <use href="assets/images/icon.svg#caret"></use>
          </svg>
        </button>
        <ul class="collapse submenu-list" id="marketingToggle">
          <li
            *ngIf="user_role == 1 || filterByCodeAndLevel('Ads_Management',6)"
            [ngClass]="{ active: activeClick == 'advertising-management' }">
            <a
              routerLink="/advertising-management"
              routerLinkActive="active"
              (click)="activeclick('advertising-management')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#ads-management"></use>
              </svg>
              <span class="nav-label">Ads Management</span>
            </a>
          </li>
          <!-- dialer temp -->
          <!-- <li [ngClass]="{ active: activeClick == 'advertising-management' }">
            <a routerLink="/dialer" routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#dialer"></use>
              </svg>
              <span class="nav-label">Dialer</span>
            </a>
          </li> -->
          <!-- dialer end -->
          <li *ngIf="user_role == 1 || filterByCodeAndLevel('Traffic_Analytics',6)">
            <a routerLink="/analytics" routerLinkActive="active" (click)="activeclick('analytics')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#traffic"></use>
              </svg>
              <span class="nav-label">Traffic Analytics</span>
            </a>
          </li>
          <li *ngIf="user_role == 1 || filterByCodeAndLevel('Marketing_Analytics',6)">
            <a
              routerLink="/marketing-analytics/google"
              routerLinkActive="active"
              (click)="activeclick('marketing-analytics')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#traffic"></use>
              </svg>
              <span class="nav-label">Marketing Analytics</span>
            </a>
          </li>
          <li *ngIf="user_role == 1 || filterByCodeAndLevel('GA_Campaign_URL_Builder',6)">
            <a [routerLink]="['/marketing/utm-builder']" routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#url"></use>
              </svg>
              <span class="nav-label">GA Campaign URL Builder</span>
            </a>
          </li>
          <li *ngIf="user_role == 1 || filterByCodeAndLevel('URL_Shortener',6)">
            <a [routerLink]="['/marketing/url-shortner']" routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#url-shortner"></use>
              </svg>
              <span class="nav-label">URL Shortener</span>
            </a>
          </li>
          <li *ngIf="user_role == 1 || filterByCodeAndLevel('Reconcile',6)">
            <a [routerLink]="['/marketing/reconcile']" routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#reconcile"></use>
              </svg>
              <span class="nav-label">Reconcile</span>
            </a>
          </li>
          <li
            [ngClass]="{ active: activeClick == 'email-campaign' }"
            *ngIf="user_role == 1 || filterByCodeAndLevel('Email_Communication',6)">
            <a
              routerLink="/email-campaign"
              routerLinkActive="active"
              (click)="activeclick('email-campaign')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#email-communication"></use>
              </svg>
              <span class="nav-label">Email Communication</span>
            </a>
          </li>
          <li
            [ngClass]="{ active: activeClick == 'sms-campaign' }"
            *ngIf="user_role == 1 || filterByCodeAndLevel('SMS_Communication',6)">
            <a
              routerLink="sms-campaign"
              routerLinkActive="active"
              (click)="activeclick('sms-campaign')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#sms-communication"></use>
              </svg>
              <span class="nav-label">SMS Communication</span>
            </a>
          </li>
          <li
            [ngClass]="{ active: activeClick == 'whatsapp-communication' }"
            *ngIf="user_role == 1 || filterByCodeAndLevel('WhatsApp_Communication',6)">
            <a
              routerLink="/chat"
              routerLinkActive="active"
              (click)="activeclick('whatsapp-communication')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#wp-communication"></use>
              </svg>
              <span class="nav-label">WhatsApp Communication</span>
            </a>
          </li>
          <li *ngIf="user_role == 1 || filterByCodeAndLevel('OBD_Communication',6)">
            <a routerLink="/obd" routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#communication"></use>
              </svg>
              <span class="nav-label">OBD Communication</span>
            </a>
          </li>
          <!-- <li *ngIf="user_role == 1">
            <a [routerLink]="['/marketing/remarketing']" routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#marketing"></use>
              </svg>
              <span class="nav-label">Remarketing</span>
            </a>
          </li> -->
          <li *ngIf="user_role == 1 || filterByCodeAndLevel('Manage_Widgets',6)">
            <a routerLink="/manage-widgets" routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#widget"></use>
              </svg>
              <span class="nav-label">Manage Widgets</span>
            </a>
          </li>
          <li *ngIf="user_role == 1 || filterByCodeAndLevel('Push_Notification',6)">
            <a routerLink="/push-notification" routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#notification"></use>
              </svg>
              <span class="nav-label">Push Notification</span>
            </a>
          </li>
        </ul>
      </li>
      <!-- application manager -->
      <li *ngIf="user_role == 1" class="panel">
        <button
          data-toggle="collapse"
          data-parent="#sidemenu"
          data-target="#applicationManagerToggle"
          class="btn menu-btn display-flex justify-space-between">
          <div class="display-flex">
            <svg class="dash-icons">
              <use href="assets/images/icon.svg#api-manager"></use>
            </svg>
            Application Manager
          </div>
          <svg class="dash-icons caret-icon">
            <use href="assets/images/icon.svg#caret"></use>
          </svg>
        </button>
        <ul class="collapse submenu-list" id="applicationManagerToggle">
          <li *ngIf="user_role == 1" [ngClass]="{ active: activeClick == 'applicant-manager' }">
            <a
              routerLink="/form-builder/applicant-manager"
              routerLinkActive="active"
              (click)="activeclick('applicant-manager')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#envelope"></use>
              </svg>
              <span class="nav-label">Application Manager</span>
            </a>
          </li>
          <!-- <li *ngIf="user_role == 1" [ngClass]="{ active: activeClick == '/form-builder' }">
            <a
              routerLink="/form-builder/forms"
              routerLinkActive="active"
              (click)="activeclick('form-builder')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#form-builder"></use>
              </svg>
              <span class="nav-label">Form Builder</span>
            </a>
          </li> -->
          <li *ngIf="user_role == 1" [ngClass]="{ active: activeClick == 'manage-documents' }">
            <a
              routerLink="/form-builder/manage-documents"
              routerLinkActive="active"
              (click)="activeclick('manage-documents')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#form"></use>
              </svg>
              <span class="nav-label">Manage Documents</span>
            </a>
          </li>
          <li *ngIf="user_role == 1" [ngClass]="{ active: activeClick == 'payment-manager' }">
            <a
              routerLink="/form-builder/payment-manager"
              routerLinkActive="active"
              (click)="activeclick('payment-manager')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#payment"></use>
              </svg>
              <span class="nav-label">Payment Manager</span>
            </a>
          </li>
          <li *ngIf="user_role == 1" [ngClass]="{ active: activeClick == 'purchase-summary' }">
            <a
              routerLink="/form-builder/purchase-summary"
              routerLinkActive="active"
              (click)="activeclick('purchase-summary')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#purchase"></use>
              </svg>
              <span class="nav-label">Purchase Summary</span>
            </a>
          </li>
          <li
            *ngIf="user_role == 1"
            [ngClass]="{ active: activeClick == '/form-builder/query-manager' }">
            <a
              routerLink="/form-builder/query-manager"
              routerLinkActive="active"
              (click)="activeclick('/form-builder/query-manager')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#query-manager"></use>
              </svg>
              <span class="nav-label">Query Manager</span>
            </a>
          </li>
          <li *ngIf="user_role == 1" [ngClass]="{ active: activeClick == 'quickclix-manager' }">
            <a
              routerLink="/form-builder/quickclix-manager"
              routerLinkActive="active"
              (click)="activeclick('quickclix-manager')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#manager"></use>
              </svg>
              <span class="nav-label">Quickclix Manager</span>
            </a>
          </li>
          <li *ngIf="user_role == 1" [ngClass]="{ active: activeClick == 'coupon-manager' }">
            <a
              routerLink="/form-builder/coupon-manager"
              routerLinkActive="active"
              (click)="activeclick('coupon-manager')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#coupon"></use>
              </svg>
              <span class="nav-label">Coupon Manager</span>
            </a>
          </li>
          <li *ngIf="user_role == 1" [ngClass]="{ active: activeClick == 'manage-faq' }">
            <a
              routerLink="/form-builder/manage-faq"
              routerLinkActive="active"
              (click)="activeclick('manage-faq')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#manage-faq"></use>
              </svg>
              <span class="nav-label">Manage FAQ</span>
            </a>
          </li>
          <li *ngIf="user_role == 1" [ngClass]="{ active: activeClick == 'post-application' }">
            <a
              routerLink="/post-application/forms"
              routerLinkActive="active"
              (click)="activeclick('post-application')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#post-application" />
              </svg>
              <span class="nav-label">Post Application</span>
            </a>
          </li>
          <li *ngIf="user_role == 1" [ngClass]="{ active: activeClick == 'manage-scorecard' }">
            <a
              routerLink="/post-application/manage-scorecard"
              routerLinkActive="active"
              (click)="activeclick('manage-scorecard')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#m-scorecard"></use>
              </svg>
              <span class="nav-label">Manage Scorecard</span>
            </a>
          </li>
          <li *ngIf="user_role == 1" [ngClass]="{ active: activeClick == 'interview' }">
            <a
              routerLink="/form-builder/interview"
              routerLinkActive="active"
              (click)="activeclick('interview')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#interview"></use>
              </svg>
              <span class="nav-label">Interview Scheduler</span>
            </a>
          </li>
          <li *ngIf="user_role == 1" [ngClass]="{ active: activeClick == 'document-verfication' }">
            <a
              routerLink="/form-builder/document-verfication"
              routerLinkActive="active"
              (click)="activeclick('document-verfication')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#verify"></use>
              </svg>
              <span class="nav-label">Document Verification</span>
            </a>
          </li>
          <li *ngIf="user_role == 1" [ngClass]="{ active: activeClick == 'configuration' }">
            <a
              routerLink="/form-builder/configuration"
              routerLinkActive="active"
              (click)="activeclick('configuration')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#configuration"></use>
              </svg>
              <span class="nav-label">Configuration</span>
            </a>
          </li>
          <li *ngIf="user_role == 1" [ngClass]="{ active: activeClick == 'import-report' }">
            <a
              routerLink="/form-builder/demo/import-report"
              routerLinkActive="active"
              (click)="activeclick('import-report')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#configuration"></use>
              </svg>
              <span class="nav-label">Import Report</span>
            </a>
          </li>
        </ul>
      </li>

      <!-- post application -->
      <li *ngIf="user_role == 1" class="panel">
        <button
          data-toggle="collapse"
          data-parent="#sidemenu"
          data-target="#postApplicationToggle"
          class="btn menu-btn display-flex justify-space-between">
          <div class="display-flex">
            <svg class="dash-icons">
              <use href="assets/images/icon.svg#api-manager"></use>
            </svg>
            Post Application
          </div>
          <svg class="dash-icons caret-icon">
            <use href="assets/images/icon.svg#caret"></use>
          </svg>
        </button>
        <ul class="collapse submenu-list" id="postApplicationToggle">
          <li *ngIf="user_role == 1" [ngClass]="{ active: activeClick == 'post-application' }">
            <a
              routerLink="/post-application/forms"
              routerLinkActive="active"
              (click)="activeclick('post-application')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#post-application" />
              </svg>
              <span class="nav-label">Post Application</span>
            </a>
          </li>
          <li *ngIf="user_role == 1" [ngClass]="{ active: activeClick == 'manage-scorecard' }">
            <a
              routerLink="/post-application/manage-scorecard"
              routerLinkActive="active"
              (click)="activeclick('manage-scorecard')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#m-scorecard"></use>
              </svg>
              <span class="nav-label">Manage Scorecard</span>
            </a>
          </li>
          <li *ngIf="user_role == 1" [ngClass]="{ active: activeClick == 'interview' }">
            <a
              routerLink="/form-builder/interview"
              routerLinkActive="active"
              (click)="activeclick('interview')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#interview"></use>
              </svg>
              <span class="nav-label">Interview Scheduler</span>
            </a>
          </li>
          <li *ngIf="user_role == 1" [ngClass]="{ active: activeClick == 'document-verfication' }">
            <a
              routerLink="/form-builder/document-verfication"
              routerLinkActive="active"
              (click)="activeclick('document-verfication')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#verify"></use>
              </svg>
              <span class="nav-label">Document Verification</span>
            </a>
          </li>
          <li *ngIf="user_role == 1" [ngClass]="{ active: activeClick == 'configuration' }">
            <a
              routerLink="/form-builder/configuration"
              routerLinkActive="active"
              (click)="activeclick('configuration')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#configuration"></use>
              </svg>
              <span class="nav-label">Configuration</span>
            </a>
          </li>
          <li *ngIf="user_role == 1" [ngClass]="{ active: activeClick == 'import-report' }">
            <a
              routerLink="/form-builder/demo/import-report"
              routerLinkActive="active"
              (click)="activeclick('import-report')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#configuration"></use>
              </svg>
              <span class="nav-label">Import Report</span>
            </a>
          </li>
        </ul>
      </li>
      <!-- setting -->
      <li
        *ngIf="user_role == 1 || filterByCodeAndLevel('USERS',6)  || filterByCodeAndLevel('L2_TO_L2_RE-ASSIGNMENT',6)|| filterByCodeAndLevel('PATNER_COLLEGES',6) || filterByCodeAndLevel('API_DOC',6) || filterByCodeAndLevel('Setting_Configuration',6) || filterByCodeAndLevel('USER_ROLES',6)"
        class="panel">
        <button
          data-toggle="collapse"
          data-parent="#sidemenu"
          data-target="#settingToggle"
          class="btn menu-btn display-flex justify-space-between">
          <div class="display-flex">
            <svg class="dash-icons">
              <use href="assets/images/icon.svg#settings"></use>
            </svg>
            Setting
          </div>
          <svg class="dash-icons caret-icon">
            <use href="assets/images/icon.svg#caret"></use>
          </svg>
        </button>
        <ul class="collapse submenu-list" id="settingToggle">
          <li *ngIf="user_role == 1" [ngClass]="{ active: activeClick == 'whtsp-templates' }">
            <a
              routerLink="/whtsp-templates"
              routerLinkActive="active"
              (click)="activeclick('whtsp-templates')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#global"></use>
              </svg>
              <span class="nav-label">WP Template Engine</span>
            </a>
          </li>
          <li *ngIf="user_role == 1 || filterByCodeAndLevel('USERS',6)">
            <a
              routerLink="/users"
              [routerLinkActiveOptions]="{ exact: true }"
              routerLinkActive="active">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#team"></use>
              </svg>
              <span class="nav-label">Users</span>
            </a>
          </li>
          <li
            *ngIf="user_role == 1 || filterByCodeAndLevel('USER_ROLES',6)"
            [ngClass]="{ active: activeClick == 'users-roles' }">
            <a
              routerLink="/users-roles"
              routerLinkActive="active"
              (click)="activeclick('users-roles')">
              <svg aria-label="" class="dash-icons">
                <use href="assets/images/icon.svg#user-role"></use>
              </svg>
              <span class="nav-label">User Roles</span>
            </a>
          </li>
          <li>
            <a routerLinkActive="active" routerLink="/timeline">
              <svg aria-label="" class="dash-icons" width="20px" height="20px">
                <use href="assets/images/icon.svg#clock_alpha"></use>
              </svg>
              <span class="nav-label">Timeline</span>
            </a>
          </li>
          <!-- <li>
            <a routerLinkActive="active" routerLink="/college-list">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#list"></use>
              </svg>
              <span class="nav-label">College</span>
            </a>
          </li> -->
          <li *ngIf="user_role != 1 " [ngClass]="{ active: activeClick == 'user-hierarchy' }">
            <a
              routerLink="/users/user-hierarchy"
              routerLinkActive="active"
              (click)="activeclick('user-hierarchy')">
              <svg aria-label="" class="dash-icons">
                <use href="assets/images/icon.svg#hierarchy"></use>
              </svg>
              <span class="nav-label">User Hierarchy</span>
            </a>
          </li>
          <li
            *ngIf="user_role == 1 || filterByCodeAndLevel('PATNER_COLLEGES',6)"
            [ngClass]="{ active: activeClick == 'college-list' }">
            <a
              *ngIf="is_hide"
              routerLink="/college-list"
              routerLinkActive="active"
              (click)="activeclick('college-list')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#icon-study"></use>
              </svg>
              <span class="nav-label">College</span>
            </a>
          </li>
          <li *ngIf="user_role == 1 || filterByCodeAndLevel('Setting_Configuration',6)">
            <a
              routerLink="/config"
              routerLinkActive="active"
              (click)="activeclick('custom-fields')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#custom"></use>
              </svg>
              <span class="nav-label">Configuration</span>
            </a>
          </li>
          <li
            [ngClass]="{ active: activeClick == 'docs' }"
            *ngIf="user_role == 1 || filterByCodeAndLevel('API_DOC',6)">
            <a routerLink="/docs" routerLinkActive="active" (click)="activeclick('docs')">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#docs"></use>
              </svg>
              <span class="nav-label">API Doc</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
    <div class="sidebar-footer text-center">
      <div class="mb-15">
        <div class="text-left help-support pos-rel mb-10">
          <a
            routerLink="/help-support"
            routerLinkActive="active"
            (click)="activeclick('help-support')"
            class="flex-center gap-10">
            <svg class="dash-icons">
              <use href="assets/images/icon.svg#help"></use>
            </svg>
            <span class="nav-label">Help & Support</span>
          </a>
          <span class="pos-abs">New</span>
        </div>
        <div class="flex-center justify-content-center store-icon gap-10">
          <a
            href="https://play.google.com/store/apps/details?id=com.automateazy.crm"
            target="_blank"
            title="Download Android App">
            <img src="/assets/images/play.svg" alt="Play store" />
          </a>
          <a
            href="https://apps.apple.com/in/app/automateazy-crm/id6450646312"
            target="_blank"
            title="Download Ios App">
            <img src="/assets/images/apple.svg" alt="Ios store" />
          </a>
        </div>
      </div>
      <div>
        <label>POWERED BY</label>
        <img src="assets/images/logo-powered.svg" alt="powered Logo" />
      </div>
    </div>
  </div>
</div>
