import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  constructor(private http: HttpClient) {}
  currentYear = new Date().getFullYear();
  activeClick = '';
  currentUser: any = {};
   userIp: ''
  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // this.getUserIp();
  }
  activeclick(data) {
    this.activeClick = data;
  }
  // getUserIp(){
  //    this.http.get('https://api.ipify.org').subscribe((res:any)=>{
  //      this.userIp = res;
  //      console.log(res);
  //    },(err:any)=>{
  //     console.log("");
  //    })
  // }
}
