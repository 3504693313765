<div class="modal fade static-modal" #studentModal tabindex="-1" id="studentModal" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="fs16 fw700">Incoming Prospect Details</h2>
        <a class="close-btn" data-dismiss="modal" aria-label="Close" (click)="close()">
          <svg class="dash-icons">
            <use href="assets/images/icon.svg#close-btn" />
          </svg>
        </a>
      </div>
      <div class="modal-body">
        <form class="row">
          <div class="form-group col-sm-6 col-xs-12">
            <h3>Lead ID <sup>*</sup></h3>
            <input
              type="text"
              name=""
              id=""
              class="form-control"
              [value]="leadDetail.id"
              placeholder=""
              readonly />
          </div>
          <div class="form-group col-sm-6 col-xs-12">
            <h3>Name</h3>
            <input
              type="text"
              name=""
              id=""
              [value]="leadDetail.name"
              class="form-control"
              placeholder="Enter your name" />
          </div>
          <div class="form-group col-sm-6 col-xs-12">
            <h3>Email<sup>*</sup></h3>
            <p>{{leadDetail.email}}</p>
          </div>
          <div class="form-group col-sm-6 col-xs-12">
            <h3>Phone Number<sup>*</sup></h3>
            <p>{{leadDetail.phone}}</p>
          </div>
          <div class="form-group col-sm-6 col-xs-12">
            <h3>Owner <sup>*</sup></h3>
            <select name="" id="" class="form-control">
              <option value="">Select</option>
            </select>
          </div>
          <div class="form-group col-sm-6 col-xs-12">
            <h3>Select {{EntityName}} <sup>*</sup></h3>
            <select name="" id="" class="form-control">
              <option value="">Select</option>
            </select>
          </div>
          <div class="form-group col-sm-6 col-xs-12">
            <h3>Note <sup>*</sup></h3>
            <textarea
              name=""
              id=""
              cols="5"
              rows="4"
              class="form-control"
              placeholder="Enter your note here"></textarea>
          </div>
          <div class="form-group form-btn col-sm-12 col-xs-12">
            <div class="flex-center justify-space-between">
              <img src="/assets/images/incoming.gif" alt="Incoming" />
              <button class="btn btn-blue">Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
