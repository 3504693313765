import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private http: HttpClient) {}
  getAllUsers(filters) {
    return this.http.get(`${environment.apiUrl}get_all_users`, { params: filters });
  }
  getAllRoles(filters) {
    return this.http.get(`${environment.apiUrl}get_all_user_roles`, { params: filters });
  }
  getActiveUserRoles() {
    return this.http.get(`${environment.apiUrl}get_active_user_roles`);
  }

  getActiveOrgLocations() {
    return this.http.get(`${environment.apiUrl}get_active_org_locations`);
  }

  deleteUserRole(roleId) {
    return this.http.delete(`${environment.apiUrl}roles/${roleId}`);
  }
  addUser(data) {
    return this.http.post(`${environment.apiUrl}users/register`, data);
  }
  getUserFromId(userId) {
    return this.http.get(`${environment.apiUrl}users/${userId}`);
  }
  updateUser(userId, data) {
    return this.http.put(`${environment.apiUrl}users/${userId}`, data);
  }
  removeUser(userId) {
    return this.http.delete(`${environment.apiUrl}users/${userId}`);
  }
  updatePassword(userId, data) {
    return this.http.post(`${environment.apiUrl}update-password/${userId}`, data);
  }
  userAuthLog(obj) {
    return this.http.post(`${environment.apiUrl}user/auth/log`, obj);
  }
  LogoutUser(id) {
    return this.http.delete(`${environment.apiUrl}remove/user/key/${id}`);
  }
  getAuthLog(filters) {
    return this.http.get(`${environment.apiUrl}get-auth-logs`, { params: filters });
  }
  getAuthLoginDuation(filters) {
    return this.http.get(`${environment.apiUrl}get-auth-logs-time-track`, { params: filters });
  }
  getRolePermissions(filters) {
    return this.http.get(`${environment.apiUrl}role-permissions`, { params: filters });
  }
  addRolePermissions(filters) {
    return this.http.post(`${environment.apiUrl}add-role-permissions`, filters);
  }
  RolePermissions(filters) {
    return this.http.post(`${environment.apiUrl}add-role-permissions`, filters);
  }
  getOneRolePermissions(filters) {
    return this.http.get(`${environment.apiUrl}one-role-permissions`, { params: filters });
  }
  editRolePermissions(filters) {
    return this.http.post(`${environment.apiUrl}edit-role-permissions`, filters);
  }
  addEmployeeDetails(obj, id) {
    return this.http.post(`${environment.apiUrl}add-employee-details/${id}`, obj);
  }
  editUserProfile(obj) {
    return this.http.post(`${environment.apiUrl}editUserProfile`, obj);
  }
  getAllDesignation() {
    return this.http.get(`${environment.apiUrl}getAllDesignation`);
  }
  getAllSkills() {
    return this.http.get(`${environment.apiUrl}getAllSkills`);
  }
  userActiveHistory(obj) {
    return this.http.post(`${environment.apiUrl}userActiveHistory`, obj);
  }
  getAuthLogActive(filters) {
    return this.http.get(`${environment.apiUrl}get-auth-logs-active`, { params: filters });
  }
  createUserAsWhatsappAgent(payload) {
    return this.http.post(`${environment.apiUrl}whatsapp/register/user`, payload);
  }
  resetUserAsWhatsappAgent(payload) {
    return this.http.post(`${environment.apiUrl}whatsapp/reset/user`, payload);
  }
  userBreak(body) {
    return this.http.post(`${environment.apiUrl}breakApi`, body);
  }
  userCsvExportActivity(body) {
    return this.http.post(`${environment.apiUrl}userExportActivityReport`, body);
  }
  userBreakActivity(body) {
    return this.http.post(`${environment.apiUrl}userBreakActivity`, body);
  }
  getCrmUserNotification(id, filters) {
    return this.http.post(`${environment.apiUrl}getCrmUserNotification/${id}`, filters);
  }
  readTrackerForNotification(id) {
    return this.http.post(`${environment.apiUrl}notificationReadTracker/${id}`, {});
  }
  readAllUserNotifications(id) {
    return this.http.post(`${environment.apiUrl}read-all-notification/${id}`, {});
  }
  uploadUser(payload) {
    return this.http.post(`${environment.apiUrl}uploadUser`, payload);
  }
  getUserHierarchy(data) {
    return this.http.get(`${environment.apiUrl}get_user_hierarchy`, { params: data });
  }
  getUserLoginDetails() {
    return this.http.get(`${environment.apiUrl}getUserLoginDetail`);
  }
  getPaySlip(data) {
    return this.http.get(`${environment.apiUrl}myfinances/get-payslip`, { params: data });
  }
  generatePaySlip(data) {
    return this.http.post(`${environment.apiUrl}/generate-payslip`, data);
  }
}
