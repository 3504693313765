export const environment = {
  production: true,
  // apiUrl: 'http://localhost:3090/api/v1/',
  apiUrl: 'https://apiautomateazy.techbellys.com/api/v1/',
  SOCKET_ENDPOINT: 'http://localhost:3090',
  IS_SHOW: true,
  facebookAppId: '222495365979721',
  googleClientId: '882153046170-8ak4t20495f357fsh7rtgrtdu53k502k.apps.googleusercontent.com',
  tinymceAPI: 'jib7ux8gselmihyesi1jfeurtffmx0uwz03o9rxdjhknihy5',
  IS_LOG: false,
  IS_SMS_BALANCE_SHOW: true,
  is_workflow_mgmt_show: true,
  is_lead_gen_mgmt_show: true,
  is_ads_mgmt_show: true,
  is_sms_mgmt_show: true,
  is_email_mgmt_show: true,
  is_email_mgmt_dash_show: true,
  CANDIDATE_DASHBOARD_URL: 'http://localhost:4200',
  CANDIDATE_DASHBOARD_DEPLOYED_URL: 'https://candidate-dev.techbellys.com',
  TIME_SPENT_IDLE_DURATION: 60000,
  TIME_SPENT_POLLING_TIME: 360000,
  WHATSAPP_CLIENT_BASE_URL: 'https://dev-cc-wa.aezy.in',
  CLIENT_USP_NAME: 'College',
  is_allow_prefilled_stage: false,
  entity_name: 'College',
  relatedEntityTerms: {
    student: 'Student',
    course: 'Course'
  },
};
