<p>
  <!-- <ng-container *ngIf="totalTime/1000 < 60">
       Working :  00: {{ totalTime/1000 }} Seconds
    </ng-container>
    <ng-container *ngIf="totalTime/1000 > 60 && totalTime/1000 < 600">
        Working :   {{ totalTime/1000/60 | number : '1.0-1' }} Minutes
    </ng-container>
    <ng-container *ngIf="totalTime/1000 > 600">
        Working :  {{ totalTime/10000/60/24 | number : '1.0-1' }} Hours
    </ng-container> -->

  Working : <label>{{ totalTime | date:'HH:mm:ss':'UTC'}}</label>
</p>
