import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/_services/user.service';
import { AuthenticationService } from './../../../_services/authentication.service';
import * as moment from 'moment';
import { SocketService } from 'src/app/_services/socket.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  loading = false;
  user_role: any;
  id = '';
  persistUser: any = {};
  unseenCount: any = 0;
  currentUser: any = {};
  button_status = '';
  break_reason = '';
  button = 0;

  notificationsArray = [];
  notificationsArrayUnreadCount = 0;
  checkUnreadNotification = [];

  filters = {
    page: 1,
    limit: 15,
  };
  showLoadMore = true;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private toastrService: ToastrService,
    private socketService: SocketService
  ) {}
  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.button = this.currentUser.user_status;
    this.persistUser = JSON.parse(localStorage.getItem('persist'));
    // this.socketService.joinUserRoom(this.currentUser.id);
    this.getCrmUserNotification();
    this.socketService.getNewMessage().subscribe((message: string) => {
      if (message && message != '') {
        if (this.currentUser['id'] == message['user_id']) {
          this.notificationsArray.unshift(message);
        }
      }
    });
  }
  // ngOnDestroy(): void {
  //   this.socketService.leaveUserRoom(this.currentUser.id);
  // }
  logout() {
    this.loading = true;
    this.currentUser.by_user = true;
    this.userService.userAuthLog(this.currentUser).subscribe((res: any) => {});
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
  getShortName(fullName) {
    return fullName
      .split(' ')
      .map(n => n[0])
      .join('');
  }
  toggleBtn() {
    document.body.style.overflowY = 'hidden';
  }

  breakButton(button) {
    if (button) {
      this.button_status = button;
    }
    if (button == 'Online') {
      let obj = {
        status: 0,
      };
      this.save(obj);
    }
  }
  breakReason(reason) {
    if (reason) {
      this.break_reason = reason.target.value;
    }
  }

  breakSubmit() {
    let obj = {
      status: this.button_status == 'Online' ? 0 : 1,
      reason: this.break_reason,
    };
    if (obj.status == 1 && this.break_reason == '') {
      this.toastrService.warning('Choose Break Reason');
      return;
    }
    this.save(obj);
  }

  save(obj) {
    this.loading = true;
    this.userService.userBreak(obj).subscribe(
      (res: any) => {
        if (res.code === 200) {
          document.getElementById('closeBreakPopup').click();
          this.updateLocalStorage(obj.status);
          this.loading = false;
          this.toastrService.success(res.message);
        }
      },
      err => {
        this.toastrService.error('Error!');
        this.loading = false;
      }
    );
  }

  updateLocalStorage(newValue) {
    let userObject = JSON.parse(localStorage.getItem('currentUser'));
    userObject.user_status = newValue;
    localStorage.setItem('currentUser', JSON.stringify(userObject));
    this.button = newValue;
  }

  getCrmUserNotification() {
    this.loading = true;
    this.userService.getCrmUserNotification(this.currentUser['id'], this.filters).subscribe(
      (res: any) => {
        if (res.code === 200) {
          // this.notificationsArray = res.data;
          this.notificationsArray = this.notificationsArray.concat(res.data);
          this.notificationsArrayUnreadCount = res.count;
          this.checkUnreadNotification = this.notificationsArray.filter(
            message => message.read == false
          );
          console.log(this.checkUnreadNotification);
          this.showLoadMore = res.data.length === this.filters.limit;
          console.log(this.showLoadMore, res.data.length, this.filters.limit);
        }
        this.loading = false;
      },
      error => {
        if (this.currentUser && this.currentUser.token) {
          const jwtToken = JSON.parse(atob(this.currentUser.token.split('.')[1]));
          const expires = new Date(jwtToken.exp * 1000);
          if (expires < new Date()) {
            this.toastrService.error('Session Expired!');
          }
        } else {
          this.toastrService.error('Some Error!');
        }
      }
    );
  }

  loadMore() {
    this.filters.page++;
    this.getCrmUserNotification();
  }

  calculateTimeDifference(notificationTime) {
    const notificationMoment = moment(notificationTime);
    let timeDifference = notificationMoment.fromNow();
    return timeDifference;
  }

  ReadTracker(item) {
    if (item._id && !item.read) {
      this.userService.readTrackerForNotification(item._id).subscribe(
        (res: any) => {
          item.read = true;
        },
        error => {
          this.toastrService.error('Error!');
        }
      );
    }
  }

  markAsreadAllUserNotifications() {
    this.loading = true;
    this.userService.readAllUserNotifications(this.currentUser['id']).subscribe(
      (res: any) => {
        if (res.code === 200) {
          this.toastrService.success(res.message);
          this.notificationsArray.forEach(notification => notification.read = true);
          // this.notificationsArray = [];
          this.notificationsArrayUnreadCount = 0;
          // setTimeout(() => location.reload(), 800);
        } else {
          this.toastrService.success(res.message);
        }
        this.loading = false;
      },
      error => {
        if (this.currentUser && this.currentUser.token) {
          const jwtToken = JSON.parse(atob(this.currentUser.token.split('.')[1]));
          const expires = new Date(jwtToken.exp * 1000);
          if (expires < new Date()) {
            this.toastrService.error('Session Expired!');
          }
        } else {
          this.toastrService.error('Some Error!');
        }
      }
    );
  }
  // Add this method to your component
  trackByNotification(index: number, item: any): string {
    return item._id; // Assuming `_id` is the unique identifier for notifications
  }
}
