import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AclService } from 'src/app/_services/acl.service';
import { WhatsAppService } from 'src/app/_services/whats-app.service';
import { environment } from 'src/environments/environment';
import { first } from 'rxjs/operators';
import { TenantService } from 'src/app/_services/tenant.service';
import { ToastrService } from 'ngx-toastr';
import { NavigationEnd, Router } from '@angular/router';
import { Renderer2 } from '@angular/core';
import { AclResolver } from 'src/app/_services/acl.resolver';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  hostname = '';
  // ACL_LEAD_CODE = 'LEADS';
  // SEETING_CODE = [
  //   'COLLEGE_RULE_ENGINE',
  //   'COURSE_RULE_ENGINE',
  //   'OBD',
  //   'USERS',
  //   'USER_ROLES',
  //   'L2_TO_L3_RE-ASSIGNMENT',
  //   'L2_TO_L3_ASSIGNMENT',
  //   'CUSTOM_FIELD_LEADS',
  //   'CUSTOM_SOURCE',
  //   'CITIES_LIST',
  //   'API_DOC',
  // ];
  // USER_CODE = ['USERS', 'L2_TO_L3_RE-ASSIGNMENT', 'L2_TO_L3_ASSIGNMENT'];
  // CONFIG_CODE = ['CUSTOM_FIELD_LEADS', 'CUSTOM_SOURCE', 'CITIES_LIST'];
  // USER_ROLE_CODE = 'USER_ROLES';
  // ACL_DOC_CODE = 'API_DOC';
  // ACCESS_CODE = [];
  // ACL_LEAD_data = [];
  // ACL_USER_ROLE_data = [];
  // ACL_config_data = [];
  // ACL_doc_data = [];
  user_role: any = '';
  user_name:any = '';
  id = '';
  unseenCount: any = 0;
  activeClick = '';
  isActiveClickLead = false;
  isActiveClickUser = false;
  tenantDetail: any = {};
  is_hide: boolean;
  is_workflow_mgmt_show: boolean;
  is_lead_gen_mgmt_show: boolean;
  is_ads_mgmt_show: boolean;
  is_sms_mgmt_show: boolean;
  is_email_mgmt_show: boolean;
  is_setting_menu = false;
  is_user_menu = false;
  is_user_role_menu = false;
  is_user_config_menu = false;
  is_doc_menu = false;
  aclData: any;
  EntityName: string;


  ACL = [];
  constructor(
    private whtspService: WhatsAppService,
    private location: Location,
    private aclService: AclService,
    private toasterService: ToastrService,
    private TenantService: TenantService,
    private router: Router,
    private renderer: Renderer2
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        // handle NavigationEnd event here
        if (
          (event.url.includes('leads') || event.url.includes('conversion')) &&
          event.url != '/leads?type=fav'
        ) {
          this.isActiveClickLead = true;
        } else {
          this.isActiveClickLead = false;
        }
        // console.log(event.url)
        // if (event.url.includes('/re-assignment') || event.url === '/users') {
        //   this.isActiveClickUser = true;
        // } else {
        //   console.log(false);
        //   this.isActiveClickUser = false;
        // }
      }
    });
  }
  currentUser: any = {};
  ngOnInit() {
    // console.log("AclData",AclData)
    this.hostname = window.location.protocol + '//' + window.location.host + '/public';
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.tenantDetail = JSON.parse(localStorage.getItem('persist'));
    this.getTenantProfile();
    this.is_hide = environment.IS_SHOW;
    this.is_workflow_mgmt_show = environment.is_workflow_mgmt_show;
    this.is_lead_gen_mgmt_show = environment.is_lead_gen_mgmt_show;
    this.is_ads_mgmt_show = environment.is_ads_mgmt_show;
    this.is_sms_mgmt_show = environment.is_sms_mgmt_show;
    this.is_email_mgmt_show = environment.is_email_mgmt_show;
    this.EntityName = environment.entity_name;

    console.log('is current user have wp_access: ', this.currentUser.wp_access);
    this.id = this.currentUser.id ? this.currentUser.id : '';
    this.user_role = this.currentUser ? this.currentUser.role : '';
    this.user_name = this.currentUser ? this.currentUser.role_name : '';

    if (
      this.location.path() == '/automation' ||
      this.location.path() == '/automation/my-automations' ||
      this.location.path() == '/templates?type=sms' ||
      this.location.path() == '/templates?type=email' ||
      this.location.path() == '/templates?type=whatsapp'
    ) {
      this.activeClick = 'automation';
    }
    this.getAclData();
  }
  ngOnChanges() {
    this.activeClickLead();
  }

  getUnseenMsgs() {
    this.whtspService.getUnseenMsgs(this.id).subscribe(
      (res: any) => {
        if (res.status == 'success') {
          this.unseenCount = res.data_obj.length;
          document.getElementById('unseenCount').textContent = this.unseenCount;
          document.getElementById('unseenCount1').textContent = this.unseenCount;
        } else {
          console.log('some error in fetching unseen entites');
        }
      },
      err => {
        console.log('error in api');
      }
    );
  }
  getTenantProfile() {
    try {
      this.TenantService.getTenantProfileById(this.tenantDetail.id).subscribe((res: any) => {
        if (res && res.code === 200) {
          this.tenantDetail.logo_image = res.data[0].logo_image;
          localStorage.setItem('persist', JSON.stringify(this.tenantDetail));
        } else if (res.code === 404) {
          this.toasterService.warning(res.message);
        } else {
          this.toasterService.warning(res.message);
        }
      });
    } catch (Error) {
      console.log('Error', Error);
    }
  }
  activeclick(data) {
    this.activeClick = data;
  }
  activeClickLead() {
    this.isActiveClickLead = true;
  }
  activeUserclick() {
    this.isActiveClickUser = true;
  }
  closeNavBar() {
    let nav = document.getElementById('navbar-overlay');
    nav.classList.remove('in');
    document.body.style.overflowY = 'auto';
  }

  transformedData = [];

  getAclData() {
    //console.log("Acl Data", this.aclService.aclValue);
    this.aclService
      .getAcl()
      .pipe(first())
      .subscribe(
        (data:any) => {
          console.log(data);
         this.setAclData();
        },
        error => {
          console.log('error', error);
        }
      );
  }
  setAclData() {
    console.log("setAclData",this.aclService.aclValue);

    if (this.aclService.aclValue && this.aclService.aclValue.length > 0) {
      this.transformedData = this.aclService.aclValue.reduce((acc, item) => {
        if (!acc[item.code] || item.level > acc[item.code]) {
          acc[item.code] = item.level;
        }
        return acc;
      }, {});
    }
  }

  filterByCodeAndLevel(code, level) {
    const keys = Object.keys(this.transformedData);

    if (keys.includes(code)) {
      let value = this.transformedData[code];
      if (Array.isArray(value)) {
      } else {
        value = JSON.parse(value);
      }
      if (value.includes(level)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  handleNavLinkClick(event: Event) {
    let targetElement = event.target as HTMLElement;
    while (targetElement && targetElement !== event.currentTarget) {
      if (targetElement.tagName === 'A') {
        const sidebar = document.querySelector('.sidebar-nav');
        sidebar.classList.remove('in');
        this.renderer.setStyle(document.body, 'overflow-y', 'auto');
        return;
      }
      targetElement = targetElement.parentElement;
    }
  }
}

// this.aclService.aclValue.map(data => {
//   this.ACCESS_CODE.push(data.code);
//   if (data.code === AclData.ACL_LEAD_CODE) {
//     console.log("data",data.code)
//     this.ACL['ACL_LEAD_data']
//     this.ACL_LEAD_data = data.level;
//   }
//   const level = data && data.level ? JSON.parse(JSON.stringify(data.level)) : [];
//   if (this.USER_CODE.indexOf(data.code) > -1) {
//     if (level.indexOf(6) > -1) {
//       this.is_user_menu = true;
//       this.is_setting_menu = true;
//     }
//   }
//   if (data.code === this.USER_ROLE_CODE) {
//     if (level.indexOf(6) > -1) {
//       this.is_user_role_menu = true;
//       this.is_setting_menu = true;
//     }
//   }
//   if (this.CONFIG_CODE.indexOf(data.code) > -1) {
//     if (level.indexOf(6) > -1) {
//       this.is_user_config_menu = true;
//       this.is_setting_menu = true;
//     }
//   }
//   if (data.code === this.ACL_DOC_CODE) {
//     if (level.indexOf(6) > -1) {
//       this.is_doc_menu = true;
//       this.is_setting_menu = true;
//     }
//   }
// });
