import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LeadSearchService } from 'src/app/_services/lead-search.service';
import { RuleEngineService } from 'src/app/_services/rule-engine';
import { leadData } from '../../../jsonData/lead';
import * as moment from 'moment';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { SettingService } from 'src/app/_services/setting.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dialer-cofig',
  templateUrl: './dialer-cofig.component.html',
  styleUrls: ['./dialer-cofig.component.css'],
})
export class DialerCofigComponent implements OnInit {
  loading = true;
  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ],
  };
  invalidDates: moment.Moment[] = [
    moment().add(2, 'days'),
    moment().add(3, 'days'),
    moment().add(5, 'days'),
  ];
  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some(d => d.isSame(m, 'day'));
  };
  objIfInnerLoop = {
    valueAry: [],
    operator: '',
    columnName: '',
    value: '',
    belongsTo: '',
    type: '',
  };
  cloneObj = { ...this.objIfInnerLoop };
  objIfLoop = {
    condition: 'And',
    rules: [this.cloneObj],
  };
  ifLoopAry: any = [Object.assign({}, this.objIfLoop)];
  globleCondtion = 'And';
  count = 0;
  dataList = null;
  cityAry = [];
  cityObj: any = {};
  countryAry = [];
  utmSourceAry = [];
  utmMediumAry = [];
  callActionAry = [];
  EntityName = environment.entity_name;
  relatedEntityCourse = environment.relatedEntityTerms.course;
  statusAry = [
    {
      key: 'Verified',
      value: 1,
    },
    {
      key: 'Not Verified',
      value: 0,
    },
  ];
  genderAry = [
    {
      key: 'Female',
      value: 'female',
    },
    {
      key: 'Male',
      value: 'male',
    },
    {
      key: 'Other',
      value: 'other',
    },
  ];
  callactionStatusAry = [
    {
      key: 'Yes',
      value: 1,
    },
    {
      key: 'No',
      value: 0,
    },
  ];
  selectedItemsComm = [];
  emailTempAry = [];
  smsTempAry = [];
  whatsappTempAry = [];
  userAry = [];
  collegeAry = [];
  leadTypeAry = [];
  lastEducationAry = [];
  categoryDataAry = [];
  streamDataAry = [];
  courseDataAry = [];
  yearOfAdmissionDataAry = [];
  prmarySourceDataAry = [];
  tertiarySourceDataAry = [];
  secondarySourceDataAry = [];
  utmCampaignDataAry = [];
  lastCallactionSubStageAry = [];
  stateAry = [];
  priorityAry = [
    {
      key: 'Low',
      value: 0,
    },
    {
      key: 'Medium',
      value: 1,
    },
    {
      key: 'High',
      value: 2,
    },
  ];
  listSettings: any;
  userList: any = [];
  user: any;
  list: any = [];
  priority: Number = 0;
  ruleName: any;
  triggerType: any;
  zone: any;
  empty = '';
  AllLeadStagesArr = [];
  streamAllData = [];
  ruleForm: FormGroup;
  editParam = null;
  dataEditList;
  text = 'Create';
  sampleList = [];
  constructor(
    private ruleEngineService: RuleEngineService,
    private toasterService: ToastrService,
    private router: Router,
    private leadSearchService: LeadSearchService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private settingService: SettingService
  ) {}

  ngOnInit(): void {
    this.makeFormBuild();
    this.stateAry = this.makeKeyValue(leadData.stateArray, true, 'value', 'id');
    this.getConstantList();
    this.listSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'key',
      allowSearchFilter: true,
      searchPlaceholderText: 'Search',
    };
    this.getLeadStages();
    this.getStream();
    this.getDialerLeadList();
  }
  edit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.editParam = params['id'];
      if (this.editParam != null) {
        this.getRuleList();
        this.text = 'Update';
      }
    });
  }
  makeFormBuild() {
    this.ruleForm = new FormGroup({
      name: new FormControl(''),
      zone: new FormControl(''),
      triggerType: new FormControl(''),
      // user:  new FormArray([]),
      rule: new FormArray([this.initSection()]),
    });
  }

  initDeepSection() {
    return new FormGroup({
      operator: new FormControl(''),
      columnName: new FormControl(''),
      displayName: new FormControl(''),
      value: new FormControl(''),
      valueAry: new FormControl(''),
    });
  }

  initSection() {
    return new FormGroup({
      rules: new FormArray([this.initDeepSection()]),
    });
  }

  getDialerLeadList() {
    this.loading = true;
    this.settingService.getDialerLeadList().subscribe((res: any) => {
      if (res.code === 200) {
        console.log('Res', res);
        this.loading = false;
        this.sampleList = res.data;
      }
    });
  }

  getConstantList() {
    this.loading = true;
    this.ruleEngineService.getListConstant({}).subscribe(
      (res: any) => {
        if (res && res.code == 200) {
          this.dataList = res.data;
          this.utmMediumAry = res.data.utmMedium
            ? this.makeKeyValue(res.data.utmMedium, true, 'utm_medium', null)
            : [];
          this.utmSourceAry = res.data.utmSource
            ? this.makeKeyValue(res.data.utmSource, true, 'utm_source', null)
            : [];
          this.cityAry = res.data.city ? this.makeKeyValue(res.data.city, true, 'city', null) : [];
          //this.callActionAry = res.data.callAction ? this.makeKeyValue(res.data.callAction, true, 'last_callaction_btn_message', null) : []
          this.countryAry = res.data.country
            ? this.makeKeyValue(res.data.country, false, 'name', 'id')
            : [];
          this.emailTempAry = res.data.email
            ? this.makeKeyValue(res.data.email, false, 'name', 'id')
            : [];
          this.smsTempAry = res.data.sms
            ? this.makeKeyValue(res.data.sms, false, 'name', 'id')
            : [];
          this.whatsappTempAry = res.data.whatsapp
            ? this.makeKeyValue(res.data.whatsapp, false, 'name', 'id')
            : [];
          this.userAry = res.data.userData
            ? this.makeKeyValue(res.data.userData, false, 'name', 'id')
            : [];
          this.collegeAry = res.data.collegeData
            ? this.makeKeyValue(res.data.collegeData, false, 'clg_name', 'id')
            : [];
          this.leadTypeAry = res.data.leadTypeData
            ? this.makeKeyValue(res.data.leadTypeData, true, 'lead_type', null)
            : [];
          this.lastEducationAry = res.data.lastEducationData
            ? this.makeKeyValue(res.data.lastEducationData, true, 'last_education', null)
            : [];

          this.categoryDataAry = res.data.categoryData
            ? this.makeKeyValue(res.data.categoryData, true, 'category', null)
            : [];
          //this.streamDataAry = res.data.streamData ? this.makeKeyValue(res.data.streamData, true, 'stream', null) : []
          this.courseDataAry = res.data.courseData
            ? this.makeKeyValue(res.data.courseData, true, 'course', null)
            : [];
          this.yearOfAdmissionDataAry = res.data.yearOfAdmissionData
            ? this.makeKeyValue(res.data.yearOfAdmissionData, true, 'year_of_admission', null)
            : [];
          this.prmarySourceDataAry = res.data.prmarySourceData
            ? this.makeKeyValue(res.data.prmarySourceData, true, 'prmary_source', null)
            : [];
          this.tertiarySourceDataAry = res.data.tertiarySourceData
            ? this.makeKeyValue(res.data.tertiarySourceData, true, 'tertiary_source', null)
            : [];
          this.secondarySourceDataAry = res.data.secondarySourceData
            ? this.makeKeyValue(res.data.secondarySourceData, true, 'secondary_source', null)
            : [];
          this.utmCampaignDataAry = res.data.utmCampaignData
            ? this.makeKeyValue(res.data.utmCampaignData, true, 'utm_campaign', null)
            : [];
          //this.lastCallactionSubStageAry = res.data.lastCallactionSubStageData ? this.makeKeyValue(res.data.lastCallactionSubStageData, true, 'last_callaction_sub_stage', null) : []
          // this.stateAry = res.data.stateData ? this.makeKeyValue(res.data.stateData, true, 'state', null) : []
          // this.sampleList = res.data.userAllData
          //   ? this.makeKeyValue(res.data.userAllData, false, 'name', 'id')
          //   : [];
          this.edit();
        } else {
          this.dataList = [];
        }
        this.loading = false;
      },
      err => {
        console.log('Error ===>', err);
        this.loading = false;
      }
    );
  }

  getLeadStages() {
    this.leadSearchService.getAllLeadStages().subscribe((res: any) => {
      if (res.code && res.code === 200) {
        this.AllLeadStagesArr = res.data;
        this.callActionAry = this.makeKeyValue(res.data, false, 'stage_name', 'stage_name');
      }
    });
  }

  getStream() {
    this.leadSearchService.getStream().subscribe((res: any) => {
      if (res.code && res.code === 200) {
        this.streamAllData = res.data;
        this.streamDataAry = this.makeKeyValue(res.data, false, 'cat_name', 'cat_name');
      }
    });
  }

  addData() {
    console.log(this.priority);
    const checkVal: any = this.validation();
    if (checkVal.check) {
      this.settingService
        .createDialerConfig({
          name: this.ruleName,
          // zone: this.zone,
          triggerType: this.triggerType,
          rule: this.ifLoopAry,
          globleCondtion: this.globleCondtion,
          listIds: this.list,
          priority: this.priority,
          id: this.editParam,
        })
        .subscribe(
          (res: any) => {
            if (res && res.code == 200) {
              this.toasterService.success('Created successfully');
              // this.router.navigate(['/counsellor-allocation-logic']);
            } else {
              this.dataList = [];
              this.toasterService.error(res.data);
            }
            this.loading = false;
          },
          err => {
            console.log('Error ====>', err);
            this.loading = false;
          }
        );
    } else {
      this.toasterService.error(checkVal.msg);
    }
  }

  makeKeyValue(data, isSame = false, key, value) {
    const send = [];
    for (let [, element] of data.entries()) {
      let obj = {};
      if (isSame) {
        obj['key'] = element[key];
        obj['value'] = element[key];
      } else {
        obj['key'] = element[key];
        obj['value'] = element[value];
      }
      if (element.sender_id) {
        const sender = JSON.parse(element.sender_id);
        if (sender && sender.length > 0) {
          obj['sender_id'] = sender[0];
        }
      }
      send.push(obj);
    }
    return send;
  }

  onOptionsSelected(index, innnerIndex, event, key, check = false) {
    if (key == 'value') {
      if (!Array.isArray(event) && !check) {
        return;
      }
    }
    this.ifLoopAry[index]['rules'][innnerIndex]['changePre'] =
      this.ifLoopAry[index]['rules'][innnerIndex]['columnName'];
    if (event && event.target && event.target.value) {
      this.ifLoopAry[index]['rules'][innnerIndex][key] = event.target.value;
    } else {
      event = event.map(a => a.value);
      if (event && event.startDate) {
        this.ifLoopAry[index]['rules'][innnerIndex][key] = [
          moment(event.startDate._d).local().format('YYYY-MM-DD'),
          moment(event.endDate._d).local().format('YYYY-MM-DD'),
        ];
      } else {
        this.ifLoopAry[index]['rules'][innnerIndex][key] = event;
      }
    }

    if (key === 'columnName') {
      if (event.target.value === 'city') {
        this.getPartnerCollegesCities(index, innnerIndex);
      } else if (event.target.value === 'last_callaction_sub_stage') {
        this.getSubStage(index, innnerIndex);
      } else if (event.target.value === 'course') {
        this.getCourse(index, innnerIndex);
      } else {
        this.ifLoopAry[index]['rules'][innnerIndex]['valueAry'] =
          this[this.objectKey(event.target.value)];
      }

      this.ifLoopAry[index]['rules'][innnerIndex]['clear'] = true;
      this.ifLoopAry[index]['rules'][innnerIndex]['value'] = '';
      this.ruleForm['controls']['rule']['controls'][index]['controls']['rules']['controls'][
        innnerIndex
      ].patchValue({
        value: null,
        displayName: null,
      });
    } else if (key === 'value') {
      this.displayValue(index, innnerIndex, event, key);
      this.ifLoopAry[index]['rules'][innnerIndex]['clear'] = false;
    }
  }

  displayValue(index, innnerIndex, event, key) {
    let value = event && event.target && event.target.value ? event.target.value : event;
    let name = this.ifLoopAry[index]['rules'][innnerIndex]['columnName']
      ? this.ifLoopAry[index]['rules'][innnerIndex]['columnName']
      : null;
    if (name && name === 'clg_id') {
      let st = '';
      if (Array.isArray(value)) {
        for (let [, e] of value.entries()) {
          for (let [, element] of this.dataList.collegeData.entries()) {
            if (e === element.id) {
              st += ' ' + element.clg_name;
            }
          }
        }
        this.ifLoopAry[index]['rules'][innnerIndex]['displayName'] = st;
      }
    } else if (name && name === 'priority') {
      console.log('prioritypriority', value, name);
      let st = '';
      if (Array.isArray(value)) {
        for (let [, e] of value.entries()) {
          for (let [, element] of this.priorityAry.entries()) {
            if (e === element.value) {
              st += ' ' + element.key;
            }
          }
        }
        this.ifLoopAry[index]['rules'][innnerIndex]['displayName'] = st;
      }
    }
  }

  getSubStage(index, innerIndex) {
    let leadValue = null;
    for (let [, element] of this.ifLoopAry[index]['rules'].entries()) {
      if (element.columnName === 'last_callaction_btn_message') {
        leadValue = element.value;
      }
    }
    if (leadValue !== null) {
      let leadSub = [];
      for (let [, e] of this.AllLeadStagesArr.entries()) {
        for (let [, f] of leadValue.entries()) {
          if (e.stage_name === f) {
            leadSub = [...leadSub, ...e.sub_stages];
          }
        }
      }
      leadSub = this.makeKeyValue(leadSub, false, 'name', 'name');
      this.ifLoopAry[index]['rules'][innerIndex]['valueAry'] = leadSub;
    } else {
      this.ifLoopAry[index]['rules'][innerIndex]['clear'] = true;
      this.ifLoopAry[index]['rules'][innerIndex]['value'] = '';
      this.ifLoopAry[index]['rules'][innerIndex]['valueAry'] = [];
    }
  }

  objectKey(data) {
    let key = null;
    if (data === 'utm_medium') {
      key = 'utmMediumAry';
    } else if (data === 'utm_source') {
      key = 'utmSourceAry';
    } else if (data === 'city') {
      key = 'cityAry';
    } else if (data === 'country') {
      key = 'countryAry';
    } else if (data === 'is_verified') {
      key = 'statusAry';
    } else if (data === 'user_id') {
      key = 'userAry';
    } else if (data === 'last_callaction_btn_message') {
      key = 'callActionAry';
    } else if (data === 'clg_id') {
      key = 'collegeAry';
    } else if (data === 'lead_type') {
      key = 'leadTypeAry';
    } else if (data === 'last_education') {
      key = 'lastEducationAry';
    } else if (data === 'category') {
      key = 'categoryDataAry';
    } else if (data === 'stream') {
      key = 'streamDataAry';
    } else if (data === 'course') {
      key = 'courseDataAry';
    } else if (data === 'year_of_admission') {
      key = 'yearOfAdmissionDataAry';
    } else if (data === 'prmary_source') {
      key = 'prmarySourceDataAry';
    } else if (data === 'tertiary_source') {
      key = 'tertiarySourceDataAry';
    } else if (data === 'secondary_source') {
      key = 'secondarySourceDataAry';
    } else if (data === 'utm_campaign') {
      key = 'utmCampaignDataAry';
    } else if (data === 'last_callaction_sub_stage') {
      key = 'lastCallactionSubStageAry';
    } else if (data === 'gender') {
      key = 'genderAry';
    } else if (data === 'last_callaction_followup_status') {
      key = 'callactionStatusAry';
    } else if (data === 'priority') {
      key = 'priorityAry';
    } else if (data === 'state') {
      key = 'stateAry';
    }
    return key;
  }

  addIfLoopFn() {
    const newObj = JSON.parse(JSON.stringify(this.objIfLoop));
    this.ifLoopAry.push(Object.assign({}, newObj));
    const control = <FormArray>this.ruleForm.get('rule');
    control.push(this.initSection());
  }
  addIfInnerLoopFn(i, innerIndex) {
    const newObj = JSON.parse(JSON.stringify(this.objIfInnerLoop));
    this.ifLoopAry[i]['rules'] = [...this.ifLoopAry[i]['rules'], ...[newObj]];
    const control = <FormArray>this.ruleForm.get('rule')['controls'][i].get('rules');
    control.push(this.initDeepSection());
  }

  eventGlobleCheckBox(event) {
    if (event.target.checked) {
      this.globleCondtion = 'Or';
    } else {
      this.globleCondtion = 'And';
    }
  }

  eventInnerCheckBox(event, i) {
    let conditionBox = 'And';
    if (event.target.checked) {
      conditionBox = 'Or';
    }
    this.ifLoopAry[i].condition = conditionBox;
  }

  selectedUser(data) {
    console.log('data', data);
    // this.ruleForm.patchValue({
    //   user: this.user
    // });
  }

  onOptionsSelectedChange(event, type) {
    if (type === 'zone') {
      this.zone = event.target.value;
    } else {
      this.triggerType = event.target.value;
    }
  }
  validation() {
    const obj = {
      check: true,
      msg: 'All field are required',
    };
    this.ruleName = this.ruleForm.controls['name'].value
      ? this.ruleForm.controls['name'].value
      : '';
    this.zone = this.ruleForm.controls['zone'].value ? this.ruleForm.controls['zone'].value : '';
    this.triggerType = this.ruleForm.controls['triggerType'].value
      ? this.ruleForm.controls['triggerType'].value
      : '';
    console.log('validation', this.ifLoopAry);
    if (this.ruleName === '' || !this.ruleName) {
      obj.check = false;
      obj.msg = 'Name required';
      return obj;
    } else if (this.triggerType === '' || !this.triggerType) {
      obj.check = false;
      obj.msg = 'Trigger Type required';
      return obj;
    } else if (this.list === '' || !this.list || this.list.length === 0) {
      obj.check = false;
      obj.msg = 'Lead list required';
      return obj;
    }
    for (const [i, e] of this.ifLoopAry.entries()) {
      for (const [index, element] of e.rules.entries()) {
        if (element.columnName === '') {
          obj.check = false;
          obj.msg = 'Trigger Name required';
          break;
        }
        if (element.value === '') {
          obj.check = false;
          obj.msg = 'Trigger value required';
          break;
        }
        if (element.operator === '') {
          obj.check = false;
          obj.msg = 'Trigger operator required';
          break;
        }
      }
    }
    return obj;
  }

  removeIfInnerLoopFn(i, innerIndex) {
    let aryLength = 0;
    if (this.ifLoopAry && this.ifLoopAry.length > 0) {
      for (const [i, e] of this.ifLoopAry.entries()) {
        for (const [index, element] of e.rules.entries()) {
          aryLength = aryLength + 1;
        }
      }
    }
    if (aryLength > 1) {
      this.ifLoopAry[i].rules.splice(innerIndex, 1);
      const control = <FormArray>this.ruleForm.get('rule')['controls'][i].get('rules');
      control.removeAt(innerIndex);
      if (this.ifLoopAry[i].rules.length === 0) {
        this.ifLoopAry.splice(i, 1);
        const control = <FormArray>this.ruleForm.get('rule');
        control.removeAt(i);
      }
    } else {
      this.toasterService.error("You can't delete last record");
    }
  }

  isSelected(terms: any): boolean {
    const matchAry = [
      'prmary_source',
      'utm_medium',
      'utm_source',
      'utm_campaign',
      'state',
      'city',
      'last_callaction_btn_message',
      'course',
      'clg_id',
      'last_education',
      'stream',
      'year_of_admission',
      'secondary_source',
      'tertiary_source',
      'last_callaction_sub_stage',
    ];
    console.log(matchAry.includes(terms.columnName), terms.columnName);
    if (matchAry.includes(terms.columnName) && terms.operator == 'in') {
      return true;
    } else {
      return false;
    }
  }

  getPartnerCollegesCities(index, innerIndex) {
    let stateValue = null;
    for (let [, element] of this.ifLoopAry[index]['rules'].entries()) {
      if (element.columnName === 'state') {
        stateValue = element.value;
      }
    }
    if (stateValue != null) {
      var states = stateValue
        .map(function (elem) {
          return elem;
        })
        .join(',');
      this.leadSearchService.getCitiesFromStateNameMul(states).subscribe(
        (cities: any) => {
          if (cities.code == 200) {
            console.log('cities.data', cities.data);
            let aryCity = [];
            for (let [, element] of cities.data.entries()) {
              aryCity.push({
                key: element.name,
                value: element.name,
              });
            }
            console.log('aryCity.aryCity', aryCity);
            this.ifLoopAry[index]['rules'][innerIndex]['valueAry'] = aryCity;
          }
        },
        error => {
          console.log(error);
        }
      );
    } else {
      this.ifLoopAry[index]['rules'][innerIndex]['clear'] = true;
      this.ifLoopAry[index]['rules'][innerIndex]['value'] = '';
      this.ifLoopAry[index]['rules'][innerIndex]['valueAry'] = [];
    }
  }

  getCourse(index, innerIndex) {
    let streamValue = null;
    for (let [, element] of this.ifLoopAry[index]['rules'].entries()) {
      if (element.columnName === 'stream') {
        streamValue = element.value;
      }
    }
    if (streamValue != null) {
      var stream = streamValue
        .map(function (elem) {
          return elem;
        })
        .join(',');
      this.loading = true;
      this.leadSearchService.getStreamCourse(stream).subscribe(
        (streams: any) => {
          if (streams.code == 200) {
            let courseAry = [];
            for (let [, element] of streams.data.entries()) {
              courseAry.push({
                key: element.course_name,
                value: element.course_name,
              });
            }
            this.ifLoopAry[index]['rules'][innerIndex]['valueAry'] = courseAry;
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log('Error ===>', error);
        }
      );
    } else {
      this.ifLoopAry[index]['rules'][innerIndex]['clear'] = true;
      this.ifLoopAry[index]['rules'][innerIndex]['value'] = '';
      this.ifLoopAry[index]['rules'][innerIndex]['valueAry'] = [];
    }
  }

  onSubmitRule() {
    console.log(this.ruleForm.value);
  }

  getRuleList() {
    this.loading = true;
    this.ruleEngineService
      .getListRuleCounsellor({
        _id: this.editParam,
      })
      .subscribe(
        (res: any) => {
          if (res && res.code == 200) {
            this.dataList = res.data.data && res.data.data.length > 0 ? res.data.data[0] : [];
            this.createPatchGroup(this.dataList);
            for (let [i, e] of this.dataList.rule.entries()) {
              for (let [inner, eIneer] of e.rules.entries()) {
                if (eIneer.columnName === 'clg_id') {
                  this.dataList.rule[i]['rules'][inner]['valueAry'] = this.collegeAry;
                }
              }
            }
            console.log('this.dataList.rule', this.dataList.rule, this.collegeAry);
            this.ruleForm.patchValue({
              name: this.dataList.name,
              zone: this.dataList.zone,
              triggerType: this.dataList.triggerType,
              rule: this.dataList.rule,
            });
            const userAPatch = [];
            for (let [i, e] of this.dataList.userNames.entries()) {
              userAPatch.push({
                key: e,
                value: this.dataList.userIds[i],
              });
            }
            this.user = userAPatch;
            console.log('userAPatch', userAPatch);
            // this.ruleForm.patchValue({
            //   user:userAPatch
            // })

            this.ifLoopAry = this.dataList.rule;
            this.globleCondtion = this.dataList.globleCondtion;
          } else {
            this.dataEditList = [];
          }
          this.loading = false;
        },
        err => {
          console.log('Error ====>', err);
          this.loading = false;
        }
      );
  }

  createPatchGroup(data) {
    this.ruleForm = new FormGroup({
      name: new FormControl(''),
      zone: new FormControl(''),
      triggerType: new FormControl(''),
      // user: new FormArray([]),
      rule: new FormArray(this.initSectionPatch(data)),
    });
  }

  initDeepSectionPatch(data) {
    const formArray = [];

    for (let one of data.rules) {
      formArray.push(
        this.formBuilder.group({
          operator: new FormControl(''),
          columnName: new FormControl(''),
          displayName: new FormControl(''),
          value: new FormControl(''),
          valueAry: new FormControl(''),
        })
      );
    }
    return formArray;
  }

  initSectionPatch(data) {
    const formArray = [];

    for (let one of data.rule) {
      formArray.push(
        this.formBuilder.group({
          rules: new FormArray(this.initDeepSectionPatch(one)),
        })
      );
    }
    return formArray;
  }

  onItemSelect(item: any) {
    console.log('Item selected:', item);
    console.log('Selected list:', this.list);
  }
}
