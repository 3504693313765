<!-- external-website.component.html -->
<div id="page-wrapper" class="gray-bg dashbard-1">
  <div class="content-main">
    <div class="blank chat-frame">
      <ul class="flex-center user-tab mb-15">
        <li class="active">
          <a class="btn" (click) = "tabChange('tab1')" data-toggle="tab" data-target="#tab1">Live Chat</a>
        </li>
        <li>
          <a class="btn" (click) = "tabChange('tab2')" data-toggle="tab" data-target="#tab2">History</a>
        </li>
        <li>
          <a class="btn" (click) = "tabChange('tab3')" data-toggle="tab" data-target="#tab3">BIC-History</a>
        </li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane fade active in" id="tab1">
          <div>
            <iframe
              [src]="externalWebsiteURL"
              width="1080"
              height="700"
              allowfullscreen="true"></iframe>
          </div>
        </div>
        <div class="tab-pane fade" id="tab2">
          <iframe
            [src]="externalWebsiteURL"
            width="1080"
            height="700"
            allowfullscreen="true"></iframe>
        </div>
        <div class="tab-pane fade" id="tab3">
          <iframe
            [src]="externalWebsiteURL"
            width="1080"
            height="700"
            allowfullscreen="true"></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
