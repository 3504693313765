import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
  AfterViewInit,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LeadSearchService } from './../../_services/lead-search.service';
import { leadData } from '../../jsonData/lead';
import { SourcesApiDataSharingService } from 'src/app/shared/shared/sources-api-data-sharing.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-lead-search',
  templateUrl: './lead-search.component.html',
  styleUrls: ['./lead-search.component.css'],
})
export class LeadSearchComponent implements OnInit, AfterViewInit {
  @ViewChild('studentModal', { static: false }) studentModal: ElementRef;
  leadForm: FormGroup;
  submitted = false;
  loading = false;
  submitBtn = 'Save';
  countries = [];
  states = [];
  usersList = [];
  years = new Date().getFullYear();
  userSettings = {};
  user_role: any = '';
  partnerColleges: any = [];
  user_id = 0;
  sourceArray: any = [];
  streams: any = [];
  statesArray = [];
  customFields = [];
  EntityName = environment.entity_name;

  @Output() onCreate: EventEmitter<any> = new EventEmitter();
  @Input() colleges: any[];
  @Input() users: any[];

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private leadSearchService: LeadSearchService,
    private sourcesApiDataSharingService: SourcesApiDataSharingService
  ) {}

  ngOnInit() {
    var currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.user_id = currentUser ? currentUser.id : '';
    this.user_role = currentUser['role'];
    this.streams = leadData.streamArray;
    this.states = leadData.stateArray;
    this.leadForm = this.formBuilder.group(
      {
        name: [
          '',
          [Validators.required, Validators.maxLength(30), Validators.pattern("^[a-zA-Z -']+")],
        ],
        email: [
          '',
          [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
        ],
        college: [null, Validators.required],
        country: new FormControl({ value: '101', disabled: true }, Validators.required),
        phone: [
          '',
          [Validators.required, Validators.minLength(10), Validators.pattern('^[6-9][0-9]{9}$')],
        ],
        alt_phone: ['', [Validators.min(10), Validators.pattern('^[6-9][0-9]{9}$')]],
        state: [null, Validators.required],
        stream: [null, Validators.required],
        year: ['', ''],
        source: [null, Validators.required],
        users: [null, null],
        refer_user_name: [''],
        refer_user_email: [''],
        refer_user_phone: [''],
        lead_verification_mail: [0],
      },
      { validator: this.mustMatch('phone', 'alt_phone') }
    );

    this.sourcesApiDataSharingService.sources$.subscribe(sources => {
      if (sources && sources.code == 200) {
        this.sourceArray = sources.data;
        this.customFields = sources.customFields;
        this.addLeadFormValidation();
      }
    });
  }

  ngAfterViewInit() {}

  resetForm() {
    this.leadForm.reset();
    this.submitted = false;
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }
      if (control.value == matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
        return;
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.colleges && changes.colleges.currentValue) {
      this.partnerColleges = changes.colleges.currentValue;
    }
    if (changes.users && changes.users.currentValue) {
      this.usersList = changes.users.currentValue;
    }
    if (changes.sources && changes.sources.currentValue) {
      this.sourceArray = changes.sources.currentValue;
    }
  }

  get f() {
    return this.leadForm.controls;
  }

  addLeadFormValidation() {
    for (let i = 0; i < this.customFields.length; i++) {
      const field = this.customFields[i];

      if (field.text !== null) {
        field.option = field.text.split(',');
      }

      let requireVar: any = [];
      if (field.is_required === 1) {
        requireVar.push(Validators.required);
      }

      if (field.field_type == 'email') {
        requireVar.push(Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'));
      }

      if (field.field_type == 'url') {
        const urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
        requireVar.push(Validators.pattern(urlPattern));
      }
      // Add control to the form group
      this.leadForm.addControl(
        field.key_name,
        new FormControl('', requireVar.length ? Validators.compose(requireVar) : null)
      );
    }
  }

  onSubmitLeads() {
    this.submitted = true;
    if (this.leadForm.invalid) {
      return;
    }
    this.loading = true;
    this.submitBtn = 'Saving...';

    let leadObj = {
      name: this.f.name.value,
      email: this.f.email.value,
      clg_id: this.f.college.value,
      country: this.f.country.value ? this.f.country.value : 101,
      phone: this.f.phone.value,
      alt_phone: this.f.alt_phone.value ? this.f.alt_phone.value : '',
      state: this.f.state.value,
      stream: this.f.stream.value,
      year_of_admission: this.f.year.value,
      source: this.f.source.value,
      lead_type: 'CLL',
      status: 0,
      user_id: this.f.users.value ? this.f.users.value : this.user_id,
      refer_user_name: this.f.refer_user_name.value,
      refer_user_email: this.f.refer_user_email.value,
      refer_user_phone: this.f.refer_user_phone.value,
      is_counsellor_alloc_check: 0,
      untouch_lead: 1, // By default untouch lead should be true which means lead will be untouched when re-enquired
    };
    leadObj['other'] = [];
    for (let i = 0; i < this.customFields.length; i++) {
      const keyName = this.customFields[i].key_name;
      const keyValue = this.f[this.customFields[i].key_name].value;
      leadObj['other'].push({
        keyValue,
        keyName,
      });
      leadObj[keyName] = keyValue;
    }
    this.leadSearchService.leadGenerate(leadObj).subscribe(
      (lead: any) => {
        document.getElementById('closeAddNewLeadModal').click();
        if (lead && lead.code == 200) {
          this.submitBtn = 'Save';
          this.onCreate.emit(lead);
          this.resetForm();
          this.toastr.success(lead.message, 'Success!');
          this.loading = false;
        } else {
          this.loading = false;
          this.submitBtn = 'Save';
          this.resetForm();
          this.toastr.error(lead.message, 'Error!');
        }
      },
      error => {
        this.loading = false;
        this.submitBtn = 'Save';
        this.toastr.error(error, 'Error!');
      }
    );
  }

  leadVerification(event) {
    if (event.target) {
      this.leadForm.patchValue({ lead_verification_mail: event.target.checked == false ? 0 : 1 });
    }
  }

  eventCheck(event, key) {
    let keyValue = this.f[key].value || '';
    if (event.target.checked) {
      keyValue += event.target.value;
    } else {
      keyValue = keyValue.replace(event.target.value, '');
    }
    this.f[key].setValue(keyValue);
  }
}
