import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { ProductOrderDetailComponent } from './components/product-order-detail/product-order-detail.component';
import { TenantProfileComponent } from './components/tenant/tenant-profile/tenant-profile.component';
import { WhatsappChatComponent } from './components/settings/whatsapp-chat/whatsapp-chat.component';
import { AuthGuard } from './_helpers/auth.guard';
import { MenuGuard } from './_helpers/menu.guard';
import { RoleGuard } from './_helpers/role.guard';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { DialerComponent } from './components/dialer/dialer.component';
import { DialerCofigComponent } from './components/dialer/dialer-cofig/dialer-cofig.component';
import { ListComponent } from './components/dialer/list/list.component';
import { AclResolver } from './_services/acl.resolver';
import { TimelineComponent } from './components/settings/timeline/timeline.component';
import { ImportReportComponent } from './components/form-builder/import-report/import-report.component';

// console.log(RoleGuard);

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    resolve: { acl: AclResolver },
  },
  { path: 'login', component: LoginComponent },
  { path: 'dialer', component: DialerComponent, resolve: { acl: AclResolver } },
  { path: 'dialer-config', component: DialerCofigComponent, resolve: { acl: AclResolver } },
  { path: 'dialer-list', component: ListComponent, resolve: { acl: AclResolver } },

  {
    path: 'dashboard',
    loadChildren: () =>
      import('./components/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard],
    resolve: { acl: AclResolver },
  },
  {
    path: 'lead-activity',
    data: { componentCode: 'LEADS', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () =>
      import('./components/lead-activity-log/lead-activity-log.module').then(
        m => m.LeadActivityLogModule
      ),
  },
  {
    path: 'leads',
    data: { componentCode: 'LEADS', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () => import('./components/home/home.module').then(m => m.HomeScreenModule),
  },
  // users
  {
    path: 'users',
    data: { componentCode: 'USERS', requiredPermission: 6 },
    canActivate: [AuthGuard],
    resolve: { acl: AclResolver },
    loadChildren: () => import('./components/users/users.module').then(m => m.UserModule),
  },
  {
    path: 'users-roles',
    data: { componentCode: 'USER_ROLES', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () => import('./components/roles/roles.module').then(m => m.RolesModule),
  },

  {
    path: 'lp/:id/conversion',
    data: { componentCode: 'LEADS', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () =>
      import('./components/lead-conversions/lead-conversions.module').then(
        m => m.LeadConversionsModule
      ),
  },
  {
    path: 'lp/:id/profiles',
    data: { componentCode: 'LEADS', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () =>
      import('./components/lead-profiles/lead-profiles.module').then(m => m.LeadProfilesModule),
  },

  { path: 'form-builder/demo/import-report', component: ImportReportComponent },
  { path: 'leads/offline-uploads/import-report', component: ImportReportComponent },
  {
    path: 'lp/:id/college-recommendation',
    data: { componentCode: 'LEADS', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () =>
      import('./components/college-recommendation/college-recommendation.module').then(
        m => m.CollegeRecommendationModule
      ),
  },
  {
    path: 'lp/:id/call-history',
    data: { componentCode: 'LEADS', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () =>
      import('./components/lead-call-history/lead-call-history.module').then(
        m => m.LeadCallHistoryModule
      ),
  },
  {
    path: 'lp/:id/virtual-meeting-history',
    data: { componentCode: 'LEADS', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () =>
      import('./components/lead-virtual-meeting-history/lead-virtual-meeting-history.module').then(
        m => m.LeadVirtualMeetingHistoryModule
      ),
  },
  {
    path: 'lp/:id/college-shortlisted',
    data: { componentCode: 'LEADS', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () =>
      import('./components/short-listed-colleges/short-listed-colleges.module').then(
        m => m.ShortListedCollegesModule
      ),
  },
  {
    path: 'lp/:id/college-not-interested',
    data: { componentCode: 'LEADS', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () =>
      import('./components/college-not-interested/college-not-interested.module').then(
        m => m.CollegeNotInterestedModule
      ),
  },

  {
    path: 'upload-csv-lead',
    data: { componentCode: 'UPLOAD_LEAD_CSV', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () => import('./components/lead-csv/lead-csv.module').then(m => m.LeadCsvModule),
  },
  {
    path: 'lead-reports',
    loadChildren: () =>
      import('./components/lead-reports/lead-report.module').then(m => m.LeadReportsModule), // menu hid by rajat not use by users
    canActivate: [AuthGuard],
    resolve: { acl: AclResolver },
  },
  {
    path: 'order-reports',
    data: { componentCode: 'CAF_Application_Form_Report', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () =>
      import('./components/order-report/order-report.module').then(
        m => m.OrderReportsRoutingModule
      ),
  },
  {
    path: 'product-order-detail/:id',
    data: { componentCode: 'CAF_Application_Form_Report', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    component: ProductOrderDetailComponent,
  },
  {
    path: 'quick-report',
    data: { componentCode: 'Counsellor_Productivity_Report', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () =>
      import('./components/dashboard/caller-quick-report/caller-quick-report.module').then(
        m => m.CallerquickReportRoutingModule
      ),
  },
  {
    path: 'whtsp-templates',
    loadChildren: () =>
      import('./components/settings/whtsp-template/whtsp-template.module').then(
        m => m.whatsappTempRoutingModule
      ),
    data: { roles: [1] },
    canActivate: [AuthGuard],
    resolve: { acl: AclResolver },
  },
  {
    path: 'lead-calls-history',
    data: { componentCode: 'Call_History_Counsellor', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () =>
      import('./components/lead-call-history-admin/lead-call-history-admin.module').then(
        m => m.LeadCallHistoryAdminsRoutingModule
      ),
  },
  {
    path: 'lead-calls-advance-history',
    data: { componentCode: 'Advance_Call_History_Counsellor', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () =>
      import('./components/click-to-call-admin/call-click-to-admin.module').then(
        m => m.ClickTocallAdminModule
      ),
  },
  {
    path: 'clg-wise-report',
    data: { componentCode: 'Collegewise_Lead_Report', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () =>
      import('./components/dashboard/college-wise-report/college-wise-report.module').then(
        m => m.CollegeswiseReportRoutingModule
      ),
  },
  {
    path: 'user-clg-wise-report',
    data: { componentCode: 'Counsellor_Source_Wise', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () =>
      import(
        './components/dashboard/user-college-lead-report/user-college-lead-report.module'
      ).then(m => m.UserCollegeLeadReportModule),
  },
  {
    path: 'source-wise-report',
    data: { componentCode: 'Counsellor_Source_Wise', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () =>
      import('./components/dashboard/source-lead-reports/source-lead-reports.module').then(
        m => m.SourceLeadReportsModule
      ),
  },

  {
    path: 'today-activity',
    data: { componentCode: 'My_Today_Activity', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () =>
      import('./components/today-activity/today-activity.module').then(
        m => m.TodatActivityRoutingModule
      ),
  },

  {
    path: 'utm-wise-report',
    data: { componentCode: 'Counsellor_UTM_Wise', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () =>
      import('./components/counsoller-utm-reports/counsoller-utm-report.module').then(
        m => m.UtmRoutingModule
      ),
  },
  {
    path: 'hourly-counsoler-report',
    loadChildren: () =>
      import('./components/dashboard/hourly-counsoler-report/hourly-counsoler-report.module').then(
        m => m.HourlyCounsolerReportModule
      ),
    canActivate: [AuthGuard, RoleGuard],
    resolve: { acl: AclResolver },
    data: { componentCode: 'Counsellor_Hourly_Activity', requiredPermission: 6 },
  },
  {
    path: 'hourly-all-counsoler-report',
    data: { componentCode: 'All_Counsellor_Hourly_Activity', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () =>
      import(
        './components/dashboard/hourly-all-counsoler-report/hourly-all-counsoler-report.module'
      ).then(m => m.HourlyAllCounsolerReportModule),
  },

  {
    path: 'analytics',
    data: { componentCode: 'Traffic_Analytics', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () =>
      import('./components/dashboard/analytics/analytics.module').then(m => m.AnalyticsModule),
  },
  {
    path: 'lead-status-report',
    data: { componentCode: 'Lead_Frequency_Report', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () =>
      import('./components/dashboard/report-lead-status/report-lead-status.module').then(
        m => m.ReportLeadStatusModule
      ),
  },
  {
    path: 'note-history',
    loadChildren: () =>
      import('./components/lead-note/note-history/note-history.module').then(
        m => m.NoteHistoryModule
      ),
    canActivate: [AuthGuard],
    resolve: { acl: AclResolver },
  },
  {
    path: 'automation',
    resolve: { acl: AclResolver },
    loadChildren: () =>
      import('./core/marketing-automation/marketing-automation.module').then(
        m => m.MarketingAutomationModule
      ),
  },

  {
    path: 'caller-dashboard-report',
    data: { componentCode: 'Counsellor_Home_Dashboard', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    loadChildren: () =>
      import('./components/dashboard/caller-dashboard-report/caller-dashboard-report.module').then(
        m => m.CallerDashboardReportModule
      ),
    resolve: { acl: AclResolver },
  },
  {
    path: 'college-list',
    data: { componentCode: 'PATNER_COLLEGES', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () =>
      import('./components/college-list/college-list.module').then(m => m.CollegeListModule),
  },
  {
    path: 'advertising-management',
    loadChildren: () =>
      import('./components/ads-management/ads-management.module').then(m => m.AdsManagementModule),
    canActivate: [AuthGuard],
    resolve: { acl: AclResolver },
  },
  {
    path: 'ads-management-facebook',
    loadChildren: () =>
      import('./components/ads-management-facebook/ads-management-facebook.module').then(
        m => m.AdsManagementFacebookModule
      ),
    canActivate: [AuthGuard],
    resolve: { acl: AclResolver },
  },
  {
    path: 'ads-management-google',
    data: { componentCode: 'Ads_Management', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () =>
      import('./components/ads-management-google/ads-management-google.module').then(
        m => m.AdsManagementGoogleModule
      ),
  },
  {
    path: 'tenant-profile/:id',
    component: TenantProfileComponent,
    canActivate: [AuthGuard],
    resolve: { acl: AclResolver },
  },
  {
    path: 'obd',
    data: { componentCode: 'OBD_Communication', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () => import('./components/settings/obd/obd.module').then(m => m.obdModule),
  },
  {
    path: 'lead-generation',
    loadChildren: () =>
      import('./components/lead-generation/lead-generation-common.module').then(
        m => m.LeadGenerationCommonModule
      ),
    canActivate: [AuthGuard],
    resolve: { acl: AclResolver },
  },
  {
    path: 'lead-report-by-call-action',
    loadChildren: () =>
      import(
        './components/dashboard/lead-report-by-call-action/lead-report-by-call-action.module'
      ).then(m => m.LeadReportByCallActionModule),
    data: { roles: [1], canActivate: [AuthGuard] },
    resolve: { acl: AclResolver },
  },
  {
    path: 'email-campaign',
    data: { componentCode: 'Email_Communication', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () =>
      import('./components/settings/sms-email-campaign/email-campaign/email-campaign.module').then(
        m => m.EmailCampaignModule
      ),
  },
  {
    path: 'sms-campaign',
    data: { componentCode: 'SMS_Communication', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () =>
      import('./components/settings/sms-email-campaign/sms-campaign/sms-campaign.module').then(
        m => m.SmsCampaignModule
      ),
  },
  {
    path: 'publishers',
    loadChildren: () =>
      import('./components/publisher/publisher/publisher.module').then(m => m.PublisherModule),
    canActivate: [AuthGuard],
    resolve: { acl: AclResolver },
  },
  {
    path: 'docs',
    data: { componentCode: 'API_DOC', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () => import('./components/doc/doc.module').then(m => m.DocModule),
  },
  {
    path: 'config',
    data: { componentCode: 'Setting_Configuration', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () =>
      import('./components/settings/configuration/configuration.module').then(
        m => m.ConfigurationModule
      ),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./components/my-account/my-account.module').then(m => m.MyAccountModule),
    canActivate: [AuthGuard],
    resolve: { acl: AclResolver },
  },
  {
    path: 'manage-widgets',
    loadChildren: () =>
      import('./components/marketing/widgets/widgets.module').then(m => m.WidgetsModule),
    // data: { roles: [1] },
    canActivate: [AuthGuard],
    resolve: { acl: AclResolver },
  },
  {
    path: 'rule-engine',
    loadChildren: () =>
      import('./components/settings/rule-engine/rule-engine.module').then(m => m.RuleEngineModule),
    canActivate: [AuthGuard],
    resolve: { acl: AclResolver },
  },
  {
    path: 'counsellor-allocation-logic',
    loadChildren: () =>
      import('./components/settings/counsellor/counsellor.module').then(m => m.CounsellorModule),
    canActivate: [AuthGuard],
    resolve: { acl: AclResolver },
  },
  {
    path: 'form-builder',
    loadChildren: () =>
      import('./components/form-builder/form-builder.module').then(m => m.FormBuilderModule),
    data: { roles: [1] },
    canActivate: [AuthGuard],
    resolve: { acl: AclResolver },
  },
  {
    path: 'post-application',
    loadChildren: () =>
      import('./components/post-applications/post-applications.module').then(
        m => m.PostApplicationsModule
      ),
    data: { roles: [1] },
    canActivate: [AuthGuard],
    resolve: { acl: AclResolver },
  },
  {
    path: 'push-notification',
    loadChildren: () =>
      import('./components/marketing/push-notify/push-notify.module').then(m => m.PushNotifyModule),
    canActivate: [AuthGuard],
    resolve: { acl: AclResolver },
  },
  {
    path: 'employee',
    loadChildren: () => import('./components/employee/employee.module').then(m => m.EmployeeModule),
    canActivate: [AuthGuard],
    resolve: { acl: AclResolver },
  },
  {
    path: 'marketing',
    loadChildren: () =>
      import('./components/marketing/marketing.module').then(m => m.MarketingModule),
    canActivate: [AuthGuard],
    resolve: { acl: AclResolver },
  },
  {
    path: 'workflow-allocation-logic',
    data: { componentCode: 'WorkfLow_Allocation_Logic', requiredPermission: 6 },
    canActivate: [AuthGuard, RoleGuard],
    canLoad: [RoleGuard],
    resolve: { acl: AclResolver },
    loadChildren: () =>
      import('./components/settings/workflow/workflow.module').then(m => m.WorkflowModule),
  },
  { path: 'chat', component: WhatsappChatComponent },
  { path: 'access-denied', component: AccessDeniedComponent },
  { path: 'timeline', component: TimelineComponent },
  {
    path: 'help-support',
    loadChildren: () =>
      import('./components/help-and-support/help-and-support.module').then(
        m => m.HelpAndSupportModule
      ),
    canActivate: [AuthGuard],
    resolve: { acl: AclResolver },
  },
  {
    path: 'marketing-analytics',
    loadChildren: () =>
      import('./components/marketing/marketing-analytics/marketing-analytics.module').then(
        m => m.MarketingAnalyticsModule
      ),
    canActivate: [AuthGuard],
    resolve: { acl: AclResolver },
  },

  // otherwise redirect to home
  { path: '**', redirectTo: '/dashboard' },
];

export const appRoutingModule = RouterModule.forRoot(routes);
