export const leadData = {
  sourceArray: [
    {
      value: 'WEB',
    },
    {
      value: 'APP',
    },
    {
      value: 'NINJA',
    },
    {
      value: 'RAW_LEAD',
    },
    {
      value: 'SHEET',
    },
    {
      value: 'CAF',
    },
    {
      value: 'CAF_SDK',
    },
    {
      value: 'WAP',
    },
    {
      value: 'OFFLINE_KIOSK',
    },
    {
      value: 'LANDING',
    },
    {
      value: 'SDK',
    },
    {
      value: 'CONNECTO_CD',
    },
    {
      value: 'FACEBOOK',
    },
    {
      value: 'AFFILIATE',
    },
    {
      value: 'SMS',
    },
    {
      value: 'MISSED_CALL',
    },
    {
      value: 'TRUECALLER_APP',
    },
    {
      value: 'COACHING',
    },
    {
      value: 'CAF_AFFILIATE',
    },
    {
      value: 'IVR',
    },
    {
      value: 'SHEET2',
    },
    {
      value: 'EXAM_APP',
    },
    {
      value: 'STUDY_ABROAD',
    },
    {
      value: 'NOTIFICATION',
    },
    {
      value: 'JUST_DIAL',
    },
    {
      value: 'AFFILIATE_OFFLINE',
    },
    {
      value: 'SCHOOL_CONNECT',
    },
    {
      value: 'TPC',
    },
    {
      value: 'CHATBOT',
    },
    {
      value: 'SCHOOL_STUDENT_DASHBOARD',
    },
    {
      value: 'SCHOOL_STUDENT_SELF',
    },
    {
      value: 'COUNSELLOR',
    },
  ],
  streamArray: [
    {
      name: 'Engineering',
      value: 'Engineering',
    },
    {
      name: 'Management',
      value: 'Management',
    },
    {
      name: 'Law',
      value: 'Law',
    },
    {
      name: 'Design',
      value: 'Design',
    },
    {
      name: 'Hotel Management',
      value: 'Hotel Management',
    },
    {
      name: 'Pharmacy',
      value: 'Pharmacy',
    },
    {
      name: 'Commerce & Banking',
      value: 'Commerce & Banking',
    },
    {
      name: 'Arts & Humanities',
      value: 'Arts & Humanities',
    },
    {
      name: 'Science',
      value: 'Science',
    },
    {
      name: 'Computer Applications',
      value: 'Computer Applications',
    },
    {
      name: 'Education',
      value: 'Education',
    },
    {
      name: 'Medical',
      value: 'Medical',
    },
    {
      name: 'Architecture',
      value: 'Architecture',
    },
    {
      name: 'Nursing',
      value: 'Nursing',
    },
    {
      name: 'Dental',
      value: 'Dental',
    },
    {
      name: 'Paramedical',
      value: 'Paramedical',
    },
    {
      name: 'Media & Mass Communications',
      value: 'Media & Mass Communications',
    },
    {
      name: 'Agriculture',
      value: 'Agriculture',
    },
    {
      name: 'Aviation',
      value: 'Aviation',
    },
    {
      name: 'Vocational Courses',
      value: 'Vocational Courses',
    },
    {
      name: 'Veterinary Sciences',
      value: 'Veterinary Sciences',
    },
    {
      name: 'Government Exams',
      value: 'Government Exams',
    },
    {
      name: 'Study Abroad',
      value: 'Study Abroad',
    },
    {
      name: 'Information Technology',
      value: 'Information Technology',
    },
    {
      name: 'Physical Education',
      value: 'Physical Education',
    },
    {
      name: 'Performing Arts',
      value: 'Performing Artss',
    },
  ],
  stateArray: [
    {
      id: 1,
      value: 'Andaman and Nicobar Islands',
    },
    {
      id: 2,
      value: 'Andhra Pradesh',
    },
    {
      id: 3,
      value: 'Arunachal Pradesh',
    },
    {
      id: 4,
      value: 'Assam',
    },
    {
      id: 5,
      value: 'Bihar',
    },
    {
      id: 6,
      value: 'Chandigarh',
    },
    {
      id: 7,
      value: 'Chhattisgarh',
    },
    {
      id: 8,
      value: 'Dadra and Nagar Haveli',
    },
    {
      id: 9,
      value: 'Daman and Diu',
    },
    {
      id: 10,
      value: 'Delhi',
    },
    {
      id: 11,
      value: 'Goa',
    },
    {
      id: 12,
      value: 'Gujarat',
    },
    {
      id: 13,
      value: 'Haryana',
    },
    {
      id: 14,
      value: 'Himachal Pradesh',
    },
    {
      id: 15,
      value: 'Jammu and Kashmir',
    },
    {
      id: 16,
      value: 'Jharkhand',
    },
    {
      id: 17,
      value: 'Karnataka',
    },
    {
      id: 19,
      value: 'Kerala',
    },
    {
      id: 20,
      value: 'Lakshadweep',
    },
    {
      id: 21,
      value: 'Madhya Pradesh',
    },
    {
      id: 22,
      value: 'Maharashtra',
    },
    {
      id: 23,
      value: 'Manipur',
    },
    {
      id: 24,
      value: 'Meghalaya',
    },
    {
      id: 25,
      value: 'Mizoram',
    },
    {
      id: 26,
      value: 'Nagaland',
    },
    {
      id: 29,
      value: 'Odisha',
    },
    {
      id: 31,
      value: 'Pondicherry',
    },
    {
      id: 32,
      value: 'Punjab',
    },
    {
      id: 33,
      value: 'Rajasthan',
    },
    {
      id: 34,
      value: 'Sikkim',
    },
    {
      id: 35,
      value: 'Tamil Nadu',
    },
    {
      id: 36,
      value: 'Telangana',
    },
    {
      id: 37,
      value: 'Tripura',
    },
    {
      id: 38,
      value: 'Uttar Pradesh',
    },
    {
      id: 39,
      value: 'Uttarakhand',
    },
    {
      id: 41,
      value: 'West Bengal',
    },
  ],
  stream: [
    {
      name: 'Engineering',
      key_name: 'Engineering',
    },
    {
      name: 'Management',
      key_name: 'Management',
    },
    {
      name: 'Law',
      key_name: 'Law',
    },
    {
      name: 'Design',
      key_name: 'Design',
    },
    {
      name: 'Hotel Management',
      key_name: 'Hotel Management',
    },
    {
      name: 'Pharmacy',
      key_name: 'Pharmacy',
    },
    {
      name: 'Commerce & Banking',
      key_name: 'Commerce & Banking',
    },
    {
      name: 'Arts & Humanities',
      key_name: 'Arts & Humanities',
    },
    {
      name: 'Science',
      key_name: 'Science',
    },
    {
      name: 'Computer Applications',
      key_name: 'Computer Applications',
    },
    {
      name: 'Education',
      key_name: 'Education',
    },
    {
      name: 'Medical',
      key_name: 'Medical',
    },
    {
      name: 'Architecture',
      key_name: 'Architecture',
    },
    {
      name: 'Nursing',
      key_name: 'Nursing',
    },
    {
      name: 'Dental',
      key_name: 'Dental',
    },
    {
      name: 'Paramedical',
      key_name: 'Paramedical',
    },
    {
      name: 'Media & Mass Communications',
      key_name: 'Media & Mass Communications',
    },
    {
      name: 'Agriculture',
      key_name: 'Agriculture',
    },
    {
      name: 'Aviation',
      key_name: 'Aviation',
    },
    {
      name: 'Vocational Courses',
      key_name: 'Vocational Courses',
    },
    {
      name: 'Veterinary Sciences',
      key_name: 'Veterinary Sciences',
    },
    {
      name: 'Government Exams',
      key_name: 'Government Exams',
    },
    {
      name: 'Study Abroad',
      key_name: 'Study Abroad',
    },
    {
      name: 'Information Technology',
      key_name: 'Information Technology',
    },
    {
      name: 'Physical Education',
      key_name: 'Physical Education',
    },
    {
      name: 'Performing Arts',
      key_name: 'Performing Artss',
    },
  ],
  state: [
    {
      name: 'Andaman and Nicobar Islands',
      key_name: 'Andaman and Nicobar Islands',
    },
    {
      name: 'Andhra Pradesh',
      key_name: 'Andhra Pradesh',
    },
    {
      name: 'Arunachal Prade',
      key_name: 'Arunachal Pradesh',
    },
    {
      name: 'Assam',
      key_name: 'Assam',
    },
    {
      name: 'Bihar',
      key_name: 'Bihar',
    },
    {
      name: 'Chandigarh',
      key_name: 'Chandigarh',
    },
    {
      name: 'Chhattisgar',
      key_name: 'Chhattisgarh',
    },
    {
      name: 'Dadra and Nagar Haveli',
      key_name: 'Dadra and Nagar Haveli',
    },
    {
      name: 'Daman and Diu',
      key_name: 'Daman and Diu',
    },
    {
      name: 'Delhi',
      key_name: 'Delhi',
    },
    {
      name: 'Goa',
      key_name: 'Goa',
    },
    {
      name: 'Gujarat',
      key_name: 'Gujarat',
    },
    {
      name: 'Haryana',
      key_name: 'Haryana',
    },
    {
      name: 'Himachal Prades',
      key_name: 'Himachal Pradesh',
    },
    {
      name: 'Jammu and Kashmir',
      key_name: 'Jammu and Kashmir',
    },
    {
      name: 'Jharkhand',
      key_name: 'Jharkhand',
    },
    {
      name: 'Karnata',
      key_name: 'Karnataka',
    },
    {
      name: 'Kenmore',
      key_name: 'Kenmore',
    },
    {
      name: 'Kerala',
      key_name: 'Kerala',
    },
    {
      name: 'Lakshadweep',
      key_name: 'Lakshadweep',
    },
    {
      name: 'Madhya Pradesh',
      key_name: 'Madhya Pradesh',
    },
    {
      name: 'Maharashtra',
      key_name: 'Maharashtra',
    },
    {
      name: 'Manipur',
      key_name: 'Manipur',
    },
    {
      name: 'Meghala',
      key_name: 'Meghalaya',
    },
    {
      name: 'Mizoram',
      key_name: 'Mizoram',
    },
    {
      name: 'Nagaland',
      key_name: 'Nagaland',
    },
    {
      name: 'Narora',
      key_name: 'Narora',
    },
    {
      name: 'Natwar',
      key_name: 'Natwar',
    },
    {
      name: 'Odisha',
      key_name: 'Odisha',
    },
    {
      name: 'Paschim Medinipur',
      key_name: 'Paschim Medinipur',
    },
    {
      name: 'Pondicherry',
      key_name: 'Pondicherry',
    },
    {
      name: 'Punjab',
      key_name: 'Punjab',
    },
    {
      name: 'Rajasth',
      key_name: 'Rajasthan',
    },
    {
      name: 'Sikkim',
      key_name: 'Sikkim',
    },
    {
      name: 'Tamil Nadu',
      key_name: 'Tamil Nadu',
    },
    {
      name: 'Telanga',
      key_name: 'Telangana',
    },
    {
      name: 'Tripura',
      key_name: 'Tripura',
    },
    {
      name: 'Uttar Pradesh',
      key_name: 'Uttar Pradesh',
    },
    {
      name: 'Uttarakhand',
      key_name: 'Uttarakhand',
    },
    {
      name: 'Vaishali',
      key_name: 'Vaishali',
    },
    {
      name: 'West Bengal',
      key_name: 'West Bengal',
    },
  ],
  source: [
    {
      name: 'counsellor',
      key_name: 'counsellor',
    },
    {
      name: 'info',
      key_name: 'info',
    },
    {
      name: 'reviews',
      key_name: 'reviews',
    },
    {
      name: 'News',
      key_name: 'News',
    },
    {
      name: 'customCampaignPage',
      key_name: 'customCampaignPage',
    },
    {
      name: 'courses',
      key_name: 'courses',
    },
    {
      name: 'Facebook',
      key_name: 'Facebook',
    },
    {
      name: 'Web',
      key_name: 'Web',
    },
    {
      name: 'contact',
      key_name: 'contact',
    },
    {
      name: 'placement',
      key_name: 'placement',
    },
    {
      name: 'gallery',
      key_name: 'gallery',
    },
    {
      name: 'Campaign',
      key_name: 'Campaign',
    },
    {
      name: 'Bot',
      key_name: 'Bot',
    },
    {
      name: 'faculty',
      key_name: 'faculty',
    },
    {
      name: 'srm(Kattankulathur)_JA',
      key_name: 'srm(Kattankulathur)_JA',
    },
    {
      name: 'SMS',
      key_name: 'SMS',
    },
    {
      name: 'APP',
      key_name: 'APP',
    },
    {
      name: 'NINJA',
      key_name: 'NINJA',
    },
    {
      name: 'vit(tamilnadu)_JA',
      key_name: 'vit(tamilnadu)_JA',
    },
    {
      name: 'CAF',
      key_name: 'CAF',
    },
    {
      name: 'LANDING',
      key_name: 'LANDING',
    },
    {
      name: 'AFFILIATE',
      key_name: 'AFFILIATE',
    },
    {
      name: 'SHEET',
      key_name: 'SHEET',
    },
    {
      name: 'IVR',
      key_name: 'IVR',
    },
    {
      name: 'AFFILIATE_OFFLINE',
      key_name: 'AFFILIATE_OFFLINE',
    },
    {
      name: 'SHEET2',
      key_name: 'SHEET2',
    },
    {
      name: 'CAF_AFFILIATE',
      key_name: 'CAF_AFFILIATE',
    },
    {
      name: 'SCHOOL_STUDENT_DASHBOARD',
      key_name: 'SCHOOL_STUDENT_DASHBOARD',
    },
    {
      name: 'call history',
      key_name: 'call history',
    },
    {
      name: 'SCHOOL_STUDENT_SELF',
      key_name: 'SCHOOL_STUDENT_SELF',
    },
    {
      name: 'upes(joinadmission)',
      key_name: 'upes(joinadmission)',
    },
    {
      name: 'RAW_LEAD',
      key_name: 'RAW_LEAD',
    },
    {
      name: 'MISSED_CALL',
      key_name: 'MISSED_CALL',
    },
    {
      name: 'joinadmission',
      key_name: 'joinadmission',
    },
    {
      name: 'WAP',
      key_name: 'WAP',
    },
    {
      name: 'CONNECTO_CD',
      key_name: 'CONNECTO_CD',
    },
    {
      name: 'TRUECALLER_APP',
      key_name: 'TRUECALLER_APP',
    },
    {
      name: 'COACHING',
      key_name: 'COACHING',
    },
    {
      name: 'OFFLINE_KIOSK',
      key_name: 'OFFLINE_KIOSK',
    },
    {
      name: 'STUDY_ABROAD',
      key_name: 'STUDY_ABROAD',
    },
    {
      name: 'EXAM_APP',
      key_name: 'EXAM_APP',
    },
    {
      name: 'CAF_SDK',
      key_name: 'CAF_SDK',
    },
    {
      name: 'mbbscounselling',
      key_name: 'mbbscounselling',
    },
    {
      name: 'SDK',
      key_name: 'SDK',
    },
    {
      name: 'ivr_incoming',
      key_name: 'ivr_incoming',
    },
  ],
  bankNames: [
    {
      name: 'Axis Bank Ltd',
      value: 'axis-bank',
    },
    {
      name: 'Bandhan Bank Ltd',
      value: 'bandhan-bank',
    },
    {
      name: 'CSB Bank Ltd',
      value: 'csb-bank',
    },
    {
      name: 'City Union Bank Ltd',
      value: 'city-union-bank',
    },
    {
      name: 'DCB Bank Ltd',
      value: 'dcb-bank',
    },
    {
      name: 'Dhanlaxmi Bank Ltd',
      value: 'dhanlaxmi-bank',
    },
    {
      name: 'Federal Bank Ltd',
      value: 'federal-bank',
    },
    {
      name: 'HDFC Bank Ltd',
      value: 'hdfc-bank',
    },
    {
      name: 'ICICI Bank Ltd',
      value: 'icici-bank',
    },
    {
      name: 'Induslnd Bank Ltd',
      value: 'induslnd-bank',
    },
    {
      name: 'IDFC First Bank Ltd',
      value: 'idfc-first-bank',
    },
    {
      name: 'Jammu & Kashmir Bank Ltd',
      value: 'jammu-and-kashmir-bank',
    },
    {
      name: 'Karnataka Bank Ltd',
      value: 'karnataka-bank',
    },
    {
      name: 'Karur Vysya Bank Ltd',
      value: 'karur-vysya-bank',
    },
    {
      name: 'Kotak Mahindra Bank Ltd',
      value: 'kotak-mahindra-bank',
    },
    {
      name: 'Nainital Bank Ltd',
      value: 'nainital-bank',
    },
    {
      name: 'RBL Bank Ltd',
      value: 'rbl-bank',
    },
    {
      name: 'South Indian Bank Ltd',
      value: 'south-indian-bank',
    },
    {
      name: 'Tamilnad Mercantile Bank Ltd',
      value: 'tamilnad-mercantile-bank',
    },
    {
      name: 'YES Bank Ltd',
      value: 'yes-bank',
    },
    {
      name: 'IDBI Bank Ltd',
      value: 'idbi-bank',
    },
    {
      name: 'Bank of Baroda',
      value: 'bank-of-baroda',
    },
    {
      name: 'Bank of India',
      value: 'bank-of-india',
    },
    {
      name: 'Bank of Maharashtra',
      value: 'bank-of-maharashtra',
    },
    {
      name: 'Canara Bank',
      value: 'canara-bank',
    },
    {
      name: 'Central Bank of India',
      value: 'central-bank-of-india',
    },
    {
      name: 'Indian Bank',
      value: 'indian-bank',
    },
    {
      name: 'Indian Overseas Bank',
      value: 'indian-overseas-bank',
    },
    {
      name: 'Punjab & Sind Bank',
      value: 'punjab-and-sind-bank',
    },
    {
      name: 'Punjab National Bank',
      value: 'punjab-national-bank',
    },
    {
      name: 'State Bank of India',
      value: 'state-bank-of-india',
    },
    {
      name: 'UCO Bank',
      value: 'uco-bank',
    },
    {
      name: 'Union Bank of India',
      value: 'union-bank-of-india',
    },
  ],
  days: [
    {
      name: 'Monday',
      value: 'mon',
    },
    {
      name: 'Tuesday',
      value: 'tue',
    },
    {
      name: 'Wednesday',
      value: 'wed',
    },
    {
      name: 'Thrusday',
      value: 'thru',
    },
    {
      name: 'Friday',
      value: 'fri',
    },
    {
      name: 'Saturday',
      value: 'sat',
    },
    {
      name: 'Sunday',
      value: 'sun',
    },
  ],
  languages: [
    {
      name: 'Hindi',
    },
    {
      name: 'English',
    },
    {
      name: 'Tamil',
    },
    {
      name: 'Telugu',
    },
    {
      name: 'Assamese',
    },
    {
      name: 'Bangla',
    },
    {
      name: 'Dogri',
    },
    {
      name: 'Gujarati',
    },
    {
      name: 'Kannada',
    },
    {
      name: 'Kashmiri',
    },
    {
      name: 'Maithili',
    },
    {
      name: 'Malayalam',
    },
    {
      name: 'Manipuri',
    },
    {
      name: 'Marathi',
    },
    {
      name: 'Nepali',
    },
    {
      name: 'Odia',
    },
    {
      name: 'Punjabi',
    },
    {
      name: 'Urdu',
    },
  ],
};
