<div class="login-wraper">
  <div class="col-md-4 offset-md-3 col-xs-12 mt-5 login-form">
    <div class="login-left">
      <img src="assets/images/logo-powered.svg" alt="logo" class="logo" />
      <div class="banner-img margin-t20">
        <img src="assets/images/features-img.png" />
      </div>
    </div>
    <div class="card">
      <h4 class="card-header fw900 mr-t20">Login to your account</h4>
      <div class="card-body">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="username">Email</label>
            <input
              type="text"
              formControlName="username"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
              <div *ngIf="f.username.errors.required">Username is required</div>
            </div>
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <div class="field-wraper">
              <input
                [type]="fieldTextType ? 'text' : 'password'"
                formControlName="password"
                autocomplete="off"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
              <svg (click)="toggleFieldTextType()" class="dash-icons" *ngIf="fieldTextType">
                <use href="assets/images/icon.svg#eye-slash"></use>
              </svg>
              <svg (click)="toggleFieldTextType()" class="dash-icons" *ngIf="!fieldTextType">
                <use href="assets/images/icon.svg#view"></use>
              </svg>
            </div>
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Password is required</div>
            </div>
            <p class="text-right">
              <a data-toggle="modal" data-target="#forgotPassword" class="text-blue"
                >Forgot Password</a
              >
            </p>
          </div>
          <div class="form-group form-btn">
            <button [disabled]="loading" class="btn btn-blue">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              {{loginBtn}}
            </button>
            <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- forgot password -->
<div
  class="modal fade in static-modal"
  id="forgotPassword"
  tabindex="-1"
  role="dialog"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="fs16 fw700">Forgot Your Passsword</h2>
        <a class="close-btn" data-dismiss="modal">
          <svg class="dash-icons">
            <use href="assets/images/icon.svg#close-btn" />
          </svg>
        </a>
      </div>
      <div class="modal-body">
        <p>
          To reset your password, please contact your manager for assistance. They will guide you
          through the necessary steps to recover your account.
        </p>
      </div>
    </div>
  </div>
</div>
