import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-dialer',
  templateUrl: './dialer.component.html',
  styleUrls: ['./dialer.component.css']
})
export class DialerComponent implements OnInit {
  private dialerWindow: Window | null = null;

  constructor( private userService: UserService) { }
  currentUser = {}
  loginDetails = []

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.getCurrentUserDetails()
    console.log(this.loginDetails)
  }

  getCurrentUserDetails() {
    this.userService.getUserLoginDetails().subscribe((res: any) => {
      console.log(res);
      this.loginDetails = res.data;
      const iframe = document.querySelector('iframe');

      if (this.loginDetails.length > 0) {
        const name = this.loginDetails[0].name;
        const mobile = this.loginDetails[0].alt_mobile;
        const firstLetter = name.charAt(0).toUpperCase();
        const secondLetter = name.charAt(1).toLowerCase();

        const firstSixDigits = mobile.substring(0, 6);
        const plain_password = firstLetter + secondLetter + firstSixDigits;

        console.log('Generated Password:', plain_password);
        const url = `https://webrtc.elisiontec.com/agent/index.php?VD_login=${mobile}&VD_pass=${plain_password}`;

        // Check if the window is already open and has the same URL
        if (this.dialerWindow && !this.dialerWindow.closed) {
          // Focus the already opened window
          this.dialerWindow.focus();
        } else {
          // Open a new window and store the reference
          this.dialerWindow = window.open(url, '_blank', 'width=1000,height=1000');

          // Check if the new window was successfully opened
          if (this.dialerWindow) {
            if (iframe) {
              // You can set the iframe content here if needed
              this.dialerWindow.document.body.innerHTML = iframe.outerHTML;
            }
          } else {
            console.error("Popup blocked or failed to open new window.");
          }
        }
      }
      console.log(this.loginDetails);
    }, error => {
      console.error("Failed to fetch user details:", error);
    });
  }
  

}