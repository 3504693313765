import { Component, Renderer2, OnInit } from '@angular/core';
import { UserService } from '../../_services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tracking-active-user',
  templateUrl: './tracking-active-user.component.html',
  styleUrls: ['./tracking-active-user.component.css'],
})
export class TrackingActiveUserComponent implements OnInit {
  timeIn;

  INITIAL_WAIT = 3000;
  INTERVAL_WAIT = environment.TIME_SPENT_IDLE_DURATION;
  ONE_SECOND = 1000;
  events = ['mouseup', 'keydown', 'scroll', 'mousemove'];
  startTime = Date.now();
  endTime = this.startTime + this.INITIAL_WAIT;
  totalTime = 0;
  totalTimeDb = 0;
  clickCount = 0;
  buttonClicks = {
    total: 0,
  };
  buttonClickCount = 0;
  linkClickCount = 0;
  keypressCount = 0;
  scrollCount = 0;
  mouseMovementCount = 0;
  private unlistener: () => void;
  constructor(
    private renderer2: Renderer2,
    private userService: UserService
  ) {}
  ngOnInit() {
    this.timeN();
    this.intervalTime();
    this.unlistener = this.renderer2.listen('document', 'mousemove', event => {
      this.endTime = Date.now() + this.INTERVAL_WAIT;
      //console.log(`I am detecting mousemove at ${event.pageX}, ${event.pageY} on Document!`);
    });
  }
  timeN() {
    let apihit = false;
    this.timeIn = setInterval(() => {
      if (this.startTime <= this.endTime) {
        this.startTime = Date.now();
        this.totalTime += this.ONE_SECOND;
        this.totalTimeDb += this.ONE_SECOND;
        apihit = false;
      } else if (!apihit) {
        //console.log("api apihit", apihit)
        apihit = true;
        this.updateUserActive();
        //console.log("api hit", apihit)
      } else {
        //console.log("contine hit")
      }
    }, this.ONE_SECOND);
  }

  intervalTime() {
    this.timeIn = setInterval(() => {
      this.updateUserActive();
    }, environment.TIME_SPENT_POLLING_TIME);
  }

  ngOnDestroy() {
    this.unlistener();
  }

  updateUserActive() {
    this.userService
      .userActiveHistory({
        totalSeconds: this.totalTimeDb,
      })
      .subscribe(
        (roles: any) => {
          if (roles.code == 200) {
            this.totalTimeDb = 0;
          }
        },
        error => {
          console.log(error);
        }
      );
  }
}
