import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
// used to create fake backend
// import { fakeBackendProvider } from './_helpers/fake-backend';

import { AppComponent } from './app.component';
import { appRoutingModule } from './app-routing.module';

import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { LoginComponent } from './components/login/login.component';
import { HeaderComponent } from './components/common/header/header.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { SidebarComponent } from './components/common/sidebar/sidebar.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
// import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { PapaParseModule } from 'ngx-papaparse';
// import { SocketioService } from './_services/socketio.service';

import { NgxPaginationModule } from 'ngx-pagination';
import { ProductOrderDetailComponent } from './components/product-order-detail/product-order-detail.component';
/*For LMS report */
import { LeadSearchModule } from './components/lead-search/lead-search.module';
import { TenantProfileComponent } from './components/tenant/tenant-profile/tenant-profile.component';
import { GlobalErrorHandler } from './_helpers/globalerrorhandler';
import { CKEditorModule } from 'ckeditor4-angular';
import { EditorModule } from '@tinymce/tinymce-angular';

import { TrackingActiveUserComponent } from './components/tracking-active-user/tracking-active-user.component';
import { WhatsappChatComponent } from './components/settings/whatsapp-chat/whatsapp-chat.component';
import { StudentDetailsModalComponent } from './components/student-details-modal/student-details-modal.component';
import * as moment from 'moment';
import { AuthServiceService } from './_services/auth-service.service';
import { RoleGuard } from './_helpers/role.guard';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
// import { NgxOrgChartModule } from 'ngx-org-chart';
import { DialerComponent } from './components/dialer/dialer.component';
import { DialerCofigComponent } from './components/dialer/dialer-cofig/dialer-cofig.component';
import { ListComponent } from './components/dialer/list/list.component';
import { TimelineComponent } from './components/settings/timeline/timeline.component';
// import { LeadNoteAmComponent } from './components/lead-note-am/lead-note-am.component';
// import { CallActionAMComponent } from './components/form-builder/call-action-am/call-action-am.component';
// import { SendSmsComponent } from './components/common/sms-email-popup/send-sms/send-sms.component';
// import { SendEmailComponent } from './components/common/sms-email-popup/send-email/send-email.component';
@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    appRoutingModule,
    ToastrModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    RouterModule,
    LeadSearchModule,
    EditorModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    PapaParseModule,
    NgxPaginationModule,
    NgSelectModule,
    // NgxOrgChartModule,
    CKEditorModule,
    NgxDaterangepickerMd.forRoot({
      displayFormat: 'MMMM D, YYYY',
      //format: 'MM/DD/YYYY', // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
      direction: 'ltr', // could be rtl
      weekLabel: 'W',
      //separator: ' To ', // default is ' - '
      //applyLabel: 'Okay', // detault is 'Apply'
      //cancelLabel: 'Cancel', // detault is 'Cancel'
      clearLabel: 'Reset', // detault is 'Clear'
      //customRangeLabel: 'Custom range',
      //daysOfWeek: moment.weekdaysMin(),
      //monthNames: moment.months(),
      firstDay: 1, // first day is monday
    }),
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ProductOrderDetailComponent,
    TenantProfileComponent,
    TrackingActiveUserComponent,
    WhatsappChatComponent,
    StudentDetailsModalComponent,
    AccessDeniedComponent,
    DialerComponent,
    DialerCofigComponent,
    ListComponent,
    TimelineComponent,
    // LeadNoteAmComponent,
    // CallActionAMComponent,
    // SendSmsComponent,
    // SendEmailComponent,
  ],

  providers: [
    // SocketioService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    DatePipe,

    // provider used to create fake backend
    // fakeBackendProvider
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
