import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../_models/user';
import { AclService } from 'src/app/_services/acl.service';
import { AuthServiceService } from 'src/app/_services/auth-service.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(
    private http: HttpClient,
    private aclService: AclService,
    private authService: AuthServiceService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    return this.http
      .post<any>(
        `${environment.apiUrl}users/auth`,
        { username, password },
        { withCredentials: true }
      )
      .pipe(
        map(user => {
          if (user.code === 200) {
            console.log(user);
            localStorage.setItem('currentUser', JSON.stringify(user.result));
            localStorage.setItem('persist', JSON.stringify(user.tenantDetail));
            this.currentUserSubject.next(user.result);
          }
          return user;
        })
      );
  }

  refreshToken(User) {
    console.log('users-------->', User);
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('persist');
    localStorage.clear();
    this.currentUserSubject.next(null);
    this.currentUserSubject.next(null);
    this.aclService.clearAclData(); // Clear cached ACL data on logout
    this.authService.clearPermissions();
  }
}
