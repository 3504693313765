import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class FormBuilderService {
  private dropdownOptions = new BehaviorSubject<any>(null);
  public dropdownOptions$ = this.dropdownOptions.asObservable();

  constructor(private http: HttpClient) {}

  getDropdownOptions() {
    return this.http.get(`${environment.apiUrl}pre-application/dropdown-options`);
  }
  getStates() {
    return this.http.get(`${environment.apiUrl}getstates`);
  }

  getCities(stateId) {
    return this.http.get(`${environment.apiUrl}getcities?stateId=${stateId}`);
  }

  addQuickclix(data) {
    return this.http.post(`${environment.apiUrl}pre-application/quickclix-options`, data);
  }

  updateQuickclix(id, data) {
    return this.http.patch(`${environment.apiUrl}pre-application/quickclix-options/${id}`, data);
  }

  getQuickclixes() {
    return this.http.get(`${environment.apiUrl}pre-application/quickclix-options`);
  }

  setDropdownOptions(data) {
    this.dropdownOptions.next(data);
  }

  createForm(data: any) {
    return this.http.post(`${environment.apiUrl}pre-application`, data);
  }
  createCollege(data: any) {
    return this.http.post(`${environment.apiUrl}colleges/add-v2`, data);
  }

  updateForm(data: any, id: any) {
    return this.http.patch(`${environment.apiUrl}pre-application/${id}`, data);
  }

  updateCollege(data: any, id: any) {
    return this.http.patch(`${environment.apiUrl}colleges/update-v2/${id}`, data);
  }
  getCombinedTimeline(leadId) {
    return this.http.get(
      `${environment.apiUrl}pre-application/get-combined-timeline?leadId=${leadId}`
    );
  }
  getCommonTimeline(
    lead_id: string,
    category?: string,
    dateFilter?: string,
    fromDate?: string,
    toDate?: string
  ) {
    let queryParams = `?`;

    if (category) queryParams += `category=${category}&`;
    if (dateFilter) queryParams += `dateFilter=${dateFilter}&`;
    if (fromDate) queryParams += `fromDate=${fromDate}&`;
    if (toDate) queryParams += `toDate=${toDate}&`;

    // Remove trailing '&' if present
    queryParams = queryParams.slice(-1) === '&' ? queryParams.slice(0, -1) : queryParams;

    return this.http.get(
      `${environment.apiUrl}pre-application/get-timeline/${lead_id}${queryParams}`
    );
  }

  getFormData(pagination) {
    return this.http.get(
      `${environment.apiUrl}pre-application?limit=${pagination.limit}&page=${pagination.page}`
    );
  }

  getInstituteData(body, page, limit) {
    const params = [];

    // Add parameters only if they exist in the body
    if (body.clg_name) {
      params.push(`clg_name=${encodeURIComponent(body.clg_name)}`);
    }
    if (body.status) {
      params.push(`status=${encodeURIComponent(body.status)}`);
    }
    if (body.created_by) {
      params.push(`created_by=${encodeURIComponent(body.created_by.toString())}`);
    }
    if (body.created_at_from) {
      params.push(`created_at_from=${encodeURIComponent(body.created_at_from)}`);
    }
    if (body.created_at_to) {
      params.push(`created_at_to=${encodeURIComponent(body.created_at_to)}`);
    }
    if (body.updated_at_from) {
      params.push(`updated_at_from=${encodeURIComponent(body.updated_at_from)}`);
    }
    if (body.updated_at_to) {
      params.push(`updated_at_to=${encodeURIComponent(body.updated_at_to)}`);
    }
    // if (body.pageNumber) {
    //   params.push(`page=${encodeURIComponent(body.pageLimit)}`);
    // }
    // if (body.limit) {
    //   params.push(`limit=${encodeURIComponent(body.currentPage)}`);
    // }

    // Join all parameters to form the query string
    const queryString = params.length > 0 ? `${params.join('&')}` : '';

    // Make the HTTP GET request with the constructed query string
    return this.http.get(
      `${environment.apiUrl}colleges/getAllColleges?${queryString}&pageNumber=${page}&limit=${limit}`
    );
  }

  getTemplates() {
    return this.http.get(`${environment.apiUrl}pre-application/form-template`);
  }

  deleteTemplate(id) {
    return this.http.delete(`${environment.apiUrl}pre-application/form-template/${id}`);
  }

  getTemplatesById(id) {
    return this.http.get(`${environment.apiUrl}pre-application/form-template/${id}`);
  }

  getCollegeDetails(id) {
    return this.http.get(`${environment.apiUrl}colleges/getDetails/${id}`);
  }

  addTemplate(data) {
    return this.http.post(`${environment.apiUrl}/pre-application/form-template`, data);
  }

  getFormDataById(id) {
    return this.http.get(`${environment.apiUrl}pre-application/?_id=${id}`);
  }

  getFormDataByForName(form_name, pagination) {
    return this.http.get(
      `${environment.apiUrl}pre-application?form_title=${form_name}&limit=${pagination.limit}&page=${pagination.page}`
    );
  }

  getFormDataByNameByClg(form_name, clg_id, pagination) {
    return this.http.get(
      `${environment.apiUrl}pre-application?form_title=${form_name}&clg_id=${clg_id}&limit=${pagination.limit}&page=${pagination.page}`
    );
  }

  getFilteredFormData(data, pagination) {
    return this.http.get(
      `${environment.apiUrl}pre-application?form_title=${data.form_name}&clg_id=${
        data.clg_id
      }&end_date=${data.end_date}&is_hide=${
        data.enable_disable ? data.enable_disable : ''
      }&created_at=${data.created_at}&limit=${pagination.limit}&page=${pagination.page}`
    );
  }

  getFormList(clg_id) {
    return this.http.get(`${environment.apiUrl}pre-application/forms?clg_id=${clg_id}`);
  }

  getPartnerClgInfo(clg_id) {
    return this.http.get(`${environment.apiUrl}pre-application/partner-college-info/${clg_id}`);
  }

  addPartnerClgInfo(data) {
    return this.http.post(`${environment.apiUrl}pre-application/partner-college-info`, data);
  }

  updatePartnerClgInfo(data, id) {
    return this.http.put(`${environment.apiUrl}updateClg/${id}`, data);
  }

  addFormWidget(data) {
    return this.http.post(`${environment.apiUrl}pre-application/form-widget`, data);
  }

  getFormWidget(filters) {
    return this.http.get(`${environment.apiUrl}pre-application/form-widget`, {
      params: filters,
    });
  }

  updateFormWidget(id, data) {
    return this.http.patch(`${environment.apiUrl}pre-application/form-widget/${id}`, data);
  }

  getClgQueriesCount() {
    return this.http.get(`${environment.apiUrl}pre-application/queries/count`);
  }

  getAllQueriesCollegeWise(pagination, clg_id, query_status, query) {
    const params = new URLSearchParams();
    if (clg_id) {
      params.set('clg_id', clg_id);
    }
    if (query) {
      params.set('search_query', query);
    }
    if (query_status) {
      params.set('query_status', query_status);
    }
    let lead_id = 0;
    if (pagination.lead_id) {
      lead_id = pagination.lead_id;
    }
    return this.http.get(
      `${environment.apiUrl}pre-application/queries?limit=${pagination.limit}&page=${
        pagination.page
      }&lead_id=${lead_id}&${params.toString()}`
    );
  }

  getQuery(id) {
    return this.http.get(`${environment.apiUrl}pre-application/queries?_id=${id}`);
  }

  updateQuery(id, data) {
    return this.http.patch(`${environment.apiUrl}pre-application/queries/${id}`, data);
  }

  uploadDocuments(data: any) {
    return this.http.post(`${environment.apiUrl}pre-application/college-documents`, data);
  }

  getClgUploadedDocuments(data, pagination, search) {
    return this.http.get(
      `${environment.apiUrl}pre-application/college-documents?limit=${pagination.limit}&page=${pagination.page}&file_title=${search}&clg_id=${data.clg_id}&form_name=${data.form_name}`
    );
  }

  getClgUploadedDocumentsIdWise(id) {
    return this.http.get(`${environment.apiUrl}pre-application/college-documents?_id=${id}`);
  }

  updateClgUploadedDocument(id, data) {
    return this.http.patch(`${environment.apiUrl}pre-application/college-documents/${id}`, data);
  }

  removeClgUploadedDocument(id) {
    return this.http.delete(`${environment.apiUrl}pre-application/college-documents/${id}`);
  }

  addFaq(data) {
    return this.http.post(`${environment.apiUrl}pre-application/faqs`, data);
  }

  getFaqs(data, pagination) {
    return this.http.get(
      `${environment.apiUrl}pre-application/faqs?limit=${pagination.limit}&page=${pagination.page}&clg_id=${data.clg_id}&category=${data.category}`
    );
  }

  getFaq(id) {
    return this.http.get(`${environment.apiUrl}pre-application/faqs?_id=${id}`);
  }

  updateFaq(id, data) {
    return this.http.patch(`${environment.apiUrl}pre-application/faqs/${id}`, data);
  }

  addFormPayment(data) {
    return this.http.post(`${environment.apiUrl}pre-application/payment-manager`, data);
  }

  getFormPayment(clg_id, form_name) {
    return this.http.get(
      `${environment.apiUrl}pre-application/payment-manager?clg_id=${clg_id}&form_name=${form_name}`
    );
  }

  getFormPaymentList(form_name: string, pagination?: { limit?: number; page?: number }) {
    const params = new URLSearchParams();
    console.log(form_name, 'filters.form_name');

    if (form_name) {
      params.set('form_name', form_name);
    }
    const limit = pagination && pagination.limit ? pagination.limit : 10; // Default limit value
    const page = pagination && pagination.page ? pagination.page : 1; // Default page value

    return this.http.get(
      `${
        environment.apiUrl
      }pre-application/payment-manager-list?${params.toString()}&limit=${limit}&page=${page}`
    );
  }

  updateFormPayment(id, data) {
    return this.http.patch(`${environment.apiUrl}pre-application/payment-manager/${id}`, data);
  }
  deleteFormPayment(id) {
    return this.http.delete(`${environment.apiUrl}pre-application/payment-manager/${id}`);
  }

  getApplicationManagerListing(data) {
    return this.http.post(`${environment.apiUrl}pre-application/form-responses`, data);
  }

  getAllFormData(data) {
    return this.http.post(`${environment.apiUrl}pre-application/form-responses-custom-v7`, data);
  }

  searchFormData(data) {
    return this.http.put(`${environment.apiUrl}pre-application/form-responses-search`, data);
  }

  applicationTrendGraphData(data) {
    return this.http.put(`${environment.apiUrl}pre-application/application-trend-graph`, data);
  }

  applicationStageWiseGraphData(body) {
    return this.http.post(`${environment.apiUrl}pre-application/application-stagewise-graph`, body);
  }

  applicationGeographicalStateWiseGraphData(body) {
    return this.http.post(
      `${environment.apiUrl}pre-application/application-geographicalstate-graph`,
      body
    );
  }

  applicationGeographicalCityWiseGraphData(data) {
    return this.http.put(
      `${environment.apiUrl}pre-application/application-geographicalcity-graph`,
      data
    );
  }

  untouchedApplicationsGraphData() {
    return this.http.get(`${environment.apiUrl}pre-application/untouched-applications-graph`);
  }

  getApplicationManagerListingById(id) {
    return this.http.get(`${environment.apiUrl}pre-application/form-responses?_id=${id}`);
  }

  getApplicationManagerListingByScorecard(id) {
    return this.http.get(`${environment.apiUrl}pre-application/form-responses?scorecard_id=${id}`);
  }

  updateApplicationFormResponse(id, data) {
    return this.http.patch(`${environment.apiUrl}pre-application/form-responses/${id}`, data);
  }

  updateApplicationFormResponseStage(data) {
    return this.http.patch(`${environment.apiUrl}pre-application/form-responses`, data);
  }

  removeFormResponse(data) {
    return this.http.patch(`${environment.apiUrl}pre-application/remove-form-responses`, data);
  }

  releaseOfferLetter(data) {
    return this.http.post(`${environment.apiUrl}pre-application/form-responses/offer-letter`, data);
  }

  sendQueryMessage(data) {
    return this.http.post(`${environment.apiUrl}pre-application/query-message`, data);
  }

  updateQueryMessage(id, data) {
    return this.http.patch(`${environment.apiUrl}pre-application/query-message/${id}`, data);
  }

  getQueryMessages(id) {
    return this.http.get(`${environment.apiUrl}pre-application/query-message?query_id=${id}`);
  }

  bulkApplicationUpload(data) {
    return this.http.post(`${environment.apiUrl}pre-application/bulk-upload/user`, data);
  }
  bulkCounsellorUpload(data) {
    return this.http.post(
      `${environment.apiUrl}pre-application/bulk-upload/counsellor-allocation`,
      data
    );
  }

  singleApplicationUpload(data) {
    return this.http.post(`${environment.apiUrl}pre-application/bulk-upload/single-user`, data);
  }

  bulkApplicationUploadRejected(page: number = 1, limit: number = 10) {
    return this.http.get(
      `${environment.apiUrl}pre-application/bulk-upload/rejected-user?page=${page}&limit=${limit}`
    );
  }

  getPaymentOrderResponse(page, limit, filters, status) {
    console.log('check2', filters);
    const params = new URLSearchParams();
    if (filters.clg_id) {
      params.set('clg_id', filters.clg_id);
    }
    if (filters.form_name) {
      params.set('form_name', filters.form_name);
    }
    if (filters.payment_status || status) {
      let pay_status = status ? status : filters.payment_status;
      params.set('status', pay_status);
    }
    if (filters.payment_id) {
      params.set('payment_id', filters.payment_id);
    }
    if (filters.approved_at) {
      params.set('updated_at', filters.approved_at);
    }
    return this.http.get(
      `${
        environment.apiUrl
      }pre-application/order-response?${params.toString()}&limit=${limit}&page=${page}`
    );
  }
  getformDeatils(filter) {
    return this.http.post(`${environment.apiUrl}pre-application/details`, filter);
  }

  getPaymentOrderResponseById(id) {
    return this.http.get(
      `${environment.apiUrl}pre-application/order-response/payment-details/${id}`
    );
  }
  getPaymentOrderResponseByType(order_type: string, limit, page) {
    return this.http.get(
      `${environment.apiUrl}pre-application/order-response?clg_id=&limit=${limit}&page=${page}&order_type=${order_type}`
    );
  }

  getCoupons(pagination) {
    return this.http.get(
      `${environment.apiUrl}pre-application/offer?limit=${pagination.limit}&page=${pagination.page}`
    );
  }

  getCouponsById(id) {
    return this.http.get(`${environment.apiUrl}pre-application/offer?_id=${id}`);
  }

  addCoupon(data) {
    return this.http.post(`${environment.apiUrl}pre-application/offer`, data);
  }

  updateCoupon(id, data) {
    return this.http.patch(`${environment.apiUrl}pre-application/offer/${id}`, data);
  }

  changePaymentStatus(data) {
    return this.http.patch(
      `${environment.apiUrl}pre-application/form-responses/offline-payment-status`,
      data
    );
  }

  getFormDocuments(data) {
    return this.http.post(
      `${environment.apiUrl}pre-application/form-responses/get-form-documents`,
      data
    );
  }

  addInterviewProcess(data) {
    return this.http.post(`${environment.apiUrl}post-application/interview-slot-booking`, data);
  }

  updateInterviewProcess(id, processId, data) {
    return this.http.patch(
      `${environment.apiUrl}post-application/interview-slot-booking/${id}`,
      data
    );
  }

  getInterviewProcess(clg_id, form_name) {
    return this.http.get(
      `${environment.apiUrl}post-application/interview-slot-booking?clg_id=${clg_id}&form_name=${form_name}`
    );
  }

  getInterviewProcessById(id) {
    return this.http.get(`${environment.apiUrl}post-application/interview-slot-booking?_id=${id}`);
  }

  addInterviewSlotBulkUpload(data) {
    return this.http.post(
      `${environment.apiUrl}post-application/interview-slot-type/bulk-upload`,
      data
    );
  }

  getInterviewSlotBulkUpload() {
    return this.http.get(
      `${environment.apiUrl}post-application/interview-slot-booking/bulk-upload`
    );
  }

  getApplicationProcess(clg_id, pagination) {
    return this.http.get(
      `${environment.apiUrl}post-application/design-process?clg_id=${clg_id}&limit=${pagination.limit}&page=${pagination.page}`
    );
  }

  getApplicationProcessById(id) {
    return this.http.get(`${environment.apiUrl}post-application/design-process?_id=${id}`);
  }

  addApplicationProcess(data) {
    return this.http.post(`${environment.apiUrl}post-application/design-process`, data);
  }

  updateApplicationProcess(id, data) {
    return this.http.patch(`${environment.apiUrl}post-application/design-process/${id}`, data);
  }

  addInterviewSlot(data) {
    return this.http.post(`${environment.apiUrl}post-application/interview-slot-type`, data);
  }

  deleteInterviewSlot(id) {
    return this.http.delete(`${environment.apiUrl}post-application/interview-slot-type/${id}`);
  }

  updateInterviewSlot(id, data) {
    return this.http.patch(`${environment.apiUrl}post-application/interview-slot-type/${id}`, data);
  }

  getInterviewSlot(clg_id, form, process_name, pagination) {
    return this.http.get(
      `${environment.apiUrl}post-application/interview-slot-type?clg_id=${clg_id}&form_name=${form}&process_name=${process_name}&page=${pagination.page}&limit=${pagination.limit}`
    );
  }

  getAllSlotsByClg(clg_id) {
    return this.http.get(
      `${environment.apiUrl}post-application/interview-slot-booking/all-slots?clg_id=${clg_id}`
    );
  }

  getScoreProcess(clg_id, form_name) {
    return this.http.get(
      `${environment.apiUrl}post-application/candidate-evaluation?clg_id=${clg_id}&form_name=${form_name}`
    );
  }

  getAllScoreProcess(data) {
    return this.http.get(
      `${environment.apiUrl}post-application/candidate-evaluation?clg_id=${data.clg_id}&form_name=${data.form}&page=${data.pagination.page}&limit=${data.pagination.limit}`
    );
  }

  getScoreProcessById(id) {
    return this.http.get(`${environment.apiUrl}post-application/candidate-evaluation/${id}`);
  }

  addScoreProcess(data) {
    return this.http.post(`${environment.apiUrl}post-application/candidate-evaluation`, data);
  }

  updateScoreProcess(id, data) {
    return this.http.patch(
      `${environment.apiUrl}post-application/candidate-evaluation/${id}`,
      data
    );
  }

  bulkAssignPanel(data) {
    return this.http.patch(`${environment.apiUrl}pre-application/form-responses`, data);
  }

  getOfferletter(data) {
    return this.http.get(
      `${environment.apiUrl}post-application/offer-letter?clg_id=${data.clg_id}&form_name=${data.form}`
    );
  }

  addOfferletter(data) {
    return this.http.post(`${environment.apiUrl}post-application/offer-letter`, data);
  }

  updateOfferletter(id, data) {
    return this.http.patch(`${environment.apiUrl}post-application/offer-letter/${id}`, data);
  }

  getAllConfiguration(query: any) {
    let limit = query.limit ? query.limit : '';
    let page_number = query.page_number ? query.page_number : '';
    let clg_id = query.clg_id ? query.clg_id : '';
    return this.http.get(
      `${environment.apiUrl}pre-application/college-dynamic-fields-with-all-clg-list?limit=${limit}&page_number=${page_number}&clg_id=${clg_id}`
    );
  }

  addColumnViewApplicationManager(data) {
    return this.http.post(
      `${environment.apiUrl}/pre-application/application-manager-column-view`,
      data
    );
  }

  getColumnViewApplicationManager(clg_id, form_name) {
    // return this.http.get(
    //   `${environment.apiUrl}/pre-application/application-manager-column-view?clg_id=${clg_id}&form_name=${form_name}`
    // );
    return this.http.get(`${environment.apiUrl}pre-application/quick-views?tableName=application`);
  }

  deleteColumnViewApplicationManager(id) {
    return this.http.post(`${environment.apiUrl}/pre-application/quick-view`, {
      _id: id,
    });
  }

  getOnlineDetails(data) {
    return this.http.post(`${environment.apiUrl}pre-application/online-details`, data);
  }

  getOfflineDetails(data) {
    return this.http.post(`${environment.apiUrl}pre-application/offline-details`, data);
  }

  updateDocumentSatus(data, id) {
    return this.http.post(
      `${environment.apiUrl}pre-application/update-document-status/${id}`,
      data
    );
  }

  getAllQueriesCollegeWiseData(data) {
    return this.http.post(`${environment.apiUrl}pre-application/queriesData`, data);
  }
  getAllNotes(id: any) {
    return this.http.get(`${environment.apiUrl}/mg-get-all-lead-note?limit=10&page=1&leadId=${id}`);
  }

  getLeadDetails(data) {
    return this.http.post(`${environment.apiUrl}pre-application/details`, data);
  }
  updateLeadDetails(data, id) {
    return this.http.put(`${environment.apiUrl}pre-application/details-update/${id}`, data);
  }
  hideUnhideForm(data) {
    return this.http.post(`${environment.apiUrl}pre-application/hideUnhideForm`, data);
  }

  getDocumentDetails(clg_id, form_name) {
    return this.http.get(
      `${environment.apiUrl}pre-application/college-documents?clg_id=${clg_id}&form_name=${form_name}`
    );
  }

  getAllsmsTemplates() {
    return this.http.get(`${environment.apiUrl}sms/templates-with-sender-list`);
  }

  sendSmsLeads(data: any) {
    return this.http.post(`${environment.apiUrl}send-sms-to-lead`, data);
  }

  changeLeadStage(data: any) {
    return this.http.put(
      `${environment.apiUrl}pre-application/details/change-lead-stage/${data.id}`,
      data
    );
  }

  getImportReport(page, limit, importedBy, dateFrom, dateTo, importStatus, formType) {
    return this.http.get(
      `${environment.apiUrl}import-report-v2?page=${page}&limit=${limit}&importedBy=${importedBy}&dateFrom=${dateFrom}&dateTo=${dateTo}&import_status=${importStatus}&form=${formType}`
    );
  }

  saveFollowUp(data: any) {
    return this.http.post(`${environment.apiUrl}pre-application/followup`, data);
  }

  getUpcomingFollowUp(id: string) {
    return this.http.get(
      `${environment.apiUrl}pre-application/get-closest-followup?lead_id=${id} `
    );
  }

  // getAllCounsellors() {
  //   return this.http.get(`${environment.apiUrl}get_all_users/counsellor`);
  // }
  getAllCounsellors() {
    return this.http.get(
      `${environment.apiUrl}get_all_users?role=4&sdate=&edate=&export_acess=&is_leads_selection_enable=&location=&status=&ivrEnabled=&type=search&sortKey=user_times_at&sortOrder=-1&sortValue=Latest`
    );
  }

  selectAllLeads() {
    return this.http.post(`${environment.apiUrl}pre-application/form-responses-custom-v4`, {});
  }

  saveQuickView(data) {
    return this.http.post(`${environment.apiUrl}pre-application/save-quick-view`, data);
  }

  getAllDemoVideos() {
    return this.http.get(`${environment.apiUrl}getAllDemos`);
  }

  saveReassignLead(data: any) {
    return this.http.post(`${environment.apiUrl}pre-application/details/re-assign-lead`, data);
  }

  getAllEmailTemplates() {
    return this.http.get(`${environment.apiUrl}getAllEmailTempates?clgId=`);
  }

  sendBulkEmails(body: any) {
    return this.http.post(`${environment.apiUrl}send-email-to-lead`, body);
  }

  uploadOfflineDocs(id: string, data: any) {
    return this.http.post(`${environment.apiUrl}pre-application/upload-document/${id}`, data);
  }

  togglePaymentList(id: string, status: any) {
    return this.http.post(
      `${environment.apiUrl}pre-application/payment-manager/set-${status}/${id}
`,
      ''
    );
  }

  getPaymentHistory(id, form_name) {
    return this.http.get(
      `${environment.apiUrl}pre-application/payment-details?_id=${id}&form_name=${form_name}`
    );
  }
  getQueryTickets(
    id: string,
    form_name: string,
    TicketStatus: string,
    date: string | null,
    fromDate?: string,
    toDate?: string
  ) {
    let url = `${environment.apiUrl}pre-application/lead-queries/${id}?form_name=${form_name}&status=${TicketStatus}`;

    // If a custom date range is provided, append it to the URL
    if (fromDate && toDate) {
      url += `&fromDate=${fromDate}&toDate=${toDate}`;
    } else if (date) {
      // If using a predefined date range, include the date_filter parameter
      url += `&date_filter=${date}`;
    }

    return this.http.get(url);
  }

  getApplicationStages(body: any) {
    return this.http.post(`${environment.apiUrl}pre-application/details/all-stages`, {
      form_name: body.form_name,
    });
  }

  getFormWiseFields(name) {
    return this.http.post(`${environment.apiUrl}pre-application/form-fields`, { form_name: name });
  }

  getViewApplicant(userId: string, formId: string, clg_id: string) {
    return this.http.get(
      `${environment.apiUrl}pre-application/view-as-applicant?user_form_id=${userId}&form_name=${formId}&clg_id=${clg_id}`
    );
  }

  updateFormResponse(form_id, data) {
    return this.http.patch(`${environment.apiUrl}form-response/${form_id}`, data);
  }

  getQrCode(url: string) {
    return this.http.post(`${environment.apiUrl}pre-application/details/qr-generate`, { url });
  }
  downloadReciept(payment_id, lead_id) {
    return this.http.get(
      `${environment.apiUrl}pre-application/get-invoice?payment_id=${payment_id}&lead_id=${lead_id}`
    );
  }

  markPaymentFailed(id: string, body) {
    return this.http.put(
      `${environment.apiUrl}pre-application/create-offline-payment-failed-note/${id}`,
      body
    );
  }
  getPaymentNote(id: string) {
    return this.http.get(`${environment.apiUrl}pre-application/offline-payment-failed-note/${id}`);
  }

  getPackageDetails(plan: string) {
    return this.http.post(`${environment.apiUrl}pre-application/get-plan`, { plan_name: plan });
  }

  addIpAddress(body) {
    return this.http.patch(`${environment.apiUrl}pre-application/ip-configuration/add`, body);
  }

  removeIpAddress(body) {
    return this.http.patch(`${environment.apiUrl}pre-application/ip-configuration/remove`, body);
  }

  changeStatusInstitute(body, clg_id) {
    return this.http.put(`${environment.apiUrl}colleges/markStatus/${clg_id}`, body);
  }

  getMasterData(body) {
    return this.http.post(`${environment.apiUrl}pre-application/master-data/filter`, body);
  }
  createMasterData(body) {
    return this.http.post(`${environment.apiUrl}pre-application/master-data/upload/bulk`, body);
  }

  deleteMasterData(id) {
    return this.http.delete(`${environment.apiUrl}pre-application/master-data/delete/${id}`);
  }

  toggleMasterDataStatus(body) {
    return this.http.put(`${environment.apiUrl}pre-application/master-data/toggle/status`, body);
  }
  addValuesInMasterData(body) {
    return this.http.post(`${environment.apiUrl}pre-application/master-data/upload/single`, body);
  }
  changeMasterDataValueStatus(body) {
    return this.http.put(
      `${environment.apiUrl}pre-application/master-data/sub-level/toggle/status`,
      body
    );
  }
  deleteValueInMasterData(body) {
    return this.http.post(`${environment.apiUrl}pre-application/master-data/delete-level`, body);
  }

  getAllMasterData() {
    return this.http.get(`${environment.apiUrl}pre-application/get-all-master-data`);
  }

  toggleShowMasterData(body) {
    return this.http.put(
      `${environment.apiUrl}pre-application/master-data/sub-level/toggle/is-visible`,
      body
    );
    //   body{
    //     "data_id":"66d2ca0ec2f4a83b40f3b7bf",
    //     "level_id":"66d2ca0ec2f4a83b40f3b7b7"
    // }
  }
  setQuickViewAsDefault(body: any) {
    return this.http.patch(`${environment.apiUrl}pre-application/quick-view/mark-default`, body);
  }
  getformDetails(filter) {
    return this.http.post(`${environment.apiUrl}pre-application/details`, filter);
  }
  getAllFollowUpNotes(
    id: any,
    status = '',
    date = '',
    fromDate: string | null = null,
    toDate: string | null = null,
    custom: boolean = false
  ) {
    let queryParams: string[] = [`leadId=${encodeURIComponent(id)}`];

    if (status) {
      queryParams.push(`status=${encodeURIComponent(status)}`);
    }

    if (date && date !== 'custom') {
      queryParams.push(`followupDate=${encodeURIComponent(date)}`);
    }

    if (custom && fromDate && toDate) {
      queryParams.push(`from_date=${encodeURIComponent(fromDate)}`);
      queryParams.push(`to_date=${encodeURIComponent(toDate)}`);
      queryParams.push(`custom=true`);
    }

    const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';

    return this.http.get(
      `${environment.apiUrl}pre-application/lead-notes-and-followups${queryString}`
    );
  }

  markFollowUpComplete(data) {
    return this.http.put(`${environment.apiUrl}/pre-application/complete-followup`, data);
  }
  getLeadStages() {
    return this.http.get(`${environment.apiUrl}getLeadStages`);
  }
  changeApplicationStage(data: any) {
    console.log(data);
    return this.http.put(
      `${environment.apiUrl}pre-application/details/change-Application-stage/${data.id}`,
      data
    );
  }
  getImportedByList() {
    return this.http.get(`${environment.apiUrl}import-report/importedBy`);
  }
  getImportReportForms() {
    return this.http.get(`${environment.apiUrl}import-report/forms`);
  }
  sendBulkReply(data: any) {
    return this.http.post(`${environment.apiUrl}pre-application/bulk/query-message`, data);
  }
  getQueryCount() {
    return this.http.get(`${environment.apiUrl}pre-application/queries/count`);
  }
  getFilterQuery(data: any) {
    const searchQuery = data && data.search_query ? data.search_query : '';
    const page = data && data.pageNo ? data.pageNo : 1;
    const limit = data && data.rowsPerPage ? data.rowsPerPage : 10;
    const isPaymentDone = data && data.is_payment_done !== undefined ? data.is_payment_done : '';
    const queryStatus = data && data.filters && data.filters.status ? data.filters.status : '';
    const category = data && data.category ? data.category : '';
    const assignedTo = data && data.assignedTo ? data.assignedTo : '';
    const lastReplyBy =
      data && data.filters && data.filters.lastRepliedBy ? data.filters.lastRepliedBy : '';
    const updatedAtEnd = data && data.updatedBy_endDate ? data.updatedBy_endDate : '';
    const updatedAtStart = data && data.updatedBy_startDate ? data.updatedBy_startDate : '';
    const createdAtEnd = data && data.createdBy_endDate ? data.createdBy_endDate : '';
    const createdAtStart = data && data.createdBy_startDate ? data.createdBy_startDate : '';
    const formSelected = data && data.selectedForms ? data.selectedForms : '';
    const overallFeedback = data && data.feedback ? data.feedback : '';

    return this.http.get(
      `${environment.apiUrl}pre-application/queries?` +
        `search_query=${searchQuery}&` +
        `page=${page}&` +
        `limit=${limit}&` +
        `is_payment_done=${isPaymentDone}&` +
        `query_status=${queryStatus}&` +
        `category=${category}&` +
        `assigned_to=${assignedTo}&` +
        `last_reply_by=${lastReplyBy}&` +
        `updated_at_end=${updatedAtEnd}&` +
        `updated_at_start=${updatedAtStart}&` +
        `created_at_end=${createdAtEnd}&` +
        `created_at_start=${createdAtStart}&` +
        `form_selected=${formSelected}&` +
        `overall_feedback=${overallFeedback}`
    );
  }

  getAllQueries(data: any) {
    const searchQuery = data && data.search_query ? data.search_query : '';
    const isPaymentDone = data && data.is_payment_done !== undefined ? data.is_payment_done : '';
    const queryStatus = data && data.filters && data.filters.status ? data.filters.status : '';
    const category = data && data.category ? data.category : '';
    const assignedTo = data && data.assignedTo ? data.assignedTo : '';
    const lastReplyBy =
      data && data.filters && data.filters.lastRepliedBy ? data.filters.lastRepliedBy : '';
    const updatedAtEnd = data && data.updatedBy_endDate ? data.updatedBy_endDate : '';
    const updatedAtStart = data && data.updatedBy_startDate ? data.updatedBy_startDate : '';
    const createdAtEnd = data && data.createdBy_endDate ? data.createdBy_endDate : '';
    const createdAtStart = data && data.createdBy_startDate ? data.createdBy_startDate : '';
    const formSelected = data && data.selectedForms ? data.selectedForms : '';
    const overallFeedback = data && data.feedback ? data.feedback : '';

    return this.http.get(
      `${environment.apiUrl}pre-application/queries?` +
        `search_query=${searchQuery}&` +
        `is_payment_done=${isPaymentDone}&` +
        `query_status=${queryStatus}&` +
        `category=${category}&` +
        `assigned_to=${assignedTo}&` +
        `last_reply_by=${lastReplyBy}&` +
        `updated_at_end=${updatedAtEnd}&` +
        `updated_at_start=${updatedAtStart}&` +
        `created_at_end=${createdAtEnd}&` +
        `created_at_start=${createdAtStart}&` +
        `form_selected=${formSelected}&` +
        `overall_feedback=${overallFeedback}`
    );
  }

  reassignQuery(data: any) {
    return this.http.put(`${environment.apiUrl}pre-application/re-assign-query`, data);
  }
  updateToggleState(id: string, status: boolean) {
    return this.http.patch(
      `${environment.apiUrl}pre-application/queries/toggle/${id}/${status}`,
      null
    );
  }
  changeQueryStatus(id: any, status: string) {
    return this.http.patch(`${environment.apiUrl}pre-application/queries/${id}`, {
      status: status,
    });
  }

  changeConversationStatus(id: string, status: string) {
    return this.http.patch(
      `${environment.apiUrl}pre-application/queries/change-status-chat/${id}`,
      {
        status: status,
      }
    );
  }
  reOpenQuery(id: any) {
    return this.http.patch(`${environment.apiUrl}pre-application/queries/${id}`, {
      status: 'in progress',
    });
  }

  getUserFormData(id) {
    return this.http.get(`${environment.apiUrl}pre-application/id/${id}`);
  }

  getCourses() {
    return this.http.get(`${environment.apiUrl}pre-application/getCourses?clg_id=10`);
  }
}
