<div id="divLoading" class="divLoading" *ngIf="loading">
    <p><img src="assets/images/loader1.gif" /></p>
  </div>
  <div id="page-wrapper" class="gray-bg dashbard-1">
    <div class="content-main">
      <div class="blank">
        <h2 class="fs18 fsm16 fw700 flex-center">
          <a class="flex-center" routerLink="/dialer-list">
            <svg class="back-arrow">
              <use href="assets/images/icon.svg#back" />
            </svg>
          </a>
          Dialer Configuration
        </h2>
        <form [formGroup]="ruleForm" (ngSubmit)="onSubmitRule()">
          <div class="graph-card">
            <div class="row display-flex flex-wrap normal-form">
              <div class="form-group col-sm-4 col-xs-12">
                <h3>Name<sup>*</sup></h3>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter name"
                  formControlName="name" />
              </div>
              <div class="form-group col-sm-3 col-xs-12">
                <h3>Trigger Event<sup>*</sup></h3>
                <select
                  class="form-control"
                  (change)='onOptionsSelectedChange($event, "event")'
                  formControlName="triggerType">
                  <option value="">Select Trigger</option>
                  <option value="update">On Updated</option>
                  <option value="create">On Created</option>
                </select>
              </div>
            </div>
          </div>
          <div class="display-flex justify-space-between margin-t30 mr-t30 flex-wrap">
            <div class="display-flex">
            </div>
          </div>
          <div class="tab-content">
            <div class="tab-pane fade active in">
              <!-- <div class="graph-card"> -->
              <div class="normal-form flex-center justify-content-center mt-15">
                <!-- <div class="form-group"> -->
                <div class="btn-container flex-center margin-r15 mr-r15">
                  <label class="switch toogle-btn">
                    <input
                      type="checkbox"
                      name="switch-mode"
                      id="switch-mode"
                      value="1"
                      (change)="eventGlobleCheckBox($event)" />
                    <label
                      for="switch-mode"
                      data-on="AND"
                      data-off="OR"
                      class="toogle-btn-inner"></label>
                  </label>
                </div>
                <!-- </div> -->
                <!-- <div class="form-group col-sm-6 col-xs-6 row justify-content-start"> -->
                <div class="flex-center justify-content-end add-upload-btn" (click)="addIfLoopFn()">
                  <a class="btn flex-center">
                    <svg class="dash-icons mr-r5 margin-r5">
                      <use href="assets/images/icon.svg#plus" />
                    </svg>
                    Add Condition Block</a
                  >
                </div>
                <!-- </div> -->
              </div>
              <!-- </div> -->
  
              <ng-container formArrayName="rule">
                <ng-container *ngFor="let oneLead of ifLoopAry; index as i">
                  <div class="margin-t20 mr-t20 text-center" *ngIf="i > 0">
                    <p class="text-btn btn-blue">{{globleCondtion}}</p>
                  </div>
                  <div class="graph-card" [formGroupName]="i">
                    <div class="row flex-center flex-wrap normal-form">
                      <div class="form-group col-sm-12 col-xs-12">
                        <div class="btn-container">
                          <label class="switch toogle-btn">
                            <input
                              type="checkbox"
                              name="switch-mode"
                              id="switch-mode"
                              value="1"
                              (change)="eventInnerCheckBox($event, i)" />
                            <label
                              for="switch-mode"
                              data-on="AND"
                              data-off="OR"
                              class="toogle-btn-inner"></label>
                          </label>
                        </div>
                      </div>
                      <ng-container formArrayName="rules">
                        <ng-container *ngFor="let oneLeadInner of oneLead.rules;   index as j">
                          <ng-container [formGroupName]="j">
                            <div class="form-group col-sm-12 col-xs-12" *ngIf="j > 0">
                              <p class="text-btn btn-blue">{{oneLead.condition}}</p>
                            </div>
                            <div class="form-group col-sm-3 col-xs-12">
                              <select
                                name=""
                                id=""
                                class="form-control"
                                formControlName="columnName"
                                (change)='onOptionsSelected(i , j, $event, "columnName")'>
                                <option value="">Select Condition</option>
                                <option value="prmary_source">Source</option>
                                <option value="secondary_source">Secondary Source</option>
                                <option value="tertiary_source">Tertiary Source</option>
                                <option value="utm_medium">UTM Medium</option>
                                <option value="utm_source">UTM Source</option>
                                <option value="utm_campaign">UTM Campaign</option>
                                <option value="state">State</option>
                                <option value="city">City</option>
                                <option value="last_callaction_btn_message">Lead Recent Stage</option>
                                <option value="course">{{relatedEntityCourse}}</option>
                                <option value="priority">Lead Priority</option>
                                <!-- <option value="user_id">Lead Owner</option> -->
                                <option value="clg_id">{{EntityName}}</option>
                                <option value="lead_type">Lead Type</option>
                                <option value="last_education">Last Education</option>
                                <option value="last_callaction_call_date_time">Followup Date</option>
                                <option value="is_verified">Verification Status</option>
                                <option value="gender">Gender</option>
                                <option value="category">Category</option>
                                <option value="stream">Stream</option>
                                <option value="year_of_admission">Year of Admission</option>
                                <option value="last_callaction_sub_stage">Lead Sub-Stage</option>
                                <option value="last_callaction_followup_status">
                                  Followup Status
                                </option>
                              </select>
                            </div>
  
                            <div class="form-group col-sm-3 col-xs-12">
                              <select
                                name=""
                                id=""
                                class="form-control"
                                formControlName="operator"
                                (change)='onOptionsSelected(i , j, $event, "operator")'>
                                <option value="">Select Operator</option>
                                <ng-container
                                  *ngIf="oneLeadInner.columnName === 'last_callaction_call_date_time'">
                                  <option value="<">Before</option>
                                  <option value=">">After</option>
                                  <option value="between">Between</option>
                                </ng-container>
                                <ng-container
                                  *ngIf="oneLeadInner.columnName !== 'last_callaction_call_date_time'">
                                  <option value="in">Include</option>
                                  <option value="NOT IN">Exclude</option>
                                </ng-container>
                              </select>
                            </div>
                            <div class="form-group custom-ng-container col-sm-3 col-xs-12">
                              <ng-select
                                (change)='onOptionsSelected(i , j, $event, "value")'
                                placeholder="Please type here"
                                [multiple]="true"
                                [closeOnSelect]="false"
                                [ngClass]="(oneLeadInner.changePre !== oneLeadInner.columnName )? 'hideTxt' : 'showTxt'"
                                [clearable]="false"
                                *ngIf="oneLeadInner.columnName !== 'last_callaction_call_date_time'"
                                [items]="oneLeadInner.valueAry"
                                [bindLabel]="'key'"
                                bindValue="value"
                                clearAllText="Clear"
                                formControlName="value">
                                <!-- <ng-option *ngFor="let prf of oneLeadInner.valueAry" [value]="prf.value">
                                                  {{prf.key}}</ng-option>  [ngModel]="item$.selected"-->
                                <ng-template
                                  ng-option-tmp
                                  let-item="item"
                                  let-item$="item$"
                                  let-index="index">
                                  <input
                                    id="item-{{index}}"
                                    type="checkbox"
                                    [checked]="item$.selected" /><span title="{{item.key}}">
                                    {{item.key}}</span
                                  >
                                </ng-template>
                              </ng-select>
                              <input
                                type="date"
                                class="form-control"
                                placeholder="select date"
                                *ngIf="oneLeadInner.operator === '<' || oneLeadInner.operator === '>'"
                                (change)='onOptionsSelected(i , j, $event, "value", true)'
                                formControlName="value" />
                              <input
                                *ngIf="oneLeadInner.operator === 'between'"
                                type="text"
                                class="form-control dateTimePicker"
                                ngxDaterangepickerMd
                                [showCustomRangeLabel]="true"
                                [alwaysShowCalendars]="true"
                                [ranges]="ranges"
                                open="center"
                                keepCalendarOpeningWithRange="true"
                                [isInvalidDate]="isInvalidDate"
                                [showClearButton]="true"
                                (change)="onOptionsSelected(i , j, $event, 'value', true)"
                                placeholder="Select Date..."
                                formControlName="value" />
                              <!-- <input type="text" class="form-control" placeholder="Enter city name"
                                              (blur)="onOptionsSelected(i , j, $event, 'value')"
                                              *ngIf="oneLeadInner.columnName === 'city'" /> -->
  
                              <!-- <ng-multiselect-dropdown [placeholder]="'Select Value'"
                                                      [data]="oneLeadInner.valueAry" [settings]="dropdownGapSettings"
                                       (onSelect)='onOptionsSelected(i , j, $event, "value")'
                                       >
                                       </ng-multiselect-dropdown> -->
                            </div>
                            <div class="form-group col-sm-3 col-xs-12">
                              <div class="flex-center user-tab justify-content-center margin-t15">
                                <a (click)="addIfInnerLoopFn(i,j)">
                                  <svg class="dash-icons">
                                    <use href="assets/images/icon.svg#plus" />
                                  </svg>
                                </a>
                                <a (click)="removeIfInnerLoopFn(i,j)">
                                  <svg class="dash-icons">
                                    <use href="assets/images/icon.svg#close-btn" />
                                  </svg>
                                </a>
                              </div>
                            </div>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <div class="graph-card">
                <div class="row flex-center flex-wrap normal-form">
                  <div class="form-group col-sm-3 col-xs-12">
                    <h3>Lead List<sup>*</sup></h3>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Lead List'"
                      [settings]="listSettings"
                      [data]="sampleList"
                      [(ngModel)]="list"
                      [ngModelOptions]="{standalone: true}"
                      (onSelect)="onItemSelect($event)"
                      >
                    </ng-multiselect-dropdown>
                  </div>
                </div>
                <div class="row flex-center flex-wrap normal-form">
                  <div class="form-group col-sm-3 col-xs-12">
                    <h3>Priority</h3>
                     <input class="form-control" [(ngModel)] = "priority" type="number" [ngModelOptions]="{standalone: true}"/>
                  </div>
                </div>
              </div>
              <div class="flex-center justify-content-end gap-10 margin-t30 mr-t30">
                <button class="btn btn-blue fs14 fw700" (click)="addData()">{{text}} Rule</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  