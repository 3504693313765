import { Component, OnInit } from '@angular/core';
import { SettingService } from 'src/app/_services/setting.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {

  constructor(private settingService : SettingService) { }

  ngOnInit(): void {
    this.getList()
  }
  list = [];
  loading = false
  dataSourceCount = 0;
  filters = {
    page_number: 1,
    limit: 10,
    clgId: [],
  };

  getList() {
    this.loading = true
    this.settingService.getDialerConfig(this.filters).subscribe((res:any) => {
      this.list = res.data.data;
      this.dataSourceCount = res.data.count;
      this.loading = false
console.log(res)
    },error=>{

    })
  }


  loadPaginationData(data) {
    (this.filters.page_number = data.currentPage),
      (this.filters.limit = data.itemsPerPage),
      this.getList();
  }

}
