<div id="wrapper">
  <app-header *ngIf="currentUser"></app-header>
  <app-sidebar *ngIf="currentUser"></app-sidebar>
  <router-outlet></router-outlet>
</div>
<app-footer *ngIf="currentUser"></app-footer>

<div class="modal fade static-modal" tabindex="-1" id="breakModal" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="fs16 fw700">Break Reason</h2>
        <a class="close-btn" data-dismiss="modal" aria-label="Close">
          <svg class="dash-icons">
            <use href="assets/images/icon.svg#close-btn" />
          </svg>
        </a>
      </div>
      <div class="modal-body text-center">
        <div class="form-group">
          <select name="" id="" class="form-control">
            <option value="">Select</option>
            <option value="">Manual Call</option>
            <option value="">Customer Counselling</option>
            <option value="">Lunch Break</option>
            <option value="">Tea / Coffee Break</option>
          </select>
        </div>
        <div class="form-group form-btn">
          <button class="btn btn-blue">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>
