<div id="divLoading" class="divLoading" *ngIf="loading">
  <p><img src="assets/images/loader1.gif" /></p>
</div>
<!-- Add-lead modal HTML -->
<div
  class="modal right-popup"
  id="studentModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="studentModalLabel"
  aria-hidden="true"
  #studentModal>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <a class="close-btn" data-dismiss="modal" aria-label="Close">
        <svg class="dash-icons">
          <use href="assets/images/icon.svg#close-btn" />
        </svg>
      </a>
      <form class="student-form" [formGroup]="leadForm" (ngSubmit)="onSubmitLeads()">
        <h2>Add New Lead</h2>
        <div class="add-lead-form">
          <div class="form-group">
            <h3 for="name">Name<sup>*</sup></h3>
            <input
              type="text"
              name="name"
              id="name"
              class="form-control"
              required="required"
              placeholder="Please enter prospect name"
              formControlName="name"
              [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
            <div *ngIf="submitted && f.name.errors" class="invalid-fields">
              <div *ngIf="f.name.errors.required">Name is required</div>
            </div>
            <div *ngIf="submitted && f.name.errors" class="invalid-fields">
              <div *ngIf="f.name.errors.maxlength">Max length 30 characters</div>
            </div>
            <div *ngIf="submitted && f.name.errors" class="invalid-fields">
              <div *ngIf="f.name.errors.pattern">Invalid Name or Enter alphabet only</div>
            </div>
          </div>
          <div class="form-group">
            <h3 for="email">Email<sup>*</sup></h3>
            <input
              type="text"
              name="email"
              id="email"
              class="form-control"
              required="required"
              placeholder="Please enter prospect email"
              formControlName="email"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <div *ngIf="submitted && f.email.errors" class="invalid-fields">
              <div *ngIf="f.email.errors.required">Email is required</div>
            </div>
            <div *ngIf="submitted && f.email.errors" class="invalid-fields">
              <div *ngIf="f.email.errors.pattern">Invalid email</div>
            </div>
          </div>
          <div class="form-group">
            <h3 for="college">Select {{EntityName}}<sup>*</sup></h3>
            <ng-select [placeholder]="'Search/Select '+EntityName" formControlName="college">
              <ng-option *ngFor="let item of partnerColleges" [value]="item.clg_id">
                {{item.clg_name}}
              </ng-option>
            </ng-select>
            <div *ngIf="submitted && f.college.errors" class="invalid-fields">
              <div *ngIf="f.college.errors.required">{{EntityName}} is required</div>
            </div>
          </div>
          <div class="form-group">
            <h3 for="country">Country Code</h3>
            <select
              formControlName="country"
              [ngClass]="{ 'is-invalid': submitted && f.country.errors }">
              <option [value]="101">+91, India</option>
            </select>
            <div *ngIf="submitted && f.country.errors" class="invalid-fields">
              <div *ngIf="f.country.errors.required">Country code is required</div>
            </div>
          </div>
          <div class="form-group">
            <h3 for="phone">Phone Number<sup>*</sup></h3>
            <input
              type="text"
              name="phone"
              id="inputNamePhone"
              class="form-control"
              required="required"
              placeholder="Please enter your 10 digits valid phone number"
              formControlName="phone"
              [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
            <div *ngIf="submitted && f.phone.errors" class="invalid-fields">
              <div *ngIf="f.phone.errors.required">Phone number is required</div>
              <div *ngIf="f.phone.errors.minlength">Phone number must be at least 10 digit</div>
              <div *ngIf="f.phone.errors.pattern">Phone number is invalid</div>
            </div>
          </div>
          <div class="form-group">
            <h3 for="alt_phone">Alternate Phone Number</h3>
            <input
              type="text"
              name="phone"
              id="inputNameAltPhone"
              class="form-control"
              placeholder="Please enter your 10 digits valid phone number"
              formControlName="alt_phone"
              [ngClass]="{ 'is-invalid': submitted && f.alt_phone.errors }" />
            <div *ngIf="submitted && f.alt_phone.errors" class="invalid-fields">
              <div *ngIf="f.alt_phone.errors.minlength">
                Alt. Phone number must be at least 10 characters
              </div>
              <div *ngIf="f.alt_phone.errors.pattern">Alt. Phone number is invalid</div>
              <div *ngIf="f.alt_phone.errors.mustMatch">Phone & Alt. Phone can not be same</div>
            </div>
          </div>
          <div class="form-group">
            <h3 for="state">State<sup>*</sup></h3>
            <ng-select
              formControlName="state"
              placeholder="Search/Select State"
              [searchable]="true">
              <ng-option *ngFor="let state of states" [value]="state.value">
                {{ state.value }}
              </ng-option>
            </ng-select>
            <div *ngIf="submitted && f.state.errors" class="invalid-fields">
              <div *ngIf="f.state.errors.required">State is required</div>
            </div>
          </div>
          <div class="form-group">
            <h3 for="stream">Preferred Stream<sup>*</sup></h3>
            <ng-select
              formControlName="stream"
              placeholder="Search/Select Preferred Stream"
              [searchable]="true">
              <ng-option *ngFor="let stream of streams;" [value]="stream.value">
                {{stream.name}}
              </ng-option>
            </ng-select>
            <div *ngIf="submitted && f.stream.errors" class="invalid-fields">
              <div *ngIf="f.stream.errors.required">Preferred stream is required</div>
            </div>
          </div>
          <div class="form-group">
            <h3 for="year">Year of Admission</h3>
            <select formControlName="year" [ngClass]="{ 'is-invalid': submitted && f.year.errors }">
              <option value="" selected>Select Year of Admission</option>
              <option [value]="years">{{years}}</option>
              <option [value]="years+1">{{years+1}}</option>
            </select>
          </div>
          <div class="form-group">
            <h3 for="source">Lead Source<sup>*</sup></h3>
            <ng-select
              formControlName="source"
              placeholder="Search/Select Lead Source"
              [searchable]="true">
              <ng-option *ngFor="let source_name of sourceArray;" [value]="source_name.source">
                {{source_name.source}}
              </ng-option>
            </ng-select>
            <div *ngIf="submitted && f.source.errors" class="invalid-fields">
              <div *ngIf="f.source.errors.required">Lead Source is required</div>
            </div>
          </div>
          <div class="form-group" *ngIf="usersList.length">
            <h3 for="college">Assign To Counsellor</h3>
            <!-- <ng-multiselect-dropdown [placeholder]="'Select User'" [data]="usersList"
							[(ngModel)]="selectedUserItems" [settings]="userSettings" (onSelect)="onUserSelect($event)"
							formControlName="users">
						</ng-multiselect-dropdown> -->
            <ng-select
              formControlName="users"
              placeholder="Select Assign To User"
              [searchable]="true">
              <ng-option *ngFor="let item of usersList" value="{{item.id}}"
                >{{item.name}}
              </ng-option>
            </ng-select>
          </div>
          <!-- reffer input fields -->
          <div class="form-group">
            <h3 for="name">Referrer Name</h3>
            <input
              type="text"
              name="refer_name"
              id="refer_name"
              class="form-control"
              placeholder="Please enter referrer name"
              formControlName="refer_user_name" />
          </div>
          <div class="form-group">
            <h3 for="name">Referrer Email</h3>
            <input
              type="text"
              name="refer_email"
              id="refer_email"
              class="form-control"
              placeholder="Please enter referrer email"
              formControlName="refer_user_email" />
          </div>
          <div class="form-group">
            <h3 for="name">Referrer Phone</h3>
            <input
              type="text"
              name="refer_phone"
              id="refer_phone"
              class="form-control"
              placeholder="Please enter referrer phone"
              formControlName="refer_user_phone" />
          </div>
          <!--  reffer input fields end -->
          <!-- send mail -->
          <div class="form-group">
            <label class="fs14 flex-center gap-10">
              <input
                type="checkbox"
                id="sendLead"
                (change)="leadVerification($event)"
                fromControlName="lead_verification_mail" />
              Want to send lead verification mail?
            </label>
          </div>
          <ng-container *ngFor="let one of customFields;">
            <div class="form-group">
              <h3 for="one.field_name">
                {{one.field_name}}
                <sup *ngIf="one.is_required === 1">*</sup>
              </h3>
              <ng-container
                *ngIf="one.field_type === 'text' || one.field_type === 'email' 
							|| one.field_type === 'url' || one.field_type === 'number'">
                <input
                  type="{{one.field_type}}"
                  name="{{one.key_name}}"
                  id="{{one.key_name}}"
                  class="form-control"
                  placeholder="{{one.field_placeholder}}"
                  formControlName="{{one.key_name}}" />
              </ng-container>
              <ng-container *ngIf="one.field_type === 'date'">
                <input
                  type="date"
                  name="{{one.key_name}}"
                  id="{{one.key_name}}"
                  class="form-control"
                  placeholder="{{one.field_placeholder}}"
                  formControlName="{{one.key_name}}" />
              </ng-container>
              <ng-container *ngIf="one.field_type === 'select'">
                <select name="{{one.key_name}}" formControlName="{{one.key_name}}">
                  <ng-container *ngFor="let o of one.option;">
                    <option value="{{o}}">{{o}}</option>
                  </ng-container>
                </select>
              </ng-container>
              <ng-container *ngIf="one.field_type === 'radio'">
                <ng-container *ngFor="let o of one.option;">
                  <input
                    name="{{one.key_name}}"
                    type="radio"
                    formControlName="{{one.key_name}}"
                    value="{{o}}" />
                  <label>{{o}}</label><br />
                </ng-container>
              </ng-container>

              <div *ngIf="submitted && f[one.key_name].errors" class="invalid-fields">
                <div *ngIf="f[one.key_name].errors.required">{{one.field_name}} is required.</div>
              </div>
              <div *ngIf="submitted && f[one.key_name].errors" class="invalid-fields">
                <div *ngIf="f[one.key_name].errors.pattern">Invalid {{one.key_name}}</div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="form-btn">
          <button type="submit" class="btn btn-blue margin-r10 mr-r10">{{submitBtn}}</button>
          <button
            type="button"
            class="btn btn-orange"
            data-dismiss="modal"
            id="closeAddNewLeadModal"
            (click)="resetForm()">
            Close
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
