import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { TenantService } from 'src/app/_services/tenant.service';
import { leadData } from '../../../jsonData/lead';
import * as JsonAppPermissionList from '../../../jsonData/app_permission_list';
import { LeadSearchService } from 'src/app/_services/lead-search.service';

@Component({
  selector: 'app-tenant-profile',
  templateUrl: './tenant-profile.component.html',
  styleUrls: ['./tenant-profile.component.css'],
})
export class TenantProfileComponent implements OnInit {
  constructor(
    private TenantService: TenantService,
    private LeadSearchService: LeadSearchService,
    private Route: ActivatedRoute,
    private ToasterService: ToastrService,
    private fb: FormBuilder
  ) {}
  persistUser: any = {};
  TenantId;
  TenantDetail: any = [{ description: '' }];
  is_loading = true;
  editTenant: FormGroup;
  states = [];
  cities = [];
  currentUser = {};
  permissionList = [];
  uploadedFile = '';
  logoImageSrc: string;
  hostname = '';
  change_logo = false;
  dropdownSettings = {};
  ngOnInit() {
    this.hostname = window.location.protocol + '//' + window.location.host + '/public';
    // this.hostname = window.location.protocol + "//" + "dev-cc.automateazy.com" + "/public";
    this.Route.params.subscribe(res => {
      this.TenantId = res.id;
    });
    this.persistUser = JSON.parse(localStorage.getItem('persist'));
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.getTenantProfile();
    this.states = leadData.stateArray;
    this.permissionList = JsonAppPermissionList.permissionArray;
    this.editTenant = this.fb.group({
      name: ['', Validators.required],
      short_name: ['', Validators.required],
      description: ['', Validators.required],
      estd_year: ['', Validators.required],
      phone: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
      support_call: [''],
      support_email: ['', Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
      address1: ['', Validators.required],
      address2: ['', ''],
      state: ['', Validators.required],
      city: ['', Validators.required],
      pincode: ['', ''],
      website: ['', ''],
      twitter: ['', ''],
      facebook: ['', ''],
      instagram: ['', ''],
      youtube: ['', ''],
      app_latest_version: ['', ''],
      app_latest_version_code: ['', ''],
      app_download_link: ['', ''],
      app_released_on: [''],
      app_required_os: ['', ''],
      app_min_permission: [],
    });
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'permission_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
  }

  onItemSelect(item: any) {
    console.log('onItemSelect', item);
  }
  getTenantProfile() {
    try {
      this.is_loading = true;
      this.TenantService.getTenantProfileById(this.TenantId).subscribe((res: any) => {
        if (res && res.code === 200) {
          this.TenantDetail = res.data[0];
          console.log('this.TenantDetail:', this.TenantDetail);
          this.is_loading = false;
        } else if (res.code === 404) {
          this.ToasterService.warning(res.message);
          this.is_loading = false;
        } else if (res.code === 500) {
          this.ToasterService.warning('Server Error');
          this.is_loading = false;
        } else {
          this.ToasterService.warning('Some Error');
          this.is_loading = false;
        }
      });
    } catch (Error) {
      this.ToasterService.warning('Some Error');
      this.is_loading = false;
    }
  }

  patchValue() {
    const appReleasedOnISO = moment(new Date(this.TenantDetail['app_released_on']))
      .add(330, 'minutes')
      .toISOString()
      .substring(0, 16);
    // let activePermissionIds = this.TenantDetail['app_min_permission'].split(',');
    // activePermissionIds = activePermissionIds.map(function(str) { return parseInt(str); });
    // const activeAppPermissionList = this.permissionList.filter(({id}) => activePermissionIds.includes(id))
    this.editTenant.patchValue({
      name: this.TenantDetail['name'],
      short_name: this.TenantDetail['short_name'],
      description: this.TenantDetail['description'],
      estd_year: this.TenantDetail['estd_year'],
      phone: this.TenantDetail['phone'],
      support_call: this.TenantDetail['support_call'],
      support_email: this.TenantDetail['support_email'],
      address1: this.TenantDetail['address1'],
      address2: this.TenantDetail['address2'],
      state: this.TenantDetail['state'],
      city: this.TenantDetail['city'],
      pincode: this.TenantDetail['pincode'],
      website: this.TenantDetail['website'],
      twitter: this.TenantDetail['twitter'],
      facebook: this.TenantDetail['facebook'],
      instagram: this.TenantDetail['instagram'],
      youtube: this.TenantDetail['youtube'],
      app_latest_version: this.TenantDetail['app_latest_version'],
      app_latest_version_code: this.TenantDetail['app_latest_version_code'],
      app_download_link: this.TenantDetail['app_download_link'],
      app_released_on: appReleasedOnISO,
      app_required_os: this.TenantDetail['app_required_os'],
      app_min_permission: JSON.parse(this.TenantDetail['app_min_permission']),
    });
    this.logoImageSrc = this.hostname + this.TenantDetail['logo_image'];
    this.change_logo = false;
    this.uploadedFile = '';
  }

  get f() {
    return this.editTenant.controls;
  }

  onSubmit() {
    if (this.editTenant.invalid) {
      return;
    }
    this.is_loading = true;
    const fb = new FormData();
    fb.append('name', this.editTenant.value.name);
    fb.append('short_name', this.editTenant.value.short_name);
    fb.append('description', this.editTenant.value.description);
    fb.append('estd_year', this.editTenant.value.estd_year);
    fb.append('phone', this.editTenant.value.phone);
    fb.append('support_call', this.editTenant.value.support_call);
    fb.append('support_email', this.editTenant.value.support_email);
    fb.append('address1', this.editTenant.value.address1);
    fb.append('address2', this.editTenant.value.address2);
    fb.append('state', this.editTenant.value.state);
    fb.append('city', this.editTenant.value.city);
    fb.append('pincode', this.editTenant.value.pincode);
    fb.append('website', this.editTenant.value.website);
    fb.append('twitter', this.editTenant.value.twitter);
    fb.append('facebook', this.editTenant.value.facebook);
    fb.append('instagram', this.editTenant.value.instagram);
    fb.append('youtube', this.editTenant.value.youtube);
    fb.append('app_latest_version', this.editTenant.value.app_latest_version);
    fb.append('app_latest_version_code', this.editTenant.value.app_latest_version_code);
    fb.append('app_released_on', this.editTenant.value.app_released_on);
    fb.append('app_download_link', this.editTenant.value.app_download_link);
    fb.append('app_required_os', this.editTenant.value.app_required_os);
    fb.append('app_min_permission', JSON.stringify(this.editTenant.value.app_min_permission));
    if (this.uploadedFile != '') {
      fb.append('logo_image', this.uploadedFile);
    }

    this.TenantService.EditTenantProfile(this.TenantId, fb).subscribe(
      (res: any) => {
        if (res && res.code === 200) {
          document.getElementById('closeEditPopup').click();
          this.getTenantProfile();
          this.is_loading = false;
          this.ToasterService.success(`${res.message}`);
        } else if (res.code === 204) {
          this.is_loading = false;
          this.ToasterService.success(`${res.message}`);
        } else if (res.code === 500) {
          this.is_loading = false;
          this.ToasterService.success(`${res.message}`);
        }
      },
      error => {
        console.log('Error ====>', error);
        this.ToasterService.warning(error);
      }
    );
  }

  fileUpload(event) {
    console.log(event);
    if (event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = e => {
        this.uploadedFile = event.target.files[0];
        this.logoImageSrc = reader.result as string;
        console.log(this.uploadedFile);
      };
    }
  }
  changeLogo() {
    this.change_logo = true;
  }

  changeState(e) {
    if (e) {
      const stateId = this.getStateIdFromValue(e);
      this.getCitiesFromStateId(stateId);
    }
  }

  getCitiesFromStateId(stateId) {
    this.is_loading = true;
    this.LeadSearchService.getCitiesFromStateId(stateId).subscribe(
      (res: any) => {
        if (res.code == 200) {
          this.cities = res.data;
        } else {
          this.cities = [];
        }
        this.is_loading = false;
      },
      error => {
        console.log('Error ====>', error);
        this.is_loading = false;
      }
    );
  }

  getStateIdFromValue(value) {
    for (let state of this.states) {
      if (state.value === value) {
        return state.id;
      }
    }
    return null; // or throw an error if the value is not found
  }
}
