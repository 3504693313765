import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SettingService {
  constructor(private http: HttpClient) {}
  getSmsTemplate() {
    return this.http.get(`${environment.apiUrl}getsmstemplate`);
  }
  savesmstemplate(obj) {
    let name = obj.name ? obj.name : '';
    let body = obj.body ? obj.body : '';
    let status = obj.status ? obj.status : '';
    let str = obj.body.replace('\n', '\n');
    return this.http.get(
      `${environment.apiUrl}savesmstemplate?name=${name}&body=${JSON.stringify(
        str
      )}&status=${status}`
    );
  }
  sendsmstest(obj, request) {
    let phone = request.phone ? request.phone : '';
    let str = obj.body.replace('\n', '\n');
    return this.http.get(
      `${environment.apiUrl}sendsmstest?body=${JSON.stringify(str)}&phone=${phone}`
    );
  }
  getsmstemp(id) {
    return this.http.get(`${environment.apiUrl}getsmstemplatebyid?id=${id}`);
  }
  updateSmsTemplate(obj, id) {
    let name = obj.name ? obj.name : '';
    let status = obj.status ? obj.status : '';
    let str = obj.body.replace('\n', '\n');
    return this.http.get(
      `${environment.apiUrl}updatesmstemplate?name=${name}&body=${JSON.stringify(
        str
      )}&status=${status}&id=${id}`
    );
  }
  AddAssignmentRule(obj) {
    return this.http.post(`${environment.apiUrl}add/assignment/rule`, obj);
  }
  getassignmentrules() {
    return this.http.get(`${environment.apiUrl}get/all/rules`);
  }
  getassignmentrulesbyid(id) {
    return this.http.get(`${environment.apiUrl}get/rule/${id}`);
  }
  updateRule(id, data) {
    return this.http.post(`${environment.apiUrl}update/assignment/rule/${id}`, data);
  }
  deleteRule(id) {
    return this.http.delete(`${environment.apiUrl}delete/rule/${id}`);
  }
  getWhtspTemplate(filters) {
    return this.http.get(`${environment.apiUrl}get-all-whtsp-template`, { params: filters });
  }
  saveWhtsTemplate(obj) {
    JSON.stringify(obj.template_body.replace('\n', '\n'));
    return this.http.post(`${environment.apiUrl}save-whtsp-template`, obj);
  }
  getWhtspTempById(id) {
    return this.http.get(`${environment.apiUrl}get-whtsp-template/${id}`);
  }
  updateWhtsTemplate(obj, id) {
    JSON.stringify(obj.template_body.replace('\n', '\n'));
    return this.http.post(`${environment.apiUrl}update-whtsp-template/${id}`, obj);
  }
  deleteWhtspTemplate(id) {
    return this.http.delete(`${environment.apiUrl}delete-whtsp-template/${id}`);
  }
  getWhtspTemplateForSend(filters) {
    return this.http.get(`${environment.apiUrl}get-all-active-whtsp-template`, { params: filters });
  }
  getDistinctWhtspTemplate() {
    return this.http.get(`${environment.apiUrl}get-unique-template-name`);
  }
  getWhtspTempByName(id) {
    return this.http.get(`${environment.apiUrl}get-whtsp-template-by-id?tempId=${id}`);
  }
  saveCampaignTemplate(obj) {
    return this.http.post(`${environment.apiUrl}create/campaign/template`, obj);
  }
  getAllCampaignTemplte() {
    return this.http.get(`${environment.apiUrl}get-all-campaign-templtes`);
  }
  deleteCampaignTemplate(id) {
    return this.http.delete(`${environment.apiUrl}get-campaign-templte/${id}`);
  }
  updateCampaignTemplate(id, obj) {
    return this.http.post(`${environment.apiUrl}update/campaign/template/${id}`, obj);
  }
  saveCampaignPage(obj) {
    return this.http.post(`${environment.apiUrl}create/google/lp`, obj);
  }
  getAllCampaignPages() {
    return this.http.get(`${environment.apiUrl}get-all-campaign-pages`);
  }
  deleteCampaignPage(id) {
    return this.http.delete(`${environment.apiUrl}delete-campaign-page/${id}`);
  }
  updateCampaignPage(id, obj) {
    return this.http.post(`${environment.apiUrl}update-campaign-page/${id}`, obj);
  }
  getCampaignPageById(id) {
    return this.http.get(`${environment.apiUrl}get-campaign-page-by-id/${id}`);
  }
  savePushSubscription(obj) {
    console.log(obj);
    return this.http.post(`${environment.apiUrl}save-push-notification-subscriber`, obj);
  }
  pushNotificationToUser(obj) {
    return this.http.post(`${environment.apiUrl}push-notification`, obj);
  }
  saveObdVoiceFile(obj) {
    return this.http.post(`${environment.apiUrl}save-voice-files`, obj);
  }
  updateVoice(obj, id) {
    return this.http.put(`${environment.apiUrl}save-voice-files/${id}`, obj);
  }
  getVoiceFileList(filters) {
    return this.http.get(`${environment.apiUrl}get-voice-obd-list`, { params: filters });
  }
  getApprovedFileList(isActive) {
    return this.http.get(`${environment.apiUrl}get-approved-voice-obd-list?is_active=${isActive}`);
  }
  syncVoiceFile(id) {
    let obj = { id: id };
    return this.http.post(`${environment.apiUrl}sync-voice-list`, obj);
  }
  createVoiceCampaign(obj) {
    return this.http.post(`${environment.apiUrl}create-voice-campaign`, obj);
  }
  getServiceNo(isActive) {
    return this.http.get(`${environment.apiUrl}obdServiceNumber?is_active=${isActive}`);
  }
  stopScheduleCampaign(id) {
    return this.http.post(`${environment.apiUrl}stopScheduleCampaign/${id}`,{});
  }
  addServiceNo(payload) {
    return this.http.post(`${environment.apiUrl}obdServiceNumber`, payload);
  }
  updateServiceNo(id, payload) {
    return this.http.put(`${environment.apiUrl}updateObdServiceNumber/${id}`, payload);
  }
  deleteServiceNo(id) {
    return this.http.delete(`${environment.apiUrl}obdServiceNumber/${id}`);
  }
  getVoiceBalance() {
    return this.http.get(`${environment.apiUrl}get-voice-obd-balance`);
  }
  getObdCampaign(obj) {
    return this.http.post(`${environment.apiUrl}get-obd-campaign`,obj);
  }
  getObdCampaignReport(obj) {
    return this.http.get(`${environment.apiUrl}get-obd-campaign-report`, { params: obj });
  }
  getObdDistinctResponses() {
    return this.http.get(`${environment.apiUrl}getDistinctObdResponse`);
  }
  saveStateCourseRule(obj) {
    return this.http.post(`${environment.apiUrl}save/state-course/rule`, obj);
  }
  getCourseRuleSetList() {
    return this.http.get(`${environment.apiUrl}get-course-rule-list`);
  }
  getCourseRuleById(id) {
    return this.http.get(`${environment.apiUrl}get-course-rule-by-id?id=${id}`);
  }
  updateCourseRule(obj, id) {
    return this.http.put(`${environment.apiUrl}update-course-rule/${id}`, obj);
  }
  deleteCourseRule(id) {
    return this.http.delete(`${environment.apiUrl}delete-course-rule/${id}`);
  }
  getStateCities(filters) {
    return this.http.get(`${environment.apiUrl}get/city`, { params: filters });
  }
  addCity(obj) {
    return this.http.post(`${environment.apiUrl}city/create`, obj);
  }
  deleteCityById(id) {
    return this.http.delete(`${environment.apiUrl}delete/city/by/id/${id}`);
  }
  getCityById(id) {
    return this.http.get(`${environment.apiUrl}get/city/by/id/${id}`);
  }
  updateCity(obj, id) {
    return this.http.put(`${environment.apiUrl}city/update/${id}`, obj);
  }
  CityStatusUpdate(obj, id) {
    return this.http.put(`${environment.apiUrl}city/status/update/${id}`, obj);
  }
  createCustomeField(obj) {
    return this.http.post(`${environment.apiUrl}create/custome-field`, obj);
  }
  getCustomeField(obj) {
    return this.http.post(`${environment.apiUrl}get/custome-field`, obj);
  }
  deleteCustomeField(obj) {
    return this.http.post(`${environment.apiUrl}delete/custome-field`, obj);
  }
  syncVoiceList() {
    return this.http.get(`${environment.apiUrl}sync-voice-list`);
  }

  getEnterpriseWhatsappTemplate(filters) {
    return this.http.get(`${environment.apiUrl}get/enterprise/whatsapp/templates`, {
      params: filters,
    });
  }
  uploadWhatsappImage(body) {
    return this.http.post(`${environment.apiUrl}whatsapp/upload/image`, body);
  }
  sendEnterPriseWpMessage(body) {
    return this.http.post(`${environment.apiUrl}whatsapp/send/message`, body);
  }
  checkWhatsappCustomer(body) {
    return this.http.post(`${environment.apiUrl}check/whatsapp/customer`, body);
  }
  getWhatsappProjectId() {
    return this.http.get(`${environment.apiUrl}whatsapp-project-id`);
  }
  getObdCampaignGraphReport(id) {
    return this.http.post(`${environment.apiUrl}campaign-chart/${id}`,{});
  }
  //dialer
  createDialerConfig(payload) {
    return this.http.post(`${environment.apiUrl}dialer/config`,payload);
  }
  getDialerConfig(filters) {
    return this.http.get(`${environment.apiUrl}dialer/config`,{params:filters});
  }
  getDialerLeadList() {
    return this.http.get(`${environment.apiUrl}dialer/lead/list`);
  }
}
