import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { SettingService } from 'src/app/_services/setting.service';

@Component({
  selector: 'app-whatsapp-chat',
  templateUrl: './whatsapp-chat.component.html',
  styleUrls: ['./whatsapp-chat.component.css'],
})
export class WhatsappChatComponent implements OnInit {
  externalWebsiteURL: SafeHtml = '';
  
  path = '';
  phone = ""
  conversationStatus = ''
  project_id=''
  
  statusMap = {
    tab1: '',
    tab2: 'history',
  };
  currentUser:any
  
  constructor(
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private el: ElementRef,
    private settingService:SettingService
  ) {}
  
  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.route.queryParams.subscribe(params => {
      this.phone = params['phone'] || '';
      this.path = params['path'] || '';
      this.conversationStatus = params['status'] || '';
      this.getWhatsappProjectId()
      this.updateExternalWebsiteURL(this.phone, this.path);
    });

  }
  
  ngAfterViewInit() {
    if (this.path === 'inbox') {
      this.switchToTab('tab1');
    }
   else if (this.path === 'history') {
      this.switchToTab('tab2');
    } else if (this.path === 'bic-history') {
      this.switchToTab('tab3');
    }
  }
  
  switchToTab(tabId: string) {
    this.el.nativeElement.querySelector(`[data-toggle="tab"][data-target="#${tabId}"]`).click();
  }
  
  tabChange(tab: string) {
    const phone = this.route.snapshot.queryParams['phone'];
  
    if (tab === 'tab2') {
      this.path = 'history';
    } else if (tab === 'tab1') {
      this.path = 'inbox';
    } else if (tab === 'tab3') {
      this.path = 'bic-history';
    }  
    this.updateExternalWebsiteURL(phone, this.path);
  }
  
  updateExternalWebsiteURL(phone: string, path: string) {
    const queryParams = `source=external&phoneNumber=${phone ? phone : ''}&conversationStatus=${this.conversationStatus ? this.conversationStatus : ''}&token=${this.currentUser.wp_token}&projectId=${this.project_id}`;
    const urlStatus = path === 'bic-history' ? 'bic-history' : path === 'history' ? 'history' : 'inbox';
    const url = `${environment.WHATSAPP_CLIENT_BASE_URL}/${urlStatus}?${queryParams}`;
    console.log("wp url",url);
    this.externalWebsiteURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getWhatsappProjectId() {
    this.settingService.getWhatsappProjectId().subscribe((res:any) => {
      if(res.code === 200) {
        this.project_id = res.data
        this.updateExternalWebsiteURL(this.phone, this.path);
      }
    })
  }

}