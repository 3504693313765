import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SourcesApiDataSharingService {
  // BehaviorSubject to store and share the sources data
  private sourcesSubject = new BehaviorSubject<any>(null);
  
  // Observable to expose the sources data
  sources$ = this.sourcesSubject.asObservable();

  // Method to set the sources data
  setSourcesData(sources: any) {
    this.sourcesSubject.next(sources);
  }
}
