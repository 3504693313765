<div id="page-wrapper" class="gray-bg dashbard-1">
  <div class="content-main">
    <div class="blank">
      <h2 class="fs20 fsm18 fw700 mb-30">Timeline</h2>
      <div class="normal-form form-grid mb-30">
        <div class="datepicker pos-rel form-group">
          <input
            type="text"
            name=""
            id=""
            class="form-control dateTimePicker"
            placeholder="Select Date Range" />
        </div>
        <div class="form-group">
          <select name="" id="" class="form-control">
            <option value="">Select Properties</option>
          </select>
        </div>
        <div class="form-group">
          <select name="" id="" class="form-control">
            <option value="">Select Users</option>
          </select>
        </div>
        <div class="form-group">
          <button class="btn btn-blue">Search</button>
        </div>
      </div>
      <div>
        <ul class="timeline publisher">
          <li>
            <div class="timeline-badge info">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#history"></use>
              </svg>
            </div>
            <div class="timeline-panel">
              <div class="timeline-title mb-15">
                7 Nov, 2024 <span class="badge fs12">1 total changes in this day</span>
              </div>
              <div class="time-des custom-card">
                <div
                  class="flex-center justify-space-between gap-15 flex-wrap mb-15 border-bottom pb-15">
                  <div class="flex-center gap-10">
                    <svg class="dash-icons">
                      <use href="assets/images/icon.svg#user"></use>
                    </svg>
                    <h3 class="fs18 fsm16 fw700">Vinay <small>(Modified by)</small></h3>
                  </div>
                  <div class="flex-center gap-10">
                    <svg class="dash-icons">
                      <use href="assets/images/icon.svg#timeline"></use>
                    </svg>
                    <p>7 Nov, 2024 4:45PM IST</p>
                  </div>
                </div>
                <div class="timeline-info mb-20">
                  <div class="flex-center gap-10">
                    <svg class="dash-icons">
                      <use href="assets/images/icon.svg#th-list"></use>
                    </svg>
                    <p>Table Effected : <strong>Dashboard</strong></p>
                  </div>
                  <div class="flex-center gap-10">
                    <svg class="dash-icons">
                      <use href="assets/images/icon.svg#global"></use>
                    </svg>
                    <p>IP Address : <strong>112.32.33.999</strong></p>
                  </div>
                  <div class="flex-center gap-10">
                    <svg class="dash-icons">
                      <use href="assets/images/icon.svg#file"></use>
                    </svg>
                    <p>Property : <span class="badge">table_component</span></p>
                  </div>
                  <div class="flex-center gap-10">
                    <svg class="dash-icons">
                      <use href="assets/images/icon.svg#device"></use>
                    </svg>
                    <p>User Agent : <strong>Mozilla/5.0 (X11; Linux x86_64.</strong></p>
                  </div>
                </div>
                <div class="collapse-container">
                  <div class="flex-1 data-item">
                    <button
                      class="btn flex-center justify-space-between"
                      data-toggle="collapse"
                      data-target="#oldData">
                      Old Data
                      <svg class="dash-icons">
                        <use href="assets/images/icon.svg#expand-more"></use>
                      </svg>
                    </button>
                    <div class="collapse" id="oldData">
                      <code>
                        <pre class="scrollbar">
                            "
                            &quot;carousel&quot;: &#123;
                              &quot;color&quot;: &quot;#eff4ef&quot;,
                              &quot;isEnabled&quot;: true,
                              &quot;list_items&quot;: [
                                &#123;
                                  &quot;id&quot;: 1,
                                  &quot;text&quot;: &quot;how are you&quot;,
                                  &quot;font_size&quot;: 23,
                                  &quot;description&quot;: &quot;Free Shipping on all orders. Priority delivery on Prepaid Orders&quot;,
                                  &quot;font_weight&quot;: &quot;bold&quot;
                                &#125;,
                                &#123;
                                  &quot;id&quot;: 2,
                                  &quot;text&quot;: &quot;sdfgh&quot;,
                                  &quot;font_size&quot;: 20,
                                  &quot;description&quot;: &quot;Explore best of Levi’s deals and know about exclusive collections first&quot;,
                                  &quot;font_weight&quot;: &quot;500&quot;
                                &#125;,
                                &#123;
                                  &quot;id&quot;: 3,
                                  &quot;text&quot;: &quot;asdfghjk&quot;,
                                  &quot;font_size&quot;: 19,
                                  &quot;description&quot;: &quot;explore best of Levi’s deals and know about exclusive collections first&quot;,
                                  &quot;font_weight&quot;: &quot;bold&quot;
                                &#125;
                              ]
                            &#125;
                            "
                        </pre>
                      </code>
                    </div>
                  </div>
                  <div class="flex-1 data-item">
                    <button
                      class="btn flex-center justify-space-between"
                      data-toggle="collapse"
                      data-target="#newData">
                      New Data
                      <svg class="dash-icons">
                        <use href="assets/images/icon.svg#expand-more"></use>
                      </svg>
                    </button>
                    <div class="collapse" id="newData">
                      <code>
                        <pre class="scrollbar">
                            "
                            &quot;carousel&quot;: &#123;
                              &quot;color&quot;: &quot;#eff4ef&quot;,
                              &quot;isEnabled&quot;: true,
                              &quot;list_items&quot;: [
                                &#123;
                                  &quot;id&quot;: 1,
                                  &quot;text&quot;: &quot;how are you&quot;,
                                  &quot;font_size&quot;: 23,
                                  &quot;description&quot;: &quot;Free Shipping on all orders. Priority delivery on Prepaid Orders&quot;,
                                  &quot;font_weight&quot;: &quot;bold&quot;
                                &#125;,
                                &#123;
                                  &quot;id&quot;: 2,
                                  &quot;text&quot;: &quot;sdfgh&quot;,
                                  &quot;font_size&quot;: 20,
                                  &quot;description&quot;: &quot;Explore best of Levi’s deals and know about exclusive collections first&quot;,
                                  &quot;font_weight&quot;: &quot;500&quot;
                                &#125;,
                                &#123;
                                  &quot;id&quot;: 3,
                                  &quot;text&quot;: &quot;asdfghjk&quot;,
                                  &quot;font_size&quot;: 19,
                                  &quot;description&quot;: &quot;explore best of Levi’s deals and know about exclusive collections first&quot;,
                                  &quot;font_weight&quot;: &quot;bold&quot;
                                &#125;
                              ]
                            &#125;
                            "
                        </pre>
                      </code>
                    </div>
                  </div>
                </div>
                <div class="flex-center justify-content-end mt-15">
                  <button class="btn btn-blue fs12" data-toggle="modal" data-target="#viewChanges">
                    View compare
                  </button>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="timeline-badge info">
              <svg class="dash-icons">
                <use href="assets/images/icon.svg#history"></use>
              </svg>
            </div>
            <div class="timeline-panel">
              <div class="timeline-title mb-15">
                7 Nov, 2024 <span class="badge fs12">1 total changes in this day</span>
              </div>
              <div class="time-des custom-card">
                <div
                  class="flex-center justify-space-between gap-15 flex-wrap mb-15 border-bottom pb-15">
                  <div class="flex-center gap-10">
                    <svg class="dash-icons">
                      <use href="assets/images/icon.svg#user"></use>
                    </svg>
                    <h3 class="fs18 fsm16 fw700">Vinay <small>(Modified by)</small></h3>
                  </div>
                  <div class="flex-center gap-10">
                    <svg class="dash-icons">
                      <use href="assets/images/icon.svg#timeline"></use>
                    </svg>
                    <p>7 Nov, 2024 4:45PM IST</p>
                  </div>
                </div>
                <div class="timeline-info mb-20">
                  <div class="flex-center gap-10">
                    <svg class="dash-icons">
                      <use href="assets/images/icon.svg#th-list"></use>
                    </svg>
                    <p>Table Effected : <strong>Dashboard</strong></p>
                  </div>
                  <div class="flex-center gap-10">
                    <svg class="dash-icons">
                      <use href="assets/images/icon.svg#global"></use>
                    </svg>
                    <p>IP Address : <strong>112.32.33.999</strong></p>
                  </div>
                  <div class="flex-center gap-10">
                    <svg class="dash-icons">
                      <use href="assets/images/icon.svg#file"></use>
                    </svg>
                    <p>Property : <span class="badge">table_component</span></p>
                  </div>
                  <div class="flex-center gap-10">
                    <svg class="dash-icons">
                      <use href="assets/images/icon.svg#device"></use>
                    </svg>
                    <p>User Agent : <strong>Mozilla/5.0 (X11; Linux x86_64.</strong></p>
                  </div>
                </div>
                <div class="collapse-container">
                  <div class="flex-1 data-item">
                    <button
                      class="btn flex-center justify-space-between"
                      data-toggle="collapse"
                      data-target="#oldData">
                      Old Data
                      <svg class="dash-icons">
                        <use href="assets/images/icon.svg#expand-more"></use>
                      </svg>
                    </button>
                    <div class="collapse" id="oldData">
                      <code>
                        <pre class="scrollbar">
                            "
                            &quot;carousel&quot;: &#123;
                              &quot;color&quot;: &quot;#eff4ef&quot;,
                              &quot;isEnabled&quot;: true,
                              &quot;list_items&quot;: [
                                &#123;
                                  &quot;id&quot;: 1,
                                  &quot;text&quot;: &quot;how are you&quot;,
                                  &quot;font_size&quot;: 23,
                                  &quot;description&quot;: &quot;Free Shipping on all orders. Priority delivery on Prepaid Orders&quot;,
                                  &quot;font_weight&quot;: &quot;bold&quot;
                                &#125;,
                                &#123;
                                  &quot;id&quot;: 2,
                                  &quot;text&quot;: &quot;sdfgh&quot;,
                                  &quot;font_size&quot;: 20,
                                  &quot;description&quot;: &quot;Explore best of Levi’s deals and know about exclusive collections first&quot;,
                                  &quot;font_weight&quot;: &quot;500&quot;
                                &#125;,
                                &#123;
                                  &quot;id&quot;: 3,
                                  &quot;text&quot;: &quot;asdfghjk&quot;,
                                  &quot;font_size&quot;: 19,
                                  &quot;description&quot;: &quot;explore best of Levi’s deals and know about exclusive collections first&quot;,
                                  &quot;font_weight&quot;: &quot;bold&quot;
                                &#125;
                              ]
                            &#125;
                            "
                        </pre>
                      </code>
                    </div>
                  </div>
                  <div class="flex-1 data-item">
                    <button
                      class="btn flex-center justify-space-between"
                      data-toggle="collapse"
                      data-target="#newData">
                      New Data
                      <svg class="dash-icons">
                        <use href="assets/images/icon.svg#expand-more"></use>
                      </svg>
                    </button>
                    <div class="collapse" id="newData">
                      <code>
                        <pre class="scrollbar">
                            "
                            &quot;carousel&quot;: &#123;
                              &quot;color&quot;: &quot;#eff4ef&quot;,
                              &quot;isEnabled&quot;: true,
                              &quot;list_items&quot;: [
                                &#123;
                                  &quot;id&quot;: 1,
                                  &quot;text&quot;: &quot;how are you&quot;,
                                  &quot;font_size&quot;: 23,
                                  &quot;description&quot;: &quot;Free Shipping on all orders. Priority delivery on Prepaid Orders&quot;,
                                  &quot;font_weight&quot;: &quot;bold&quot;
                                &#125;,
                                &#123;
                                  &quot;id&quot;: 2,
                                  &quot;text&quot;: &quot;sdfgh&quot;,
                                  &quot;font_size&quot;: 20,
                                  &quot;description&quot;: &quot;Explore best of Levi’s deals and know about exclusive collections first&quot;,
                                  &quot;font_weight&quot;: &quot;500&quot;
                                &#125;,
                                &#123;
                                  &quot;id&quot;: 3,
                                  &quot;text&quot;: &quot;asdfghjk&quot;,
                                  &quot;font_size&quot;: 19,
                                  &quot;description&quot;: &quot;explore best of Levi’s deals and know about exclusive collections first&quot;,
                                  &quot;font_weight&quot;: &quot;bold&quot;
                                &#125;
                              ]
                            &#125;
                            "
                        </pre>
                      </code>
                    </div>
                  </div>
                </div>
                <div class="flex-center justify-content-end mt-15">
                  <button class="btn btn-blue fs12" data-toggle="modal" data-target="#viewChanges">
                    View compare
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<!-- view changes -->
<div class="modal fade static-modal" id="viewChanges" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="fs16 fw700">Comparison of Old and New details</h2>
        <a class="close-btn" data-dismiss="modal" aria-label="Close">
          <svg class="dash-icons">
            <use href="assets/images/icon.svg#close-btn" />
          </svg>
        </a>
      </div>
      <div class="modal-body display-flex flex-wrap gap-15">
        <div class="scrollbar code-wrapper old-data">
          <h4 class="fs16 fw700 mb-15 text-center old-data">Old Data</h4>
          <code>
            <pre class="scrollbar">
                    "
                    &quot;carousel&quot;: &#123;
                      &quot;color&quot;: &quot;#eff4ef&quot;,
                      &quot;isEnabled&quot;: true,
                      &quot;list_items&quot;: [
                        &#123;
                          &quot;id&quot;: 1,
                          &quot;text&quot;: &quot;how are you&quot;,
                          &quot;font_size&quot;: 23,
                          &quot;description&quot;: &quot;Free Shipping on all orders. Priority delivery on Prepaid Orders&quot;,
                          &quot;font_weight&quot;: &quot;bold&quot;
                        &#125;,
                        &#123;
                          &quot;id&quot;: 2,
                          &quot;text&quot;: &quot;sdfgh&quot;,
                          &quot;font_size&quot;: 20,
                          &quot;description&quot;: &quot;Explore best of Levi’s deals and know about exclusive collections first&quot;,
                          &quot;font_weight&quot;: &quot;500&quot;
                        &#125;,
                        &#123;
                          &quot;id&quot;: 3,
                          &quot;text&quot;: &quot;asdfghjk&quot;,
                          &quot;font_size&quot;: 19,
                          &quot;description&quot;: &quot;explore best of Levi’s deals and know about exclusive collections first&quot;,
                          &quot;font_weight&quot;: &quot;bold&quot;
                        &#125;
                      ]
                    &#125;
                    "
                </pre
            >
          </code>
        </div>
        <div class="scrollbar code-wrapper new-data">
          <h4 class="fs16 fw700 mb-15 text-center">New Data</h4>
          <code>
            <pre class="scrollbar">
                      "
                      &quot;carousel&quot;: &#123;
                        &quot;color&quot;: &quot;#eff4ef&quot;,
                        &quot;isEnabled&quot;: true,
                        &quot;list_items&quot;: [
                          &#123;
                            &quot;id&quot;: 1,
                            &quot;text&quot;: &quot;how are you&quot;,
                            &quot;font_size&quot;: 23,
                            &quot;description&quot;: &quot;Free Shipping on all orders. Priority delivery on Prepaid Orders&quot;,
                            &quot;font_weight&quot;: &quot;bold&quot;
                          &#125;,
                          &#123;
                            &quot;id&quot;: 2,
                            &quot;text&quot;: &quot;sdfgh&quot;,
                            &quot;font_size&quot;: 20,
                            &quot;description&quot;: &quot;Explore best of Levi’s deals and know about exclusive collections first&quot;,
                            &quot;font_weight&quot;: &quot;500&quot;
                          &#125;,
                          &#123;
                            &quot;id&quot;: 3,
                            &quot;text&quot;: &quot;asdfghjk&quot;,
                            &quot;font_size&quot;: 19,
                            &quot;description&quot;: &quot;explore best of Levi’s deals and know about exclusive collections first&quot;,
                            &quot;font_weight&quot;: &quot;bold&quot;
                          &#125;
                        ]
                      &#125;
                      "
                  </pre
            >
          </code>
        </div>
      </div>
    </div>
  </div>
</div>
