import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
// import { io, Socket } from 'socket.io-client';
// import { Observable, observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class WhatsAppService {
  //  socket: Socket;
  //  url =  environment.apiUrl;

  constructor(
    private http: HttpClient,
    private toastrService: ToastrService
  ) {
    //this.socket = io(this.url);
  }
  // listen(eventName) {
  //    return new Observable(observar => {
  //        this.socket.on(eventName, (data) => {
  //            observar.next(data);
  //        });
  //    });
  // }
  getChatList(id) {
    console.log('chatlist::');
    return this.http.get(`${environment.apiUrl}chats_list?id=${id}`);
  }
  getChatHistory(data) {
    data.type = data.type ? data.type : '';
    return this.http.get(
      `${environment.apiUrl}chat_history?chat_id=${data.wa_user_id}&limit=${data.limit}&offset=${data.offset}&userId=${data.userId}&type=${data.type}`
    );
  }
  sendWhatsAppMessage(data, type) {
    console.log(type);
    return this.http.post(`${environment.apiUrl}send_wa_message?ctype=${type}`, data);
  }
  getUnseenMsgs(id) {
    console.log(id);
    let obj = {
      unseen_msg: true,
    };
    return this.http.post(`${environment.apiUrl}chats_list_query/${id}`, obj);
  }
  getRequestingList() {
    return this.http.get(`${environment.apiUrl}requesting_chats_list`);
  }
}
