<div id="divLoading" class="divLoading" *ngIf="is_loading">
  <p><img src="assets/images/loader1.gif" /></p>
</div>
<!-- publisher-profie-start-->
<div id="page-wrapper" class="gray-bg dashbard-1">
  <div class="content-main">
    <div class="blank">
      <div class="publisher-profile-section display-flex flex-column justify-space-between">
        <div class="publisher-logo flex-center">
          <figure>
            <img src="{{ hostname }}{{persistUser.logo_image}}" />
          </figure>
          <div class="logo-title">
            <h2 class="fs18">{{persistUser.name}}</h2>
            <ul class="display-flex publiher-user-profile-icon">
              <li>
                <a href="{{TenantDetail.facebook}}" target="_blank">
                  <svg class="dash-icons">
                    <use xlink:href="assets/images/icon.svg#fb" />
                  </svg>
                </a>
              </li>
              <li>
                <a href="{{TenantDetail.instagram}}" target="_blank">
                  <svg class="dash-icons">
                    <use xlink:href="assets/images/icon.svg#instagram" />
                  </svg>
                </a>
              </li>
              <li>
                <a href="{{TenantDetail.youtube}}" target="_blank">
                  <svg class="dash-icons">
                    <use xlink:href="assets/images/icon.svg#youtube" />
                  </svg>
                </a>
              </li>
              <li>
                <a href="{{TenantDetail.twitter}}" target="_blank">
                  <svg class="dash-icons">
                    <use xlink:href="assets/images/icon.svg#twitter" />
                  </svg>
                </a>
              </li>
            </ul>
            <p style="margin: 0">{{TenantDetail ? TenantDetail.description : ''}}</p>
          </div>
        </div>
        <ul class="edit-btn display-flex justify-content-end margin-t15 mr-t15">
          <li *ngIf="this.currentUser['role'] == 1">
            <a
              (click)="patchValue()"
              class="btn fs12"
              data-toggle="modal"
              data-target="#publisherModal"
              >Edit Tenant Profile</a
            >
          </li>
        </ul>
      </div>
      <!-- publisher-profie end-->
      <!-- publisher-basic-details-start-->
      <div class="publisher-basic-detils">
        <h2 class="fs20">Basic Details</h2>
        <div class="detail-list display-grid">
          <ul class="timeline publisher">
            <li>
              <div class="timeline-badge info"></div>
              <div class="timeline-panel">
                <div class="timeline-title">Phone Number</div>
                <div class="time-des">{{TenantDetail.phone}}</div>
              </div>
            </li>
            <li>
              <div class="timeline-badge info"></div>
              <div class="timeline-panel">
                <div class="timeline-title">Supprt Phone Number</div>
                <div class="time-des">
                  {{TenantDetail && TenantDetail.support_call ? TenantDetail.support_call : ''}}
                </div>
              </div>
            </li>
            <li>
              <div class="timeline-badge info"></div>
              <div class="timeline-panel">
                <div class="timeline-title">Support Email ID</div>
                <div class="time-des">
                  {{TenantDetail.support_email ? TenantDetail.support_email : ''}}
                </div>
              </div>
            </li>
          </ul>
          <ul class="timeline publisher">
            <li>
              <div class="timeline-badge info"></div>
              <div class="timeline-panel">
                <div class="timeline-title">Address Type 1</div>
                <div class="time-des">{{TenantDetail.address1 ? TenantDetail.address1 : ''}}</div>
              </div>
            </li>
            <li>
              <div class="timeline-badge info"></div>
              <div class="timeline-panel">
                <div class="timeline-title">Address 2</div>
                <div class="time-des">{{TenantDetail.address2 ? TenantDetail.address2 : ''}}</div>
              </div>
            </li>
            <li>
              <div class="timeline-badge info"></div>
              <div class="timeline-panel">
                <div class="timeline-title">Country</div>
                <div class="time-des">
                  {{TenantDetail.country ? TenantDetail.country : 'India'}}
                </div>
              </div>
            </li>
          </ul>
          <ul class="timeline publisher">
            <li>
              <div class="timeline-badge info"></div>
              <div class="timeline-panel">
                <div class="timeline-title">State</div>
                <div class="time-des">{{TenantDetail.state ? TenantDetail.state : ''}}</div>
              </div>
            </li>
            <li>
              <div class="timeline-badge info"></div>
              <div class="timeline-panel">
                <div class="timeline-title">City</div>
                <div class="time-des">{{TenantDetail.city ? TenantDetail.city : ''}}</div>
              </div>
            </li>
            <li>
              <div class="timeline-badge info"></div>
              <div class="timeline-panel">
                <div class="timeline-title">Pincode</div>
                <div class="time-des">{{TenantDetail.pincode ? TenantDetail.pincode : ''}}</div>
              </div>
            </li>
          </ul>
          <ul class="timeline publisher">
            <li>
              <div class="timeline-badge info"></div>
              <div class="timeline-panel">
                <div class="timeline-title">Tenant Type</div>
                <div class="time-des">
                  {{TenantDetail.tenant_type ? TenantDetail.tenant_type : ''}}
                </div>
              </div>
            </li>
            <li>
              <div class="timeline-badge info"></div>
              <div class="timeline-panel">
                <div class="timeline-title">Website</div>
                <div class="time-des">{{TenantDetail.website ? TenantDetail.website : ''}}</div>
              </div>
            </li>
            <li>
              <div class="timeline-badge info"></div>
              <div class="timeline-panel">
                <div class="timeline-title">WhatsApp API Enable</div>
                <div class="time-des">
                  {{TenantDetail.wp_api_enabled ? TenantDetail.wp_api_enabled : ''}}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- publisher-basic-details-end-->
<!-- publisher Modal -->
<div class="modal static-modal fade" id="publisherModal" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="fs16 fw700">Edit Tenant Profile</h2>
        <a class="close-btn" data-dismiss="modal" aria-label="Close">
          <svg class="dash-icons">
            <use href="assets/images/icon.svg#close-btn" />
          </svg>
        </a>
      </div>
      <!-- publihser-form-start-->
      <div class="modal-body">
        <div class="publisher-edit-form-block">
          <form [formGroup]="editTenant" (ngSubmit)="onSubmit()" class="row">
            <div class="form-group col-sm-4 col-xs-12">
              <label>Tenant Name <sup> *</sup></label>
              <input
                type="text"
                formControlName="name"
                class="form-control"
                placeholder="Amity University, Kolkata" />
              <div *ngIf="f.name.touched && f.name.invalid" class="alert alert-danger">
                <div *ngIf="f.name.errors.required">Name is required.</div>
              </div>
            </div>
            <div class="form-group col-sm-4 col-xs-12">
              <label>Tenant Short Name <sup> *</sup></label>
              <input
                type="text"
                formControlName="short_name"
                class="form-control"
                placeholder="Enter tenant short name" />
              <div *ngIf="f.short_name.touched && f.short_name.invalid" class="alert alert-danger">
                <div *ngIf="f.short_name.errors.required">Short Name is required.</div>
              </div>
            </div>
            <div class="form-group col-sm-4 col-xs-12">
              <label>Established In <sup>*</sup></label>
              <input
                type="text"
                formControlName="estd_year"
                class="form-control"
                placeholder="1990" />
            </div>
            <div class="form-group col-sm-4 col-xs-12">
              <label>Support Email</label>
              <input
                type="tel"
                formControlName="support_email"
                class="form-control"
                placeholder="Support@gmail.com" />
              <div
                *ngIf="f.support_email.touched && f.support_email.invalid"
                class="alert alert-danger">
                <div *ngIf="f.support_email.errors.pattern">Please, Enter Valid email address.</div>
              </div>
            </div>
            <div class="form-group col-sm-4 col-xs-12">
              <label>Phone Number <sup> *</sup></label>
              <input
                type="tel"
                formControlName="phone"
                class="form-control"
                placeholder="+91 973 102 3320" />
              <div *ngIf="f.phone.touched && f.phone.invalid" class="alert alert-danger">
                <div *ngIf="f.phone.errors.required">Mobile Number is required.</div>
                <div *ngIf="f.phone.errors.pattern">Please, Enter 10 digit Mobile Number.</div>
              </div>
            </div>
            <div class="form-group col-sm-4 col-xs-12">
              <label>Support Phone Number</label>
              <input
                type="tel"
                formControlName="support_call"
                class="form-control"
                placeholder="+91 973 102 3320" />
            </div>

            <div class="form-group col-sm-4 col-xs-12">
              <label>Address Line 1 <sup> *</sup></label>
              <input
                type="text"
                formControlName="address1"
                class="form-control"
                placeholder="Enter your address" />
            </div>
            <div class="form-group col-sm-4 col-xs-12">
              <label>Address Line 2</label>
              <input
                type="text"
                formControlName="address2"
                class="form-control"
                placeholder="Enter your address" />
            </div>
            <div class="form-group col-sm-4 col-xs-12">
              <label>State <sup>*</sup></label>
              <div class="publish_select">
                <select class="form-control select" formControlName="state" (ngModelChange)="changeState($event)">
                  <option>Select State</option>
                  <option *ngFor="let state of states;" [value]="state.value">
                    {{state.value}}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group col-sm-4 col-xs-12">
              <label>City <sup>*</sup></label>
              <div class="publish_select">
                <select class="form-control select" formControlName="city">
                  <option>Select City</option>
                  <option *ngFor="let city of cities;" [value]="city.name">
                    {{city.name}}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group col-sm-4 col-xs-12">
              <label>Pincode</label>
              <input
                type="number"
                formControlName="pincode"
                class="form-control"
                placeholder="Enter your pincode" />
            </div>
            <div class="form-group col-sm-4 col-xs-12">
              <label>Website <sup> *</sup></label>
              <input
                type="text"
                formControlName="website"
                class="form-control"
                placeholder="Enter Your Website" />
            </div>
            <div class="form-group col-sm-4 col-xs-12">
              <label>Twitter</label>
              <input
                type="text"
                formControlName="twitter"
                class="form-control"
                placeholder="@your twitter handle" />
            </div>
            <div class="form-group col-sm-4 col-xs-12">
              <label>Facebook </label>
              <input
                type="text"
                formControlName="facebook"
                class="form-control"
                placeholder="Enter your facebook profile link" />
            </div>
            <div class="form-group col-sm-4 col-xs-12">
              <label>Instagram</label>
              <input
                type="text"
                formControlName="instagram"
                class="form-control"
                placeholder="@instagram handle" />
            </div>
            <div class="form-group col-sm-4 col-xs-12">
              <label>Youtube</label>
              <input
                type="text"
                formControlName="youtube"
                class="form-control"
                placeholder="Enter your youtube link" />
            </div>
            <div class="form-group col-sm-4 col-xs-12">
              <label>App Latest Version</label>
              <input
                type="text"
                formControlName="app_latest_version"
                class="form-control"
                placeholder="Ex: 1.0.2" />
            </div>
            <div class="form-group col-sm-4 col-xs-12">
              <label>App Latest Version Code</label>
              <input
                type="text"
                formControlName="app_latest_version_code"
                class="form-control"
                placeholder="Ex: 2" />
            </div>
            <div class="form-group col-sm-4 col-xs-12">
              <label>App Released On</label>
              <input
                type="datetime-local"
                formControlName="app_released_on"
                class="form-control"
                placeholder="Ex: 23/01/2023, 10:11" />
            </div>
            <div class="form-group col-sm-4 col-xs-12">
              <label>App Required Os</label>
              <input
                type="text"
                formControlName="app_required_os"
                class="form-control"
                placeholder="Ex: Android 5.0" />
            </div>
            <div class="form-group col-sm-4 col-xs-12">
              <label>App Download Link</label>
              <input
                type="text"
                formControlName="app_download_link"
                class="form-control"
                placeholder="Enter app download link" />
            </div>
            <div class="form-group col-sm-4 col-xs-12">
              <label>App Min Permission</label>
              <ng-multiselect-dropdown
                name="App Min Permission"
                [placeholder]="'Select Permission'"
                [settings]="dropdownSettings"
                [data]="permissionList"
                formControlName="app_min_permission"
                (onSelect)="onItemSelect($event)">
              </ng-multiselect-dropdown>
            </div>
            <!--                <div class="form-group">
                  <label>App Size</label>
                  <input type="text" formControlName="app_size" class="form-control"
                     placeholder="Enter app size" />
               </div> -->

            <div class="form-group col-sm-4 col-xs-12">
              <label>Logo</label>
              <input
                type="button"
                class="form-control"
                value="Click Here to Change Logo"
                (click)="changeLogo()" />
              <input
                type="file"
                *ngIf="change_logo"
                class="form-control"
                (change)="fileUpload($event)"
                placeholder="Choose File"
                accept="image/*" />
              <img [src]="logoImageSrc" *ngIf="logoImageSrc" class="logo-img" />
            </div>
            <div class="form-group col-sm-12 col-xs-12">
              <label>Tenant Description <sup> *</sup></label>
              <textarea
                class="form-control textarea"
                formControlName="description"
                rows="5"
                cols="10">
                Describe the tenant</textarea
              >
              <div
                *ngIf="f.description.touched && f.description.invalid"
                class="alert alert-danger">
                <div *ngIf="f.description.errors.required">Description is required.</div>
              </div>
            </div>
            <div class="form-group col-sm-12 col-xs-12">
              <div class="form-btn">
                <button
                  type="submit"
                  class="btn btn-blue margin-r10 mr-r10"
                  [disabled]="editTenant.invalid">
                  Save
                </button>
                <button data-dismiss="modal" id="closeEditPopup" class="btn btn-orange">
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- publisher Modal -->
