// import { Injectable } from '@angular/core';
// import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
// import { Observable } from 'rxjs';
// import { map } from 'rxjs/operators';
// import { AuthServiceService } from '../_services/auth-service.service';

// @Injectable({
//   providedIn: 'root',
// })
// export class RoleGuard implements CanActivate {
//   constructor(
//     private authService: AuthServiceService,
//     private router: Router
//   ) {}

//   canActivate(
//     route: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot
//   ): Observable<boolean> | boolean {
//     const componentCode = route.data.componentCode;
//     const requiredPermission = route.data.requiredPermission;

//     return this.authService.fetchPermissions().pipe(
//       map(permissions => {
//         if (this.authService.hasPermission(componentCode, requiredPermission)) {
//           return true;
//         } else {
//           console.log('access-denied');
//           this.router.navigate(['/access-denied']);
//           return false;
//         }
//       })
//     );
//   }
// }


import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthServiceService } from '../_services/auth-service.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate, CanLoad {
  constructor(
    private authService: AuthServiceService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    const componentCode = route.data.componentCode;
    const requiredPermission = route.data.requiredPermission;

    return this.checkPermission(componentCode, requiredPermission);
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | boolean {
    const componentCode = route.data && route.data['componentCode'];
    const requiredPermission = route.data && route.data['requiredPermission'];

    return this.checkPermission(componentCode, requiredPermission);
  }

  private checkPermission(componentCode: string, requiredPermission: number): Observable<boolean> | boolean {
    return this.authService.fetchPermissions().pipe(
      map(permissions => {
        if (this.authService.hasPermission(componentCode, requiredPermission)) {
          return true;
        } else {
          console.log('access-denied');
          this.router.navigate(['/access-denied']);
          return false;
        }
      })
    );
  }
}
