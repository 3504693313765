import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

import { AuthenticationService } from '../_services/authentication.service';
import { User } from '../_models/user';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private currentUserSubject: BehaviorSubject<User>;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the current user
    let currentUser = this.authenticationService.currentUserValue;

    // Check if the user is logged in and has a token
    if (currentUser && currentUser.token) {
      const jwtToken = this.parseJwt(currentUser.token);
      const expires = new Date(jwtToken.exp * 1000);

      if (expires < new Date()) {
        // Token has expired, so remove user data
        this.handleExpiredToken();
      } else {
        // Clone the request to add the authorization header and credentials
        request = this.addAuthHeader(request, currentUser.token);
      }
    } else {
      // If no user or token, ensure withCredentials is still set
      request = request.clone({
        withCredentials: true,
      });
    }

    // Pass the request to the next handler in the chain
    return next.handle(request);
  }

  private parseJwt(token: string): any {
    return JSON.parse(atob(token.split('.')[1]));
  }

  private handleExpiredToken(): void {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('persist');
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']); // Optionally, redirect to the login page
  }

  private addAuthHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
      },
      withCredentials: true,
    });
  }
}
