<div id="divLoading" class="divLoading" *ngIf="loading">
  <p><img src="assets/images/loader1.gif" /></p>
</div>
<div id="page-wrapper" class="gray-bg dashbard-1">
  <div class="content-main">
    <div class="blank">
      <div class="order-container" *ngIf="user_role!=5">
        <div class="row order-details" *ngFor="let data of orderData">
          <div class="col-sm-4 col-xs-6">
            <div class="form-group order-form">
              <label>First Name</label>
              <p>{{data.billing_first_name}}</p>
            </div>
          </div>
          <div class="col-sm-4 col-xs-6">
            <div class="form-group order-form">
              <label>Last Name</label>
              <p>{{data.billing_last_name}}</p>
            </div>
          </div>
          <div class="col-sm-4 col-xs-6">
            <div class="form-group order-form">
              <label>Phone No.</label>
              <p>{{data.billing_phone}}</p>
            </div>
          </div>

          <div class="col-sm-4 col-xs-6">
            <div class="form-group order-form">
              <label>Email</label>
              <p>{{data.billing_email}}</p>
            </div>
          </div>
          <div class="col-sm-4 col-xs-12">
            <div class="form-group order-form">
              <label>User Agent</label>
              <p>{{data.user_agent}}</p>
            </div>
          </div>
          <div class="col-sm-4 col-xs-6">
            <div class="form-group order-form">
              <label>IP</label>
              <p>{{data.ip}}</p>
            </div>
          </div>
          <div class="col-sm-4 col-xs-6">
            <div class="form-group order-form">
              <label>Status</label>
              <p>{{data.status}}</p>
            </div>
          </div>
          <div class="col-sm-4 col-xs-12">
            <div class="form-group order-form">
              <label>Created</label>
              <p>{{formatDate(data.created_at)}}</p>
            </div>
          </div>
          <div class="col-sm-4 col-xs-12">
            <div class="form-group order-form">
              <label>Payment</label>
              <p>{{formatDate(data.payment_at)}}</p>
            </div>
          </div>
          <div class="col-sm-4 col-xs-12">
            <div class="form-group order-form">
              <label>Total</label>
              <p>{{data.total}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
