import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { io, Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SocketService implements OnDestroy {
  public message$: BehaviorSubject<string> = new BehaviorSubject('');

  private socket;
  private currentUser = {};
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const token = this.currentUser['token'];
    const url = `${environment.SOCKET_ENDPOINT}?token=${token}`; // Include the token in the URL
    this.socket = io(url, {
      path: '/azsocket/',
      withCredentials: true,
      transports: ['websocket', 'polling'],
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      timeout: 20000,
      upgrade: true,
      auth: {
        token
      }
    });

    this.socket.on('connect', () => {
      console.log('Socket connected');
    });

    this.socket.on('disconnect', reason => {
      console.log('Socket disconnected:', reason);
      if (reason === 'io server disconnect') {
        // The server has forcefully disconnected the socket, attempt to reconnect
        this.socket.connect();
      }
    });

    this.socket.on('connect_error', err => {
      console.log(`Client Socket connection_error ==> ${err.message}`);
      if (err.description) console.log(`Client Socket Description ==> ${err.description}`);
      if (err.context) console.log(`Client Socket Context ==> ${err.context}`);
    });

    // Listen for incoming notifications
    this.socket.on('crm_notification', message => {
      console.log("message",message)
      this.message$.next(message);
    });
  }

  // public joinUserRoom(userId: string): void {
  //   this.socket.emit('joinRoom', userId);
  // }

  // public leaveUserRoom(userId: string): void {
  //   this.socket.emit('leaveRoom', userId);
  // }

  public getNewMessage = () => {
    return this.message$.asObservable().pipe(takeUntil(this.unsubscribe$));
  };

  ngOnDestroy() {
    this.socket.disconnect();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
