import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AclService {
  private aclUserSubject: BehaviorSubject<any[]>; 
  private isFetching: boolean = false; 
  constructor(private http: HttpClient) {
    this.aclUserSubject = new BehaviorSubject<any[]>(null); 
  }

  public get aclValue(): any {
    return this.aclUserSubject.value;
  }

  getAcl(data = {}): Observable<any[]> {
    const aclData = this.aclUserSubject.value;

    // If cached data exists, return it
    if (aclData) {
      return new Observable(observer => {
        observer.next(aclData);
        observer.complete();
      });
    }

    // If data is already being fetched, return an empty observable
    if (this.isFetching) {
      return new Observable(observer => {
        const checkData = () => {
          if (this.aclUserSubject.value) {
            observer.next(this.aclUserSubject.value);
            observer.complete();
          } else {
            setTimeout(checkData, 100); 
          }
        };
        checkData();
      });
    }

    // Otherwise, fetch from API
    this.isFetching = true;
    return this.http.get<any>(`${environment.apiUrl}acl`, { params: data }).pipe(
      map(response => {
        if (response.code === 200) {
          this.aclUserSubject.next(response.data); 
          return response.data;
        }
        throw new Error('Failed to fetch ACL data');
      }),
      catchError(error => {
        this.isFetching = false;
        throw error;
      }),
      tap(() => {
        this.isFetching = false; 
      })
    );
  }

  getAclPromise(data = {}): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const aclData = this.aclValue;
      if (aclData && aclData.length > 0) {
        resolve(aclData);
      } else {
        // Fetch data and resolve
        this.getAcl(data).subscribe(
          (response) => resolve(response),
          (error) => reject(error)
        );
      }
    });
  }

  public clearAclData(): void {
    this.aclUserSubject.next(null); // Clear cached data
    this.isFetching = false; // Reset fetching flag
  }
}
