<div id="page-wrapper" class="gray-bg dashbard-1">
  <!-- <p class="text-center">IP: {{userIp}}</p> -->
  <div class="footer-txt display-flex justify-content-center">
    <p class="fsm12 text-center">
      &copy; 2021 - {{ currentYear }} Automate Easy Education & Services Pvt. Ltd. All Rights
      Reserved .
    </p>
  </div>
</div>
<!-- for-moble-menu -->
<ul class="sidebar-nav bottom-nav padding-20 display-flex justify-space-between hide-desktop">
  <li [ngClass]="{ active: activeClick == 'dashboard' }">
    <a routerLink="/" routerLinkActive="active" (click)="activeclick('dashboard')">
      <svg class="dash-icons">
        <use href="assets/images/icon.svg#dashboard" />
      </svg>
      <br />
      <span class="nav-text m-0">Dashboard</span>
    </a>
  </li>
  <li [ngClass]="{ active: activeClick == 'leads' }">
    <a
      [routerLink]="['/leads']"
      [queryParams]="{ type: 'all' }"
      routerLinkActive="active"
      (click)="activeclick('leads')">
      <svg class="dash-icons">
        <use href="assets/images/icon.svg#leads" />
      </svg>
      <br />
      <span class="nav-text">Leads</span>
    </a>
  </li>

  <li [ngClass]="{ active: activeClick == 'lead-reports' }">
    <a routerLink="/lead-reports" routerLinkActive="active" (click)="activeclick('lead-reports')">
      <svg class="dash-icons">
        <use href="assets/images/icon.svg#reports" />
      </svg>
      <br />
      <span class="nav-text">Reports</span>
    </a>
  </li>
  <li
    *ngIf="currentUser.role != 1 && currentUser.role != 6"
    [ngClass]="{ active: activeClick == 'myfollowup' }">
    <a
      [routerLink]="['/myfollowup']"
      [queryParams]="{ stage: 'today' }"
      routerLinkActive="active"
      (click)="activeclick('myfollowup')">
      <svg class="dash-icons">
        <use href="assets/images/icon.svg#followUps" />
      </svg>
      <br />
      <span class="nav-text">FollowUps</span>
    </a>
  </li>
</ul>

<!-- live call -->
<div class="live-call-btn">
  <a routerLink="/leads/live-call" class="flex-center justify-content-center" title="Live Calls">
    <svg class="dash-icons">
      <use href="assets/images/icon.svg#live-call" />
    </svg>
  </a>
</div>

<app-student-details-modal></app-student-details-modal>
