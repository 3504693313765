<div id="divLoading" class="divLoading" *ngIf="loading">
    <p><img src="assets/images/loader1.gif" /></p>
  </div>
  <div id="page-wrapper" class="gray-bg dashbard-1">
    <div class="content-main">
      <div class="blank">
        <div class="tab-content">
          <div class="tab-pane fade active in" id="tab1">
            <div class="row flex-center justify-space-between mb-15">
              <div class="col-sm-7 col-xs-12">
                <p class="fs16 fw700 text-blue text-right">Total: {{dataSourceCount}}</p>
              </div>
            </div>
            <div class="whatsapp-template">
              <div class="flex-center justify-space-between flex-wrap mb-15">
                <h2 class="fs18 fsm16 fw700 mr-b15">Dialer Configuration</h2>
                <div class="flex-center justify-content-end add-upload-btn gap-10">
                  <a
                  routerLink="/dialer-config"
                    class="btn flex-center">
                    <svg class="dash-icons mr-r5 margin-r5">
                      <use href="assets/images/icon.svg#plus" />
                    </svg>
                    Create 
                  </a>
                </div>
              </div>
              <div class="lead-table sticky-head scrollbar">
                <table class="table table-condensed">
                  <thead>
                    <tr>
                      <th scope="col">
                        <div class="th-bg">Sr. No.</div>
                      </th>
                      <th scope="col">
                        <div class="th-bg"> Name</div>
                      </th>
                      <th scope="col">
                        <div class="th-bg">Created By</div>
                      </th>
                      <th scope="col">
                        <div class="th-bg">Created On</div>
                      </th>
                      <th scope="col">
                        <div class="th-bg">trigger Type</div>
                      </th>
                      <th scope="col">
                        <div class="th-bg">Active</div>
                      </th>
                      <th scope="col">
                        <div class="th-bg">Action</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="top-row" *ngFor="let item of list; index as i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td scope="row">{{item.name}}</td>
                      <td scope="row">{{item.createdByName}}</td>
                      <td scope="row">{{item.createdAt}}</td>
                      <td scope="row">{{item.triggerType}}</td>
                      <td scope="row">{{item.status == true ? 'Yes' : 'No'}}</td>
                      <td scope = "row">
                        <a
                        title="Edit"
                        ><svg class="dash-icons">
                          <use href="assets/images/icon.svg#edit-icon" />
                        </svg>
                      </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- pagination start -->
              <!-- <app-pagination
                [dataSourceCount]="dataSourceCount"
                (fetchData)="loadPaginationData($event)"
                *ngIf="dataSourceCount !== 0"></app-pagination> -->
              <!-- pagination end -->
            </div>
          </div>
        </div>
      </div>
    </div>
  
  </div>