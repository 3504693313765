import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { SocketService } from 'src/app/_services/socket.service';
import { skip } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-student-details-modal',
  templateUrl: './student-details-modal.component.html',
  styleUrls: ['./student-details-modal.component.css'],
})
export class StudentDetailsModalComponent implements OnInit {
  constructor(
    private socketService: SocketService,
    private renderer: Renderer2
  ) {}

  @ViewChild('studentModal', { static: false }) modal: ElementRef;

  leadDetail: any = {};
  currentUser = {};
  EntityName = environment.entity_name;
  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.socketService
      .getNewMessage()
      .pipe(skip(1))
      .subscribe((message: string) => {
        console.log('open', message);
        this.leadDetail = message;
        // this.open();
        // if (message && message != '') {
        //   if (this.currentUser['id'] == message['user_id']) {
        //     this.leadDetail = message;
        //   }
        // }
      });
  }

  open() {
    this.renderer.setStyle(this.modal.nativeElement, 'display', 'block');
    this.renderer.addClass(this.modal.nativeElement, 'in');
  }

  close() {
    this.renderer.removeStyle(this.modal.nativeElement, 'display');
    this.renderer.removeClass(this.modal.nativeElement, 'in');
  }
}
