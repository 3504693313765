import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { AclService } from './acl.service';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AclResolver implements Resolve<any> {
  constructor(private aclService: AclService) {}

  resolve(): Observable<any> {
    const aclData = this.aclService.aclValue;
    if (aclData && aclData.length > 0) {
      return of(aclData);
    } else {
      return this.aclService.getAcl();
    }
  }
}
