import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AclService } from './acl.service';

interface Permission {
  code: string;
  level: number[];
}

@Injectable({
  providedIn: 'root',
})
export class AuthServiceService {
  private permissions: { [key: string]: number[] } = {};

  constructor(
    private http: HttpClient,
    private aclService: AclService
  ) {}

  fetchPermissions(): Observable<{ [key: string]: number[] }> {
    // Fetch ACL data from AclService
    return this.aclService.getAcl().pipe(
      map(aclData => {

        if (!Array.isArray(aclData)) {
          throw new Error('ACL data is not an array');
        }
        const permissions = aclData.reduce(
          (acc, permission: Permission) => {
            acc[permission.code] = permission.level;
            return acc;
          }, {} as { [key: string]: number[] }
        );
        this.permissions = permissions;
        return permissions;
      })
    );
  }

  hasPermission(componentCode: string, requiredPermission: number): boolean {
    if (this.permissions[componentCode]) {
      return this.permissions[componentCode].includes(requiredPermission);
    } else {
      return false;
    }  }

  // Public method to clear permissions
  public clearPermissions(): void {
    this.permissions = {};
  }
}
