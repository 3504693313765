import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LeadSearchService } from './../../_services/lead-search.service';
import * as moment from 'moment';

@Component({
  selector: 'app-product-order-detail',
  templateUrl: './product-order-detail.component.html',
  styleUrls: ['./product-order-detail.component.css'],
})
export class ProductOrderDetailComponent implements OnInit {
  loading: boolean = false;
  o_Id: number = 0;
  orderData: [] = [];
  user_role: any = '';
  currentUser = {};
  constructor(
    private leadSearchService: LeadSearchService,
    private route: ActivatedRoute
  ) { }
  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.user_role = this.currentUser['role'];
    this.o_Id = this.route.snapshot.params.id;
    this.getOrderDetailData(this.o_Id);
  }
  getOrderDetailData(o_Id) {
    this.loading = true;
    this.leadSearchService.getOrderDetailData(o_Id).subscribe(
      (detail: any) => {
        if (detail.code == 200) {
          this.loading = false;
          this.orderData = detail.data;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  formatDate(date) {
    if (isNaN(date)) {
      return moment(date).format('MMM DD, YYYY h:mm A');
    }
  }

}
