import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  constructor(private http: HttpClient) {}
  getTenantProfileById(id) {
    return this.http.get(`${environment.apiUrl}get_tenant_by_id/${id}`);
  }

  EditTenantProfile(id, body) {
    return this.http.put(`${environment.apiUrl}update_tenant/${id}`, body);
  }
}
