import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './_services/authentication.service';
import { User } from './_models/user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'frontend';
  currentUser: User;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private renderer: Renderer2
  ) {
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      if (this.currentUser) {
        // this.addClarityScript();
      }
    });
  }

  ngOnInit() {}

  sub: PushSubscription;

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  addClarityScript() {
    if (!this.currentUser || !this.currentUser.id) {
      console.error('User information is not available for Clarity script.');
      return;
    }

    const userId = this.currentUser.id.toString();
    const userName = this.currentUser.name.toString();
    const userEmail = this.currentUser.email;
    const userRoleId = this.currentUser.role.toString();
    const userRoleName = this.currentUser.role_name.toString();

    console.log(
      `Inserting Clarity script for userId: ${userId} || userName: ${userName} || userEmail: ${userEmail} || roleId: ${userRoleId} || roleName: ${userRoleName}`
    );
    const clarityScript = this.renderer.createElement('script');
    clarityScript.type = 'text/javascript';
    clarityScript.innerHTML = `
    (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "nbbeu5dmur");

    // Ensure clarity is loaded before calling identify
    window.addEventListener('clarity', function() {
      if (typeof clarity === 'function') {
        clarity('identify', {
          domain: window.location.hostname,
          userId: '${userId}',
          userName: '${userName}',
          userEmail: '${userEmail}',
          userRoleId: '${userRoleId}',
          userRoleName: '${userRoleName}',
        });
      } else {
        console.error('Clarity function is not defined.');
      }
    });
    `;
    const scriptPlaceholder = this.renderer.selectRootElement('head', true);
    this.renderer.appendChild(scriptPlaceholder, clarityScript);
  }
}
