import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { filter } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class LeadSearchService {
  constructor(private http: HttpClient) {}
  getAllPartnerColleges(allpartnerclgs) {
    return this.http.get(
      `${environment.apiUrl}getPartnerColleges?allpartnerclgs=${allpartnerclgs}`
    );
  }
  getlPartnerColleges() {
    return this.http.get(`${environment.apiUrl}getPartnerColleges`);
  }
  getAllCountries() {
    return this.http.get(`${environment.apiUrl}getcountries`);
  }
  getAllStates(countryId) {
    return this.http.get(`${environment.apiUrl}getstates?countryId=${countryId}`);
  }
  getCitiesFromStateId(stateId) {
    return this.http.get(`${environment.apiUrl}getcities?stateId=${stateId}`);
  }
  getCitiesFromStateName(stateName) {
    return this.http.get(`${environment.apiUrl}get_cities_of_state_by_name?name=${stateName}`);
  }
  getCitiesFromStateNameMul(stateName) {
    return this.http.get(`${environment.apiUrl}get_cities_of_state_by_name_mul?name=${stateName}`);
  }
  getAllCollegeCities(state) {
    let states = state.length ? state.map(x => x.clg_state) : '';
    return this.http.get(`${environment.apiUrl}getPartnerCollegesCities?state=${states}`);
  }
  getAllStreams() {
    return this.http.get(`${environment.apiUrl}getstreams`);
  }
  getL3Rules() {
    return this.http.get(`${environment.apiUrl}get-l3-rules`);
  }
  leadColumns() {
    return this.http.get(`${environment.apiUrl}lead-columns`);
  }

  getAllLeads(payload) {
    // let payload = {
    //   id: filters.selectedUserItems.length
    //     ? filters.selectedUserItems.map(x => x.id).join(',')
    //     : '',
    //   name: filters.name ? filters.name : '',
    //   priority: filters.priority ? filters.priority : '',
    //   lead_type: filters.lead_type ? filters.lead_type : '',
    //   isUntouched: filters.isUntouched ? filters.isUntouched : 0,
    //   source: filters.leadSource.length ? filters.leadSource.map(x => x.source).join(',') : '',
    //   sdate: filters.sdate ? filters.sdate : '',
    //   edate: filters.edate ? filters.edate : '',
    //   uStartDate: filters.uStartDate ? filters.uStartDate : '',
    //   uEndDate: filters.uEndDate ? filters.uEndDate : '',
    //   activitySDate: filters.activitySDate ? filters.activitySDate : '',
    //   activityEndDate: filters.activityEDate ? filters.activityEDate : '',
    //   user_role: user_role,
    //   vc: video_call_enable,
    //   page_no: filters.page_no ? filters.page_no : 1,
    //   limit: filters.limit ? filters.limit : 10,
    //   type:
    //     filters.leadAction && filters.leadAction.length
    //       ? filters.leadAction.map(x => x.stage_name).join(',')
    //       : '',
    //   sub_stage:
    //     filters.leadSubAction && filters.leadSubAction.length
    //       ? filters.leadSubAction.map(x => x.sub_stage_name).join(',')
    //       : '',

    //   allleadaction:
    //     filters.allleadaction && filters.allleadaction.length
    //       ? filters.allleadaction.map(x => x.stage_name).join(',')
    //       : '',

    //   clgId:
    //     filters.selectedCollegeItems && filters.selectedCollegeItems.length
    //       ? filters.selectedCollegeItems.map(x => x.clg_id).join(',')
    //       : '',
    //   sortKey: filters.sortKey ? filters.sortKey : 'lead_times_at',
    //   sortOrder: filters.sortOrder ? filters.sortOrder : -1,
    //   format: format,
    //   accessAllLeads: filters.access_all_leads ? filters.access_all_leads : 0,
    //   state: filters.state ? filters.state : '',
    //   city: filters.city ? filters.city : '',
    //   course: filters.course ? filters.course : '',
    //   stateName:
    //     filters.stateName && filters.stateName.length
    //       ? filters.stateName.map(x => x.value).join(',')
    //       : '',
    //   cityName:
    //     filters.cityName && filters.cityName.length
    //       ? filters.cityName.map(x => x.name).join(',')
    //       : '',
    //   courseName:
    //     filters.courseName && filters.courseName.length
    //       ? filters.courseName.map(x => x.course).join(',')
    //       : '',
    //   category:
    //     filters.category && filters.category.length
    //       ? filters.category.map(x => x.cat_name).join(',')
    //       : '',
    //   noOfReEnquiry: filters.noOfReEnquiry ? filters.noOfReEnquiry : '',
    //   reEnquiryOperation: filters.reEnquiryOperation ? filters.reEnquiryOperation : '',
    //   noOfApplicationForm: filters.noOfApplicationForm ? filters.noOfApplicationForm : '',
    //   applicationFormOperation: filters.applicationFormOperation
    //     ? filters.applicationFormOperation
    //     : '',
    //   lead_score: filters.lead_score ? filters.lead_score : '',
    //   leadScoreOpreation: filters.leadScoreOpreation ? filters.leadScoreOpreation : '',
    //   lead_stage_count: filters.lead_stage_count ? filters.lead_stage_count : '',
    //   leadStageCountOpreation: filters.leadStageCountOpreation
    //     ? filters.leadStageCountOpreation
    //     : '',
    //   recentReEnquiredAtOperation: filters.recentReEnquiredAtOperation
    //     ? filters.recentReEnquiredAtOperation
    //     : '',
    //   lastReEnquiredAtOperation: filters.lastReEnquiredAtOperation
    //     ? filters.lastReEnquiredAtOperation
    //     : '',
    //   recentLeadStageAtOperation: filters.recentLeadStageAtOperation
    //     ? filters.recentLeadStageAtOperation
    //     : '',
    //   leadActivityAtOperation: filters.leadActivityAtOperation
    //     ? filters.leadActivityAtOperation
    //     : '',
    //   createdAtOperation: filters.createdAtOperation ? filters.createdAtOperation : '',
    //   updatedAtOperation: filters.updatedAtOperation ? filters.updatedAtOperation : '',
    //   leadAssignAtOperation: filters.leadAssignAtOperation ? filters.leadAssignAtOperation : '',
    //   reEnquiredClg:
    //     filters.reEnquiredClg && filters.reEnquiredClg.length
    //       ? filters.reEnquiredClg.map(x => x.clg_name).join(',')
    //       : '',
    //   reEnquiredClgSource:
    //     filters.reEnquiredClgSource && filters.reEnquiredClgSource.length
    //       ? filters.reEnquiredClgSource.map(x => x.re_enquired_source).join(',')
    //       : '',
    //   reEnquiredsdate: filters.reESdate ? filters.reESdate : '',
    //   reEnquirededate: filters.reEEdate ? filters.reEEdate : '',
    //   reLastEnquiredsdate: filters.reLESdate ? filters.reLESdate : '',
    //   reLastEnquirededate: filters.reLEEdate ? filters.reLEEdate : '',
    //   utmSource:
    //     filters.utmSource && filters.utmSource.length
    //       ? filters.utmSource.map(x => x.utm_source).join(',')
    //       : '',
    //   utmCampaign:
    //     filters.utmCampaign && filters.utmCampaign.length
    //       ? filters.utmCampaign.map(x => x.utm_campaign).join(',')
    //       : '',
    //   utmMedium:
    //     filters.utmMedium && filters.utmMedium.length
    //       ? filters.utmMedium.map(x => x.utm_medium).join(',')
    //       : '',
    //   reEnquiredUtmSource:
    //     filters.reEnquiredUtmSource && filters.reEnquiredUtmSource.length
    //       ? filters.reEnquiredUtmSource.map(x => x.re_enquired_utm_source).join(',')
    //       : '',
    //   reEnquiredUtmMedium:
    //     filters.reEnquiredUtmMedium && filters.reEnquiredUtmMedium.length
    //       ? filters.reEnquiredUtmMedium.map(x => x.re_enquired_utm_medium).join(',')
    //       : '',
    //   reEnquiredUtmCampaign:
    //     filters.reEnquiredUtmCampaign && filters.reEnquiredUtmCampaign.length
    //       ? filters.reEnquiredUtmCampaign.map(x => x.re_enquired_utm_campaign).join(',')
    //       : '',
    //   fw_sdate: filters.fw_sdate ? filters.fw_sdate : '',
    //   fw_edate: filters.fw_edate ? filters.fw_edate : '',
    //   n_id: filters.nid,
    //   recentLeadStageStartDate: filters.rlsStartDate ? filters.rlsStartDate : '',
    //   recentLeadStageEndDate: filters.rlsEndDate ? filters.rlsEndDate : '',
    //   leadAssigneeStartDate: filters.laStartDate ? filters.laStartDate : '',
    //   leadAssigneeEndDate: filters.laEndDate ? filters.laEndDate : '',
    //   oldUserId:
    //     filters.selectedOldUserItems && filters.selectedOldUserItems.length
    //       ? filters.selectedOldUserItems.map(x => x.id)
    //       : '',
    //   reAssignedUserId:
    //     filters.selectedReAssignedUserItems && filters.selectedReAssignedUserItems.length
    //       ? filters.selectedReAssignedUserItems.map(x => x.id)
    //       : '',
    //   activity_event: filters.activity_event ? filters.activity_event : '',
    //   publisher_tenant_id: filters.publisher_tenant_id ? filters.publisher_tenant_id : '',
    //   org_location_name:
    //     filters.org_location_id && filters.org_location_id.length
    //       ? filters.org_location_id.map(x => x.name).join(',')
    //       : '',
    //   get_activity: filters.get_activity,
    //   sinceLeadOwnerChange: filters.since_lead_owner_change,
    //   createdAtAgeTimeGap:
    //     filters.createdAtAgeTimeGap.timeValue === '' ? '' : filters.createdAtAgeTimeGap,
    //   ownerAssignmentAtAgeTimeGap:
    //     filters.ownerAssignmentAtAgeTimeGap.timeValue === ''
    //       ? ''
    //       : filters.ownerAssignmentAtAgeTimeGap,
    //   stageCreatedAtAgeTimeGap:
    //     filters.stageCreatedAtAgeTimeGap.timeValue === '' ? ' ' : filters.stageCreatedAtAgeTimeGap,
    //   primary_source:
    //     filters.primarySelectedItems && filters.primarySelectedItems.length
    //       ? filters.primarySelectedItems.map(x => x.source).join(',')
    //       : '',
    //   secondary_source:
    //     filters.secondarySelectedItems && filters.secondarySelectedItems.length
    //       ? filters.secondarySelectedItems.map(x => x.source).join(',')
    //       : '',
    //   tertiary_source:
    //     filters.tertiarySelectedItems && filters.tertiarySelectedItems.length
    //       ? filters.tertiarySelectedItems.map(x => x.source).join(',')
    //       : '',
    //   last_source:
    //     filters.leadLastSelectedItems && filters.leadLastSelectedItems.length
    //       ? filters.leadLastSelectedItems.map(x => x.source).join(',')
    //       : '',
    // };

    return this.http.post(`${environment.apiUrl}getLeads`, payload);
  }

  leadGenerate(data) {
    return this.http.post(`${environment.apiUrl}leadGenerate`, data);
  }
  savel2tol3rule(data) {
    return this.http.post(`${environment.apiUrl}l2-to-l3-assignment-save`, data);
  }
  getCourses() {
    return this.http.get(`${environment.apiUrl}getCourses`);
  }
  getAllLeadNotifications(user_id, role, ntfDate) {
    return this.http.get(
      `${environment.apiUrl}getLeadNotifications?user_id=${user_id}&role=${role}&ntfDate=${ntfDate}`
    );
  }
  removeLeads(leadId) {
    return this.http.delete(`${environment.apiUrl}removeLead?leadId=${leadId}`);
  }
  verifiedLeads(leadId) {
    return this.http.get(`${environment.apiUrl}verifiedLeads/${leadId}`);
  }
  getUsersSpecificRoles(userHierarchy = false) {
    return this.http.get(
      `${environment.apiUrl}getUsersSpecificRoles?userHierarchy=${userHierarchy}`
    );
  }
  getL3Users() {
    return this.http.get(`${environment.apiUrl}get-l3-users`);
  }
  getUsersRoles(obj) {
    return this.http.get(`${environment.apiUrl}getUsersRoles?role=${obj}`);
  }
  getLeadReports(filters) {
    let userId = filters.userId ? filters.userId : '';
    let sdate = filters.sdate ? filters.sdate : '';
    let edate = filters.edate ? filters.edate : '';
    let scl = filters.scl.length > 0 ? filters.scl : '';
    let page_no = filters.page_no ? filters.page_no : 1;
    let limit = filters.limit ? filters.limit : 10;
    return this.http.get(
      `${environment.apiUrl}get_lead_reports?userId=${userId}&sdate=${sdate}&edate=${edate}&scl=${scl}&page_no=${page_no}&limit=${limit}`
    );
  }
  deleteAllSelectedLead(leadIds) {
    return this.http.delete(`${environment.apiUrl}deleteAllSelectedLead?leadIds=${leadIds}`);
  }
  getallProduct() {
    return this.http.get(`${environment.apiUrl}get_all_products`);
  }
  getCounsolerProducts(id, role) {
    return this.http.get(`${environment.apiUrl}counsoler-products?uId=${id}&role=${role}`);
  }
  getOrdersReport(filters) {
    let clg_ids = '';
    if (filters.selectedCollegeItems.length > 0) {
      clg_ids = filters.selectedCollegeItems.map(x => x.clg_id).join(',');
    } else {
      clg_ids = '';
    }
    let orderID = filters.orderID ? filters.orderID : '';
    let limit = filters.limit ? filters.limit : '';
    let page_no = filters.page_no ? filters.page_no : '';
    let sdate = filters.sdate ? filters.sdate : '';
    let edate = filters.edate ? filters.edate : '';
    let status = filters.status ? filters.status : ''; // Order Status
    let csv = filters.csv ? filters.csv : '';
    let name = filters.name ? filters.name : '';
    let paymentid = filters.paymentid ? filters.paymentid : '';
    let payment_status = filters.payment_status;
    let sdate_payment = filters.sdate_payment ? filters.sdate_payment : '';
    let edate_payment = filters.edate_payment ? filters.edate_payment : '';
    let total = filters.total ? filters.total : '';
    let coupon_code = filters.coupon_code ? filters.coupon_code : '';
    return this.http.get(
      `${environment.apiUrl}get_order_by_clg?orderID=${orderID}&sdate=${sdate}&edate=${edate}&clg_ids=${clg_ids}&status=${status}&limit=${limit}&page_no=${page_no}&csvData=${csv}&name=${name}&paymentid=${paymentid}&payment_status=${payment_status}&sdate_payment=${sdate_payment}&edate_payment=${edate_payment}&total=${total}&coupon_code=${coupon_code}`
    );
  }
  getOrderDetailData(oId) {
    return this.http.get(`${environment.apiUrl}get_products_order_detail?o_id=${oId}`);
  }

  getClgUspByClgId(clg_id, course) {
    return this.http.get(
      `${environment.apiUrl}get_clg_id_for_usp?clg_id=${clg_id}&course=${course}`
    );
  }

  getAllFollowup(filters) {
    console.log(filters);
    let name = filters.name ? filters.name : '';
    let followup_type = filters.followup_type ? filters.followup_type : 'today';
    let page_no = filters.page_no ? filters.page_no : 1;
    let limit = filters.limit ? filters.limit : 10;
    let clg_id = filters.clg_id === 0 ? 0 : filters.clg_id ? filters.clg_id : '';
    let source = filters.source === 0 ? 0 : filters.source ? filters.source : '';
    let user_id = filters.user_id === 0 ? 0 : filters.user_id ? filters.user_id : '';
    let search = filters.search ? filters.search : '';
    return this.http.get(
      `${environment.apiUrl}getfollowups?name=${name}&clgId=${clg_id}&source=${source}&user_id=${user_id}&sdate=${filters.sdate}&edate=${filters.edate}&followup_type=${followup_type}&page_no=${page_no}&limit=${limit}&priority=${filters.priority}&format=${filters.format}&search=${search}`
    );
  }
  getLeadStage(filters) {
    let sdate = filters.sdate ? filters.sdate : '';
    let edate = filters.edate ? filters.edate : '';
    let id = filters.clg_id ? filters.clg_id : '';
    return this.http.get(`${environment.apiUrl}lead-stage?sdate=${sdate}&edate=${edate}&id=${id}`);
  }
  changeOrderStage(id, status) {
    let obj = {
      ptc_status: status,
    };
    return this.http.post(`${environment.apiUrl}paid-to-clg/${id}`, obj);
  }
  saveOrderRemark(id, remark) {
    let obj = {
      remark: remark,
    };
    return this.http.post(`${environment.apiUrl}add-order-remark/${id}`, obj);
  }
  saveOrderOwner(id, obj) {
    return this.http.post(`${environment.apiUrl}order-owner-to/${id}`, obj);
  }
  getDegreeFormLevel(level) {
    return this.http.get(`${environment.apiUrl}get-degree-from-level?level=${level}`);
  }
  saveAdmissionUpdateStatus(obj) {
    return this.http.post(`${environment.apiUrl}saveadmsnstatus`, obj);
  }
  saveAdmsnAttachment(data) {
    return this.http.post(`${environment.apiUrl}saveadmsnattachment`, data);
  }
  getAdmsnConfirmationReport(filters) {
    console.log(filters);
    let userID = filters.userId ? filters.userId : '';
    let dfUserId = filters.defaultUserId ? filters.defaultUserId : '';
    let clgID = filters.clgId ? filters.clgId : '';
    let updateS = filters.updateStatus ? filters.updateStatus : '';
    let collegeS = filters.collegeStatus ? filters.collegeStatus : '';
    let role = filters.role ? filters.role : '';
    let limit = filters.limit ? filters.limit : '';
    let page_no = filters.page_no ? filters.page_no : '';

    return this.http.get(
      `${environment.apiUrl}getadmsncon?userId=${userID}&dfUserId=${dfUserId}&&clgId=${clgID}&updateStatus=${updateS}&collegeStatus=${collegeS}&role=${role}&page_no=${page_no}&limit=${limit}`
    );
  }
  saveAdmsnDepositedAmmount(data) {
    return this.http.post(`${environment.apiUrl}saveadmsnammount`, data);
  }
  getClgDetailByClgId(clgId) {
    return this.http.get(`${environment.apiUrl}getclgdetail/${clgId}`);
  }
  sendEmail(data) {
    return this.http.post(`${environment.apiUrl}sendemail`, data);
  }
  getLeadReportByCallAction(data) {
    let source = '';
    let userId = '';
    if (data.selectedSourceItems && data.selectedSourceItems.length > 0) {
      source = data.selectedSourceItems.map(source => source.source).join(',');
    }
    if (data.userId) {
      userId = data.userId != -1 ? data.userId : '';
    }
    return this.http.get(
      `${environment.apiUrl}lead-report-by-call-action?id=${userId}&source=${source}&sdate=${data.sdate}&edate=${data.edate}`
    );
  }

  L2ReAssignment(obj) {
    // let newId = obj.newId.map(x => x.id).join(',');
    // let source = obj.sourceItems.map(x => x.source).join(',');
    // let college = obj.collegeItems.map(x => x.clg_id).join(',');
    // let stage = obj.stageItems.map(x => x.stage_name).join(',');
    return this.http.post(`${environment.apiUrl}re-assignment-leads`, obj);
  }
  L2ReAssignmentLeadCount(obj) {
    // let source = obj.sourceItems.map(x => x.source).join(',');
    // let college = obj.collegeItems.map(x => x.clg_id).join(',');
    // let stage = obj.stageItems.map(x => x.stage_name).join(',');
    return this.http.post(`${environment.apiUrl}re-assignment-leads`, obj);
  }
  deleteL3Rule(slug) {
    let item = 1;
    return this.http.put(`${environment.apiUrl}delete-l3-rules/${slug}`, item);
  }
  getL3RuleBySlug(slug) {
    return this.http.get(`${environment.apiUrl}get-l3-rules-by-slug/${slug}`);
  }
  updateL3Rule(slug, obj) {
    return this.http.put(`${environment.apiUrl}edit-l3-rule/${slug}`, obj);
  }
  getDistinctCourses() {
    return this.http.get(`${environment.apiUrl}getDistinctCourses`);
  }
  getSavedLeadFilters() {
    return this.http.get(`${environment.apiUrl}getSaveLeadFilterDerails`);
  }
  saveUserQuickView(filters) {
    return this.http.post(`${environment.apiUrl}saveLeadFilter`, filters);
  }
  updateQuickView(id, dataObj) {
    return this.http.put(`${environment.apiUrl}updateQuickView/${id}`, dataObj);
  }
  markAsDefaultView(id, dataObj) {
    return this.http.put(`${environment.apiUrl}markAsDefaultView/${id}`, dataObj);
  }
  deleteQuickView(id) {
    return this.http.put(`${environment.apiUrl}deleteQuickView/${id}`, {});
  }
  getAllLeadStages(allStages: any = false) {
    return this.http.get(`${environment.apiUrl}getLeadStages`, {
      params: { allStages: allStages },
    });
  }
  saveLeadStage(obj) {
    return this.http.post(`${environment.apiUrl}addLeadStage`, obj);
  }
  getfollowUpByLeadIds(call_action_id) {
    return this.http.get(`${environment.apiUrl}followup/lead/${call_action_id}`);
  }
  upadateLeadStage(obj, id, type) {
    return this.http.put(`${environment.apiUrl}updateLeadStage/${id}/${type}`, obj);
  }
  upadateLeadSubStage(obj, id) {
    return this.http.put(`${environment.apiUrl}updateLeadSubStage/${id}`, obj);
  }
  getLeadActivitybyId(id, filters) {
    return this.http.get(`${environment.apiUrl}getLeadActivityById/${id}`, {
      params: filters,
    });
  }
  getLogActivity(filters) {
    return this.http.get(`${environment.apiUrl}getLogActivity`, {
      params: filters,
    });
  }
  getAllSourcesFromDb(filters) {
    return this.http.get(`${environment.apiUrl}getAllSources`, {
      params: filters,
    });
  }
  refreshSource() {
    return this.http.post(`${environment.apiUrl}build-sources`, {});
  }
  saveSourceInDb(obj) {
    return this.http.post(`${environment.apiUrl}saveSources`, obj);
  }
  updateSource(obj, id) {
    return this.http.put(`${environment.apiUrl}updateSource/${id}`, obj);
  }
  getSourceById(id) {
    return this.http.get(`${environment.apiUrl}getSourceById/${id}`);
  }
  getDistinctValueFromLmsLeads(column) {
    return this.http.get(`${environment.apiUrl}getDistinctLeadCourses?column=${column}`);
  }
  getStream() {
    return this.http.get(`${environment.apiUrl}get_stream`);
  }
  getStreamCourse(name) {
    return this.http.get(`${environment.apiUrl}get_stream_course?name=${name}`);
  }
  getDistinctTrackingEventTypes() {
    return this.http.get(`${environment.apiUrl}getTrackingEvents`);
  }
  getLeadFilterValues() {
    return this.http.get(`${environment.apiUrl}get-lead-filters`);
  }
  getCafOrderDetail(filters) {
    return this.http.get(`${environment.apiUrl}get-caf-detail-by-lead`, {
      params: filters,
    });
  }
  upadateApplicationSubStage(obj, id, subId) {
    return this.http.patch(`${environment.apiUrl}edit-application-substage/${id}/${subId}`, obj);
  }
  saveApplicationSubStage(obj, id) {
    return this.http.patch(`${environment.apiUrl}add-application-substage/${id}`, obj);
  }
  upadateApplicationStage(obj, id) {
    return this.http.patch(`${environment.apiUrl}edit-application-stage/${id}`, obj);
  }
  getAllApplicationStages() {
    return this.http.get(`${environment.apiUrl}all-application-stages`);
  }
  saveApplicationStage(obj) {
    return this.http.post(`${environment.apiUrl}application-stage`, obj);
  }

  getAllQuickViewFilter(filters) {
    return this.http.get(`${environment.apiUrl}get-all-quick-view-filter`, {
      params: filters,
    });
  }
  getFilterByID(id) {
    return this.http.get(`${environment.apiUrl}get-quick-view-filter-by-id?id=${id}`);
  }
  updateQuickViewFilter(userID, id, name, obj) {
    return this.http.put(
      `${environment.apiUrl}update-quick-view-filter-by-id?id=${id}&name=${name}`,
      obj
    );
  }
  saveQuickViewFilterInDB(obj) {
    return this.http.post(`${environment.apiUrl}save-quick-view-filter`, obj);
  }
  filterForm(filters) {
    return this.http.get(`${environment.apiUrl}filter-form`, {
      params: filters,
    });
  }
  filterUserData() {
    return this.http.get(`${environment.apiUrl}filter-user-data`);
  }
}
